import { useState } from "react";
import { CustomAlert, CustomButton } from "../../../../../../../shared/uiComponents";
import Content from "./content";

const EditSteps = ({ id, programId }: { id: string; programId: string }) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  return (
    <>
      <CustomButton title="Edit Steps" onClick={() => setOpenEdit(true)} />
      <CustomAlert
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        title="Steps"
        maxWidth="md"
        Content={({onClose}) => <Content id={id} programId={programId} onClose={onClose}/>}
      />
    </>
  );
};

export default EditSteps;
