import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import {
  useCheckPermission,
  useCompareDates,
} from "../../shared/Helpers/hooks";
import { CustomButton, Picker } from "../../shared/uiComponents";
import SearchSelect from "../../shared/uiComponents/Dropdown/searchSelect";
import { DataProperties } from "../../shared/uiComponents/Radio";
import { white100 } from "../../shared/Helpers/colors";
import { PERMISSIONS } from "../../App/constants";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getUsers } from "../../redux/State/userSlice";
import {
  getTherapists,
  getUserClients,
} from "../../redux/State/clientSlice/userClientSlice";
import { getClients } from "../../redux/State/clientSlice/clientSlice";
import {
  setClientIdFilters,
  setEndDate,
  setStartDate,
  setUserIdFilters,
} from "../../redux/State/clientSlice/billingSlice";

const today = dayjs();
const startDayOfMonth = today.startOf("month");
const beginningOfLastMoth = startDayOfMonth.subtract(1, "month");
const endOfLastMoth = beginningOfLastMoth.endOf("month");
const startOfYear = today.startOf("year");

export const ReportFilters: FC<{
  showClients?: boolean;
  showUsers: boolean;
  showShorcuts?: boolean;
}> = ({ showClients = true, showUsers, showShorcuts = true }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);
  const USER = useCheckPermission(PERMISSIONS.USER.READ);

  const filterData = useSelector((state) => state.billing.billingFilter);
  const loadingUsers = useSelector((state) => state.user.loading);
  const loadingClients = useSelector((state) => state.client.loading);
  const loadingBilling = useSelector((state) => state.billing.loading);

  const [startDateValue, setStartDateValue] = useState<Dayjs>(startDayOfMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(today);
  const [searchUser, setSearchUser] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [searchClient, setSearchClient] = useState<string>("");
  const [pageSizeUser, setPageSizeUser] = useState<number>(8);
  const [pageSizeClient, setPageSizeClient] = useState<number>(8);
  const [usersData, setUsersData] = useState<Array<DataProperties>>([]);
  const [clientsData, setClientsData] = useState<Array<DataProperties>>([]);

  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  useEffect(() => {
    dispatch(setStartDate(startDateValue.format("MM-DD-YYYY")));
    dispatch(setEndDate(endDateValue.format("MM-DD-YYYY")));
    dispatch(setClientIdFilters(clientId));
    dispatch(setUserIdFilters(userId));
  }, [startDateValue, endDateValue, clientId, userId, dispatch]);

  useEffect(() => {
    if (
      !pageSizeUser ||
      !!filterData.clientId ||
      !USER.permissionGranted ||
      !showUsers
    )
      return;
    const timeout = setTimeout(() => {
      dispatch(
        getUsers({
          page: "1",
          pageSize: `${pageSizeUser}`,
          searchString: searchUser,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<DataProperties> = response.query.map((user) => ({
            id: user.id,
            label: user.fullName,
          }));
          setUsersData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pageSizeUser,
    searchUser,
    filterData.clientId,
    USER.permissionGranted,
    showUsers,
  ]);

  useEffect(() => {
    if (!filterData.clientId || !USER.permissionGranted || !showUsers) return;

    const timeout = setTimeout(() => {
      dispatch(getTherapists(filterData.clientId))
        .then(unwrapResult)
        .then((response) => {
          if (!response) return;
          const data: Array<DataProperties> = response.map((user) => ({
            id: user.id,
            label: user.fullName,
          }));
          setUsersData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, filterData.clientId, USER.permissionGranted, showUsers]);

  useEffect(() => {
    if (!pageSizeClient || !CLIENT.permissionGranted || !showClients) return;

    if (!filterData.userId) {
      const timeout = setTimeout(() => {
        dispatch(
          getClients({
            page: "1",
            pageSize: `${pageSizeClient}`,
            searchString: searchClient,
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const data: Array<DataProperties> = response.query.map(
              (client) => ({
                id: client.id,
                label: client.fullName,
              })
            );
            setClientsData(data);
          });
      }, 1000);
      return () => clearTimeout(timeout);
    }

    const timeout = setTimeout(() => {
      dispatch(
        getUserClients({
          userId: filterData.userId,
          page: "1",
          pageSize: `${pageSizeClient}`,
          searchString: searchClient,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<DataProperties> = response.query.map((client) => ({
            id: client.id,
            label: client.fullName,
          }));
          setClientsData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pageSizeClient,
    searchClient,
    filterData.userId,
    CLIENT.permissionGranted,
    showClients,
  ]);

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const endDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setEndDateValue(date);
  };

  const userHandler = (value: string) => {
    setUserId(value);
  };

  const clientHandler = (value: string) => {
    setClientId(value);
  };

  const thisMonthHandler = () => {
    setStartDateValue(startDayOfMonth);
    setEndDateValue(today);
  };

  const lastMonthHandler = () => {
    setStartDateValue(beginningOfLastMoth);
    setEndDateValue(endOfLastMoth);
  };

  const thisYearHandler = () => {
    setStartDateValue(startOfYear);
    setEndDateValue(today);
  };

  return (
    <>
      <TimeInputsWrapper>
        {CLIENT.permissionGranted && showClients && (
          <div style={{ width: "250px" }}>
            <SearchSelect
              label="Find Client:"
              searchString={searchClient}
              setSearchString={setSearchClient}
              data={clientsData}
              setValue={clientHandler}
              loadMore={{
                activate: true,
                setSize: setPageSizeClient,
              }}
              loading={loadingClients || loadingBilling}
            />
          </div>
        )}
        {USER.permissionGranted && showUsers && (
          <div style={{ width: "250px" }}>
            <SearchSelect
              label="Find Provider:"
              searchString={searchUser}
              setSearchString={setSearchUser}
              data={usersData.filter((x) =>
                !!filterData.clientId
                  ? x.label.toLowerCase().includes(searchUser.toLowerCase())
                  : true
              )}
              setValue={userHandler}
              loadMore={{
                activate: true,
                setSize: setPageSizeUser,
              }}
              loading={loadingUsers || loadingBilling}
            />
          </div>
        )}
      </TimeInputsWrapper>
      <TimeInputsWrapper>
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={startDateHandler}
          disabled={loadingBilling}
        />
        <Picker.CustomDate
          label="End Date"
          error={hasError}
          errorMessage={message}
          value={endDateValue}
          onChange={endDateHandler}
          disabled={loadingBilling}
        />
      </TimeInputsWrapper>
      {showShorcuts && (
        <TimeInputsWrapper>
          <CustomButton
            title="This month"
            onClick={thisMonthHandler}
            secondaryButton
            disabled={loadingBilling}
          />
          <CustomButton
            title="Last month"
            onClick={lastMonthHandler}
            secondaryButton
            disabled={loadingBilling}
          />
          <CustomButton
            title="This year"
            onClick={thisYearHandler}
            secondaryButton
            disabled={loadingBilling}
          />
        </TimeInputsWrapper>
      )}
    </>
  );
};

export const ReportWrapper = styled("div")(() => ({
  minWidth: "250px",
  maxWidth: "720px",
  marginTop: "16px",
  backgroundColor: white100,
  padding: " 16px",
  borderRadius: "12px",
  ">div": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginBottom: "16px",
  },
}));

export const TimeInputsWrapper = styled("div")(() => ({
  display: "flex",
  gap: "16px",
  flexWrap: "wrap",
  marginBottom: "16px",
  "@media (max-width: 525px)": {
    flexDirection: "column",
    "&>div": {
      marginBottom: "16px",
    },
  },
}));

export const downloadFile = ({
  file,
  fileName,
}: {
  file: Blob;
  fileName: string;
}) => {
  const objectURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = objectURL;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
