import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  errorNormalizer,
  validateDecimalNumber,
} from "../../../../../shared/Helpers/functions";
import {
  CustomButton,
  CustomCheckBox,
  CustomInput,
  Picker,
  Text,
} from "../../../../../shared/uiComponents";
import { Loader } from "../../../../../components/StyledComponents";
import { TimeInputsWrapper } from "../../../../Reports/helpers";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  AuthorizationDetailsProperties,
  UpdateAllocatedHoursProperties,
  UserAllocatedHoursDetailProperties,
} from "../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import {
  clearActiveAuthorization,
  getAllocatedHoursDetailsUser,
  getAvailableHoursDetails,
  updateAllocatedHours,
} from "../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const AllocationDetails = ({
  therapistId,
  onClose,
}: {
  therapistId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const { clientId } = useParams();
  const [params] = useSearchParams();
  const authorizationId = params.get("authId");
  const id = params.get("detailsId");

  const allocatedHoursDetails = useSelector(
    (state) => state.authorization.allocatedHoursDetails
  );
  const availableHoursDetails = useSelector(
    (state) => state.authorization.availableHoursDetails
  );
  const loading = useSelector((state) => state.authorization.loading);

  useEffect(() => {
    if (!therapistId || !authorizationId || !clientId) return;
    const data: UserAllocatedHoursDetailProperties = {
      authorizationId,
      clientId,
      userId: therapistId,
    };
    dispatch(getAllocatedHoursDetailsUser(data));
  }, [therapistId, clientId, authorizationId, dispatch]);

  useEffect(() => {
    if (!authorizationId || !clientId) return;
    dispatch(getAvailableHoursDetails({ authorizationId, clientId }));
  }, [clientId, authorizationId, dispatch]);

  return (
    <>
      {!loading ? (
        allocatedHoursDetails
          .filter((x) => x.id === id)
          .map((item) => {
            const availableHours = availableHoursDetails.find(
              (x) => x.id === item.id
            );
            return (
              <AllocationDetail
                item={item}
                therapistId={therapistId}
                authorizationDetailId={item.id}
                onClose={onClose}
                availableHours={!!availableHours ? availableHours : null}
              />
            );
          })
      ) : (
        <Loader />
      )}
    </>
  );
};

const AllocationDetail = ({
  item,
  therapistId,
  authorizationDetailId,
  availableHours,
  onClose,
}: {
  authorizationDetailId: string;
  item: AuthorizationDetailsProperties;
  availableHours: AuthorizationDetailsProperties | null;
  therapistId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { clientId } = useParams();
  const authorizationId = params.get("authId");
  const [override, setOverride] = useState<boolean>(false);

  const [hours, setHours] = useState<string>("");
  const [additionalWeeklyHours, setAdditionalWeeklyHours] =
    useState<string>("");
  const [startDateValue, setStartDate] = useState<Dayjs | null>(null);
  const [endDateValue, setEndDate] = useState<Dayjs | null>(null);

  const loading = useSelector((state) => state.authorization.loading);

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDate(date);
  };

  const endDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setEndDate(date);
  };

  const hoursChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value)) return;
    setHours(value);
  };

  const hoursBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!!value.length) return;
    setHours(
      item.frequency.id === 2 ? `${item.totalHours}` : `${item.hoursPerWeek}`
    );
  };

  const additionalHoursChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value)) return;
    setAdditionalWeeklyHours(value);
  };

  const addHandler = () => {
    if (!therapistId || !authorizationId || !clientId) return;

    const additionalHoursStartDate = startDateValue?.format(
      "YYYY-MM-DD"
    ) as string;
    const additionalHoursEndDate = endDateValue?.format("YYYY-MM-DD") as string; //todo update date formatting with dateNormolizer

    const data: UpdateAllocatedHoursProperties = {
      authorizationId,
      clientId,
      userId: therapistId,
      allocation: [
        {
          authorizationDetailId,
          hours: parseFloat(hours),
          additionalHoursEndDate,
          additionalHoursStartDate,
          additionalWeeklyHours: parseFloat(additionalWeeklyHours),
        },
      ],
    };
    dispatch(updateAllocatedHours(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(clearActiveAuthorization());
        toast("Success");
        onClose();
      })
      .catch(errorNormalizer);
  };

  const overrideHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (!checked) {
      setAdditionalWeeklyHours("");
      setStartDate(dayjs(new Date()));
      setEndDate(null);
    }
    setOverride(checked);
  };

  useEffect(() => {
    if (!item.additionalWeeklyHours) return;
    setOverride(true);
    setAdditionalWeeklyHours(`${item.additionalWeeklyHours}`);
    setStartDate(dayjs(item.additionalHoursStartDate));
    setEndDate(dayjs(item.additionalHoursEndDate));
  }, [item]);

  useEffect(() => {
    if (!item) return;
    if (item.frequency.id === 2) {
      setHours(`${item.totalHours}`);
      return;
    }
    if (item.frequency.id === 1) {
      setHours(`${item.hoursPerWeek}`);
      return;
    }
  }, [item]);

  return (
    <>
      <div className="marginBottom8">
        <CustomInput
          label={`${item.sessionType.name} - ${item.frequency.name}`}
          value={hours}
          setValue={hoursChangeHandler}
          onBlur={hoursBlurHandler}
          className="marginBottom16"
        />
        <div style={{ display: "flex", gap: "16px" }}>
          {item.frequency.id === 2 && (
            <div>
              <Text title={"Total"} size="tinyBold" />
              <Text title={`Assigned hours: ${item.totalHours}`} size="tiny" />
              <Text
                title={`Unassigned: ${
                  !!availableHours ? availableHours.totalHours : 0
                }`}
                size="tiny"
              />
            </div>
          )}
          {item.frequency.id === 1 && (
            <div>
              <Text title={"Weekly"} size="tinyBold" />
              <Text
                title={`Assigned hours: ${item.hoursPerWeek}`}
                size="tiny"
              />
              <Text
                title={`Unassigned: ${
                  !!availableHours ? availableHours.hoursPerWeek : 0
                }`}
                size="tiny"
              />
            </div>
          )}
        </div>
      </div>
      {item.sessionType.supportAdditionalWeeklyHours && (
        <>
          <CustomCheckBox
            item={{ id: "1", checked: override, label: "Override" }}
            onChange={overrideHandler}
          />
          {override && (
            <div>
              <CustomInput
                label="Weekly hours"
                value={additionalWeeklyHours}
                setValue={additionalHoursChangeHandler}
                className="marginBottom8"
              />
              <TimeInputsWrapper>
                <Picker.CustomDate
                  label="Start date"
                  onChange={startDateHandler}
                  value={startDateValue}
                />
                <Picker.CustomDate
                  label="End Date"
                  onChange={endDateHandler}
                  value={endDateValue}
                />
              </TimeInputsWrapper>
            </div>
          )}
        </>
      )}

      <CustomButton
        title="Save"
        onClick={addHandler}
        className="marginBottom16"
        disabled={loading}
      />
    </>
  );
};

export default AllocationDetails;
