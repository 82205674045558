import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";

import { CheckboxDataProperties } from "../../shared/uiComponents/Checkbox";
import { CustomButton, Text } from "../../shared/uiComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";
import {
  CheckboxWrapper,
  AdminContainer as Container,
} from "../../components/StyledComponents";
import Permissions from "./permission";

import { AddRoleProperties } from "../../redux/API/identityAPIProperties";
import { DispatchProperties, useSelector } from "../../redux/store";
import { getRole, updateRole } from "../../redux/State/identitySlice/roleSlice";

const UpdatePermission = () => {
  const { roleId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const [roles, setRoles] = useState<Array<CheckboxDataProperties>>([]);
  const [section, setSection] = useState<string>("0");

  const roleName = useSelector((state) => state.role.role.name);
  const roleSectionId = useSelector((state) => state.role.role.section.id);
  const isReadonly = useSelector((state) => state.role.role.isReadonly);
  const loading = useSelector((state) => state.role.loading);

  useEffect(() => {
    setSection(roleSectionId.toString());
  }, [roleSectionId]);

  useEffect(() => {
    if (!roleId) return;
    dispatch(getRole(roleId));
  }, [roleId, dispatch]);

  const saveRoleHandler = () => {
    const newClaims = roles
      .filter((role) => role.checked)
      .map((role) => role.id);

    const data: AddRoleProperties = {
      id: roleId,
      section: parseInt(section),
      claims: newClaims,
      name: roleName,
    };
    dispatch(updateRole(data))
      .then(unwrapResult)
      .then(() => navigate(-1))
      .catch(errorNormalizer);
  };

  const updateRoles = useCallback(
    (data: Array<CheckboxDataProperties>) => setRoles(data),
    []
  );

  return (
    <Container>
      <Text
        title={"Edit role"}
        size={"largeBold"}
        className={"marginBottom16"}
      />
      <CheckboxWrapper>
        <div style={{ display: "flex", marginBottom: "16px" }}>
          <Text title={"Role:"} size={"mediumBold"} />
          <Text title={roleName} size={"medium"} className={"marginLeft8"} />
        </div>
        <Permissions
          roles={roles}
          updateRoles={updateRoles}
          section={section}
          setSection={setSection}
        />
      </CheckboxWrapper>
      <CustomButton
        title={"Save role"}
        onClick={saveRoleHandler}
        disabled={isReadonly}
        loading={loading}
      />
    </Container>
  );
};

export default UpdatePermission;
