import EditSteps from "./EditSteps";
import Copy from "./copyTarget";
import Delete from "./deleteTarget";
import Edit from "./EditTarget";
import Hold from "./holdTarget";
import MasterOut from "./masterOutTarget";
import Move from "./moveTarget";
import OpenTarget from "./openTarget";

import {
  TargetProperties,
  TargetTypes,
} from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";
import GraphsTarget from "./TargetGraph/graphsTarget";

const Actions = ({ item }: { item: TargetProperties }) => (
  <div className="actions marginBottom16">
    <OpenTarget target={item} />
    <Edit item={item} programId={item.programId} />
    {item.type.id === TargetTypes.TA && (
      <EditSteps id={item.id} programId={item.programId} />
    )}
    <Copy id={item.id} programId={item.programId} />
    <Delete id={item.id} programId={item.programId} />
    <Move id={item.id} programId={item.programId} />
    <MasterOut item={item} />
    <Hold target={item} />
    <GraphsTarget
      isIcon={false}
      targetId={item.id}
      targetName={item.name}
      type={item.type.id}
    />
  </div>
);

export default Actions;
