import { useDispatch } from "react-redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import {
  getTargets,
  openTarget,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";
import { TargetProperties } from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const OpenTarget = ({ target }: { target: TargetProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [openSure, setOpenSure] = useState<boolean>(false);
  const { id, programId, dateOpened } = target;

  const loading = useSelector((state) => state.programTarget.loading);

  const openTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(openTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenSure(false);
        toast("Opened");
      })
      .catch(errorNormalizer);
  };

  return !dateOpened ? (
    <div>
      <CustomButton
        title="Open Target"
        onClick={() => setOpenSure(true)}
        className="marginBottom8"
      />

      <CustomAlert
        open={openSure}
        onClose={() => setOpenSure(false)}
        title="Are you sure?"
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              title="Open"
              onClick={openTargetHandler}
              loading={loading}
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenSure(false)}
            />
          </div>
        )}
      />
    </div>
  ) : null;
};

export default OpenTarget;
