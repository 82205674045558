import React from "react";
import dayjs from "dayjs";
import "./downloadPdfAnnotations.scss";

const BodyPdfAnnotations = ({
  annotationsList,
  clientName,
  name,
  title1,
  title2,
  reportTemplateRef,
}: {
  annotationsList: any;
  clientName: string;
  name: string;
  title1: string;
  title2: string;
  reportTemplateRef: any;
}) => {
  return (
    <div className="display-none">
      <div ref={reportTemplateRef}>
        <div className="content-annotation">
          <h2>Annotations description</h2>
          <h3>
            <span>{title1}:</span> {clientName}
          </h3>
          <h3>
            <span>{title2}:</span> {name}
          </h3>
          <div className="wrapper-list">
            <div className="title-block">
              <h4>Description</h4>
              <h4>Date</h4>
            </div>
            <div className="body-block">
              {annotationsList &&
                annotationsList.map((item) => (
                  <div key={item.id} className="item-block">
                    <p>{item.note}</p>
                    <p>{dayjs(new Date(item.date)).format("MM-DD-YYYY")}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyPdfAnnotations;
