import { useEffect, useState, useRef, MutableRefObject } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import dayjs from "dayjs";

import CustomSelect from "../../../../../../../shared/uiComponents/Dropdown";
import { TargetGraphNotes } from "../../../../BehaviorReduction/Actions/BehaviorReductionGraph/graphNotes";
import {
  DisplayDataProperties,
  intervalData,
  LabelPeriods,
  Periods,
  ScaleValueProperties,
  commonOptions,
  saveCanvas,
  selectTimeList,
  TargetAnnotationProperties,
} from "../../../../BehaviorReduction/Actions/BehaviorReductionGraph/graphHelpers";

import { LineDisplay, LineDownload } from "./line";
import AnnotationsTarget from "./GraphAnnotations/annotationsTarget";
import BodyPdfAnnotations from "./GraphAnnotations/bodyPdfAnnotations";
import { errorNormalizer } from "../../../../../../../shared/Helpers/functions";
import {
  DispatchProperties,
  useSelector,
} from "../../../../../../../redux/store";
import {
  getDTTGraph,
  getTAGraph,
} from "../../../../../../../redux/State/clientSlice/graphSlice";
import { getClient } from "../../../../../../../redux/State/clientSlice/clientSlice";
import { getTargetAllAnnotations } from "../../../../../../../redux/State/clientSlice/graphAnnotationSlice";
import { TargetTypes } from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";
//TODO update graphsTargetBCBA, BehaviorReductionGraph folder and grapshAnnotations
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  annotationPlugin
);

const GraphTargetContent = ({
  targetId,
  targetName,
  type,
}: {
  targetId: string;
  targetName: string;
  type: TargetTypes;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const today = dayjs();
  const todayString = today.format("MM/DD/YYYY");
  const threeMonthPast = today.subtract(3, "month").format("MM/DD/YYYY");

  const { clientId, programName } = useParams();

  const [selectValue, setSelectValue] = useState<string>("0");
  const [activeInterval, setActiveInterval] = useState<string>("3M");
  const [periodicity, setPeriodicity] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(threeMonthPast);
  const [displayData, setDisplayData] = useState<Array<DisplayDataProperties>>(
    []
  );
  // eslint-disable-next-line
  const [annotationLines, setAnnotationLines] = useState<
    Array<TargetAnnotationProperties>
  >([]);

  const clientInfo = useSelector((state) => state.client.client);
  const annotationsList = useSelector(
    (state) => state.graphAnnotation.targetAnnotations
  );
  const [scaleX, setScaleX] = useState<any>({
    type: "time",
    time: {
      unit: "day",
      tooltipFormat: "PP",
    },
    title: {
      display: true,
      text: "Date",
    },
  });
  const [scaleY] = useState<any>({
    title: {
      display: true,
      text:
        type === TargetTypes.DTT ? "% Correct Responding" : "% Steps Correct",
    },
    suggestedMin: 0,
    suggestedMax: 100,
  });

  const handleIntervalChange = (interval: string) => {
    setActiveInterval(interval);
    const date = intervalData.find((x) => x.id === interval);
    if (!date) return;
    setStartDate(date.value);
  };

  const selectValueHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectValue(value);

    const unitValue = parseInt(value);
    setPeriodicity(`${unitValue + 1}`);

    const scaleX: ScaleValueProperties = {
      type: "time",
      time: {
        unit: Periods[unitValue],
      },
      title: {
        display: true,
        text: LabelPeriods[unitValue],
      },
    };
    setScaleX(scaleX);
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClient(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (
      !commonOptions.plugins ||
      !commonOptions.plugins.title ||
      !programName ||
      !targetName ||
      !clientInfo.fullName
    )
      return;
    const title = `${clientInfo.fullName} ${programName} ${targetName}`;
    commonOptions.plugins.title.text = title;
  }, [programName, targetName, clientInfo.fullName]);

  useEffect(() => {
    if (!targetId) return;
    if (type === TargetTypes.DTT) {
      dispatch(
        getDTTGraph({
          targetId,
          startDate,
          endDate: todayString,
          periodicity,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const data = response.map((item) => ({
            x: dayjs(item.date).format("YYYY-MM-DD"),
            y: item.percentage,
          }));
          setDisplayData(data);
        })
        .catch(errorNormalizer);
    }
    if (type === TargetTypes.TA) {
      dispatch(
        getTAGraph({
          targetId,
          startDate,
          endDate: todayString,
          periodicity,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const data = response.map((item) => ({
            x: dayjs(item.date).format("YYYY-MM-DD"),
            y: item.percentage,
          }));
          setDisplayData(data);
        })
        .catch(errorNormalizer);
    }
  }, [targetId, startDate, periodicity, type, todayString, dispatch]);

  useEffect(() => {
    if (
      !commonOptions.plugins ||
      !commonOptions.plugins.annotation ||
      !annotationsList
    )
      return;
    const dataAnnotations = annotationsList
      .map((item) => ({
        id: item.id,
        targetId: item.targetId,
        note: item.note,
        date: dayjs(item.date).format("MM/DD/YYYY"),
      }))
      .filter((item) => new Date(item.date) >= new Date(startDate));
    setAnnotationLines(dataAnnotations);
    const annotationsAll = annotationsList
      .map((annotation) => ({
        type: "line",
        borderColor: "red",
        borderWidth: 2,
        scaleID: "x",
        id: annotation.id,
        value: new Date(annotation.date),
      }))
      .filter((item) => new Date(item.value) >= new Date(startDate));
    commonOptions.plugins.annotation.annotations = annotationsAll;
  }, [annotationsList, startDate]);

  useEffect(() => {
    dispatch(getTargetAllAnnotations(targetId))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [targetId, dispatch]);

  const filteredAnnotations = annotationsList.filter(
    (annotation) => new Date(annotation.date) >= new Date(startDate)
  );
  const reportTemplateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      callback: (doc) =>
        doc.save(
          `${today.format("hh_mm_MM/DD/YYYY")}-${
            clientInfo.fullName
          }-${targetName}`
        ),
    });
  };

  const handleOnclickDownload = () => {
    const fileName = `${today.format("hh_mm_MM/DD/YYYY")}-${
      clientInfo.fullName
    }-${targetName}`;
    saveCanvas(fileName);
    handleGeneratePdf();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <AnnotationsTarget targetId={targetId} targetName={targetName} />
      <LineDisplay
        displayData={displayData}
        scaleX={scaleX}
        scaleY={scaleY}
        startDate={startDate}
      />

      <LineDownload
        displayData={displayData}
        scaleX={scaleX}
        scaleY={scaleY}
        startDate={startDate}
      />
      <div className="btnContainer">
        <div className="filterBlock">
          <button
            className="btnFilter"
            onClick={handleOnclickDownload}
            style={{ borderLeft: "none" }}
          >
            Download
          </button>
          {intervalData.map((interval, i) => (
            <div key={i} className="wrapper-filter">
              <div
                className={`${
                  activeInterval === interval.id
                    ? "activeBtn btnFilter"
                    : "btnFilter"
                }`}
                onClick={() => handleIntervalChange(interval.id)}
              >
                {interval.id}
              </div>
            </div>
          ))}
        </div>
        <CustomSelect
          data={selectTimeList}
          value={selectValue}
          setValue={selectValueHandler}
        />
      </div>
      <BodyPdfAnnotations
        reportTemplateRef={reportTemplateRef}
        title1="Client Name"
        title2="Target Name"
        clientName={clientInfo.fullName}
        name={targetName}
        annotationsList={filteredAnnotations}
      />
      <TargetGraphNotes targetId={targetId} />
    </div>
  );
};

export default GraphTargetContent;
