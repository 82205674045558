import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Menu as MenuIcon,
  DeleteOutlineOutlined as Delete,
  RemoveRedEyeOutlined as Eye,
} from "@mui/icons-material";

import {
  DTTPromptInfoContent,
  TAPromptInfoContent,
} from "./promptsHistoryInfoContents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { CustomAlert, CustomButton, Text } from "../../../shared/uiComponents";
import {
  useFetchTargetHistoryDTT,
  useFetchTargetHistoryTA,
} from "../DataCollection/helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  saveDTTSessionInfo,
  saveTASessionInfo,
} from "../../../redux/State/clientSlice/dataCollectionSlice";
import {
  getSessionTargets,
  setActiveTargetHidden,
  setOpenTargetNew,
} from "../../../redux/State/clientSlice/sessionSlice";
import {
  ClientTargetProperties,
  StepProperties,
  TargetTypes,
  UpdateTargetStepsProperties,
} from "../../../redux/API/ClientAPIHelpers/programTargetsProperties";
import { TargetSessionStatuses } from "../../../redux/API/ClientAPIHelpers/sessionsProperties";

export interface TargetDataProperties {
  targetId: string;
  targetInstructions: string;
  targetType: TargetTypes;
  targetSteps: Array<UpdateTargetStepsProperties>;
}

const Actions = ({
  target,
  targetSessionStatus,
  steps,
}: {
  targetSessionStatus: TargetSessionStatuses;
  target?: ClientTargetProperties;
  steps: Array<UpdateTargetStepsProperties> | null;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  if (!target) return <Text title={"No target"} />;

  const { id, targetInstructions, programId, type, sd } = target;

  const onDeleteClick = () => {
    dispatch(setOpenTargetNew(id));
  };

  const onHideClick = () => {
    dispatch(setActiveTargetHidden(id));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Text title="Instruction:" className="margin8" size="smallBold" />
          <Text
            title={
              !!targetInstructions ? targetInstructions : "No instructions"
            }
            className="marginBottom16 marginLeft8"
          />
          <Text title="SD:" className="margin8" size="smallBold" />
          <Text
            title={!!sd ? sd : "No SD"}
            className="marginBottom16 marginLeft8"
          />
        </div>
        <div>
          {type.id === TargetTypes.DTT && (
            <TargetDTTActionsHistory
              targetId={id}
              targetSessionStatus={targetSessionStatus}
            />
          )}
          {type.id === TargetTypes.TA && (
            <TargetTAActionsHistory
              targetId={id}
              targetSessionStatus={targetSessionStatus}
            />
          )}
          {targetSessionStatus === TargetSessionStatuses.open && (
            <Delete
              onClick={onDeleteClick}
              style={{ cursor: "pointer", marginLeft: "16px" }}
            />
          )}
          {targetSessionStatus === TargetSessionStatuses.active && (
            <Eye
              onClick={onHideClick}
              style={{ cursor: "pointer", marginLeft: "16px" }}
            />
          )}
        </div>
      </div>
      {type.id === TargetTypes.DTT && <TargetDTTActions targetId={id} />}
      {type.id === TargetTypes.TA && (
        <TargetTAActions targetId={id} programId={programId} steps={steps} />
      )}
    </>
  );
};

const TargetDTTActions = ({ targetId }: { targetId: string }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const { sessionId } = useParams();
  const [promptId, setPromptId] = useState<string>("");

  const promptsData = useSelector((state) => state.dataCollection.promptCodes);

  const { fetchHistory } = useFetchTargetHistoryDTT(targetId);

  const fetchActiveTargets = useCallback(() => {
    if (!sessionId) return;
    dispatch(getSessionTargets(sessionId));
  }, [sessionId, dispatch]);

  const onClickHandler = (promptCodeId: string) => {
    if (!promptCodeId || !sessionId || !targetId) return;
    setPromptId(promptCodeId);
    dispatch(saveDTTSessionInfo({ promptCodeId, sessionId, targetId }))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        fetchActiveTargets();
      })
      .catch(errorNormalizer);
  };

  return (
    <div>
      <div
        className="marginTop16 marginLeft8 prompts"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {promptsData.map((item, index) => (
          <CustomButton
            title={item.label}
            key={index}
            secondaryButton={!index}
            onClick={() => onClickHandler(item.id)}
            className={`button150 ${item.id === promptId ? "blink" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

const TargetTAActions = ({
  targetId,
  steps,
}: {
  targetId: string;
  programId: string;
  steps: Array<UpdateTargetStepsProperties> | null;
}) => {
  return (
    <div>
      <div className="marginTop16 marginLeft8 ">
        {!!steps &&
          steps.map((step, index) => (
            <TAPrompt key={index} step={step} targetId={targetId} />
          ))}
      </div>
    </div>
  );
};

const TAPrompt = ({
  step,
  targetId,
}: {
  step: UpdateTargetStepsProperties;
  targetId: string;
}) => {
  const { sessionId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();
  const { fetchHistory } = useFetchTargetHistoryTA(targetId);

  const [promptId, setPromptId] = useState<string>("");
  const promptsData = useSelector((state) => state.dataCollection.promptCodes);

  const fetchActiveTargets = useCallback(() => {
    if (!sessionId) return;
    dispatch(getSessionTargets(sessionId));
  }, [sessionId, dispatch]);

  const onButtonClick = (promptCodeId: string) => {
    const data: StepProperties = {
      promptCodeId,
      stepId: step.id,
    };
    if (!sessionId || !targetId) return;
    setPromptId(promptCodeId);
    dispatch(saveTASessionInfo({ responses: [data], sessionId, targetId }))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        fetchActiveTargets();
      })
      .catch(errorNormalizer);
  };

  return (
    <div className="marginTop16 marginLeft8 ">
      <Text
        title={step.name}
        size="largeBold"
        className="marginTop16 marginBottom16"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {promptsData.map((item, index) => (
          <CustomButton
            title={item.label}
            key={index}
            secondaryButton={!index}
            onClick={() => onButtonClick(item.id)}
            className={`button150 ${item.id === promptId ? "blink" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

const TargetDTTActionsHistory = ({
  targetId,
  targetSessionStatus,
}: {
  targetId: string;
  targetSessionStatus: TargetSessionStatuses;
}) => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { promptsDTT, fetchHistory } = useFetchTargetHistoryDTT(targetId);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  if (targetSessionStatus === TargetSessionStatuses.open) {
    return <div style={{ width: "24px", height: "24px" }} />;
  }

  return (
    <>
      <MenuIcon onClick={() => setHistoryOpen(true)} />
      <CustomAlert
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        title="History"
        Content={() => (
          <div className="marginBottom16">
            {promptsDTT.map((x, index) => (
              <DTTPromptInfoContent
                key={index}
                targetId={targetId}
                item={x}
                fetchHistory={fetchHistory}
              />
            ))}
          </div>
        )}
      />
    </>
  );
};

const TargetTAActionsHistory = ({
  targetId,
  targetSessionStatus,
}: {
  targetId: string;
  targetSessionStatus: TargetSessionStatuses;
}) => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { promptsTA, fetchHistory } = useFetchTargetHistoryTA(targetId);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  if (targetSessionStatus === TargetSessionStatuses.open) {
    return <div style={{ width: "24px", height: "24px" }} />;
  }

  return (
    <>
      <MenuIcon onClick={() => setHistoryOpen(true)} />
      <CustomAlert
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        title="History"
        Content={() => (
          <div className="marginBottom16">
            {promptsTA.map((x, index) => (
              <TAPromptInfoContent
                key={index}
                targetId={targetId}
                item={x}
                fetchHistory={fetchHistory}
              />
            ))}
          </div>
        )}
      />
    </>
  );
};

export default Actions;
