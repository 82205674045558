import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { AddOutlined, DeleteOutlineOutlined } from "@mui/icons-material";

import "../reportStyles.scss";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { CustomButton, Text } from "../../../shared/uiComponents";
import SearchSelect from "../../../shared/uiComponents/Dropdown/searchSelect";
import { DataProperties } from "../../../shared/uiComponents/Radio";
import { downloadFile, ReportFilters, ReportWrapper } from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getUtilizationReport } from "../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { getClients } from "../../../redux/State/clientSlice/clientSlice";

const UtilizationReport = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [clientsData, setclientsData] = useState<Array<DataProperties>>([]);
  const [clientIds, setClientIds] = useState<Array<string>>([]);
  const [clients, setClients] = useState<Array<DataProperties>>([]);
  const [clientId, setClientId] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");

  const filterData = useSelector((state) => state.billing.billingFilter);
  const loading = useSelector((state) => state.authorization.loading);
  const loadingExport = useSelector((state) => state.export.loading);
  const loadingClients = useSelector((state) => state.client.loading);

  useEffect(() => {
    if (!pageSize) return;

    const timeout = setTimeout(() => {
      dispatch(getClients({ page: "1", pageSize: `${pageSize}`, searchString }))
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<DataProperties> = response.query
            .filter((x) => !clientIds.includes(x.id))
            .map((client) => ({
              id: client.id,
              label: client.fullName,
            }));
          setclientsData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, pageSize, searchString, clientIds]);

  const downloadHandler = useCallback(() => {
    const { startDate, endDate } = filterData;

    dispatch(
      getUtilizationReport({
        clientIds,
        startDate,
        endDate,
      })
    )
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  }, [dispatch, clientIds, filterData]);

  const addHandler = () => {
    if (!clientId) return;
    setClientIds((prev) => [...prev, clientId]);
    const client = clientsData.find((x) => x.id === clientId);
    setClientId("");
    setSearchString("");
    if (!client) return;
    setClients((prev) => [...prev, client]);
  };

  const onClientSelectChange = (id: string) => {
    if (!id) return;
    setClientId(id);
  };

  const onClientRemove = (id: string) => {
    if (!id) return;
    setClientIds((prev) => prev.filter((x) => x !== id));
    setClients((prev) => prev.filter((x) => x.id !== id));
  };

  return (
    <ReportWrapper>
      {!!clients && !!clients.length && (
        <div
          className="marginBottom8"
          style={{
            width: "max-content",
            border: "1px solid black",
            borderRadius: "12px",
            padding: "8px",
          }}
        >
          <Text
            title={"Selected Clients:"}
            size="smallBold"
            className="marginBottom8"
          />
          {clients.map((x, index) => (
            <div style={{ display: "flex", gap: "16px" }}>
              <Text title={`${index + 1}. ${x.label}`} />
              <DeleteOutlineOutlined
                onClick={() => onClientRemove(x.id)}
                fontSize="small"
              />
            </div>
          ))}
        </div>
      )}
      <div style={{ width: "250px", display: "flex", gap: "16px" }}>
        <SearchSelect
          label="Select Clients"
          searchString={searchString}
          setSearchString={setSearchString}
          data={clientsData}
          setValue={onClientSelectChange}
          loadMore={{
            activate: true,
            setSize: setPageSize,
          }}
          loading={loadingClients || loadingExport}
        />
        <AddOutlined
          fontSize="large"
          onClick={addHandler}
          className="marginTop24"
        />
      </div>
      <ReportFilters showUsers={false} showClients={false} />
      <div style={{ width: "max-content" }}>
        <CustomButton
          title="Download"
          onClick={downloadHandler}
          loading={loading}
        />
      </div>
    </ReportWrapper>
  );
};

export default UtilizationReport;
