import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { CustomAlert, CustomButton, Text } from "../../../shared/uiComponents";
import { AdminTypes } from "../../../components/Menu";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useResetCalendarData } from "../Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  endSession,
  getSessionInfo,
} from "../../../redux/State/clientSlice/sessionSlice";

const END_SESSION_BT_NOTE = "*Note: Please ensure you have taken data on at least 3 goals per hour and recorded any behaviors observed";

const EndSession = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sessionId, clientId } = useParams();

  const { resetData } = useResetCalendarData();

  const [open, setOpen] = useState<boolean>(false);

  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    if (!sessionId) return;

    dispatch(getSessionInfo(sessionId));
  }, [sessionId, dispatch]);

  const endSessionHandler = () => {
    if (!sessionId) return;
    dispatch(endSession(sessionId))
      .then(unwrapResult)
      .then(() => {
        resetData();
        const reportId = state?.reportId;
        navigate(
          `/soap-notes/info?sessionId=${sessionId}&clientId=${clientId}${
            !!reportId ? `&reportId=${reportId}` : ""
          }`
        );
      })
      .catch(errorNormalizer)
      .finally(() => setOpen(false));
  };

  return (
    <div style={{ maxWidth: "760px" }} className="padding16">
      <CustomButton title="End session" onClick={() => setOpen(true)} />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title="Are you sure you want to end session?*"
        Content={() => {
          return (
            <div>
              <div
                style={{ display: "flex", justifyContent: "space-around" }}
                className="marginBottom16"
              >
                <CustomButton title="Yes" onClick={endSessionHandler} />
                <CustomButton
                  title="No"
                  secondaryButton
                  onClick={() => setOpen(false)}
                />
              </div>
              {!!role && role.section.id === AdminTypes.bt && (
                <Text
                  title={END_SESSION_BT_NOTE}
                  size="tinyBold"
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default EndSession;
