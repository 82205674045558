import { CustomAccordion, Text } from "../../../shared/uiComponents";
import Actions from "./actions";

import { ClientTargetProperties } from "../../../redux/API/ClientAPIHelpers/programTargetsProperties";
import { TargetSessionStatuses } from "../../../redux/API/ClientAPIHelpers/sessionsProperties";
import { useSelector } from "../../../redux/store";

export interface CustomClientTargetProperties extends ClientTargetProperties {
  targetSessionStatus: TargetSessionStatuses;
}

const SessionPrograms = () => {
  const activeClientTargets = useSelector(
    (state) => state.session.activeClientTargets
  );
  const targets = useSelector((state) => state.session.targets);

  return (
    <div
      style={{
        marginBottom: "16px",
        maxWidth: "760px",
        width: "100%",
      }}
    >
      {targets
        .filter((x) => x.targetSessionStatus !== TargetSessionStatuses.hidden)
        .filter((x) => x.targetSessionStatus !== TargetSessionStatuses.new)
        .sort((a, b) => {
          if (a.orderId < b.orderId) return 1;
          return -1;
        })
        .map((item, index) => (
          <CustomAccordion
            key={index}
            className="marginBottom16"
            HeaderContent={headerBuilder(item)}
            Content={() => (
              <Actions
                target={activeClientTargets.find((x) => x.id === item.id)}
                steps={item.steps}
                targetSessionStatus={item.targetSessionStatus}
              />
            )}
          />
        ))}
    </div>
  );
};

const headerBuilder = ({
  targetSessionStatus,
  id,
}: {
  targetSessionStatus: TargetSessionStatuses;
  id: string;
}) => {
  switch (targetSessionStatus) {
    case TargetSessionStatuses.active:
      return () => ActiveProgramHeaders({ id });

    case TargetSessionStatuses.open:
      return () => OpenProgramHeaders({ id });
    default:
      return () => <Text title={"Something wrong"} />;
  }
};

const OpenProgramHeaders = ({ id }: { id: string }) => {
  const activeClientTargets = useSelector(
    (state) => state.session.activeClientTargets
  );
  const item = activeClientTargets.find((x) => x.id === id);
  if (!item) return <Text title={"Not found"} />;
  const { program, name } = item;
  return (
    <div>
      <div style={{ display: "flex", gap: "64px", marginBottom: "16px" }}>
        <div>
          <Text title={"Program:"} />
          <Text title={program.name} size="smallBold" />
        </div>
        <div>
          <Text title={"Target:"} />
          <Text title={name} size="smallBold" />
        </div>
      </div>
    </div>
  );
};

const ActiveProgramHeaders = ({ id }: { id: string }) => {
  const activeClientTargets = useSelector(
    (state) => state.session.sessionTargets
  );
  const item = activeClientTargets.find((x) => x.target.id === id);
  if (!item) return <Text title={"Not found"} />;
  const { program, target, trials, independent, prompt, correctPercentage } =
    item;
  return (
    <div>
      <div style={{ display: "flex", gap: "64px", marginBottom: "16px" }}>
        <div>
          <Text title={"Program:"} />
          <Text title={program.name} size="smallBold" />
        </div>
        <div>
          <Text title={"Target:"} />
          <Text title={target.name} size="smallBold" />
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px" }}>
        <Text title={`Trials: ${trials}`} />
        <Text title={`Independent: ${independent}, ${correctPercentage}%`} />
        <Text title={`Prompted: ${prompt}`} />
      </div>
    </div>
  );
};

export default SessionPrograms;
