import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import { CustomTable, Text } from "../../../shared/uiComponents";
import { Header } from "../../../components/StyledComponents";
import SearchBar from "../../../components/SearchBar";
import { ReportFilters } from "../../Reports/helpers";
import { Container, FilterWrapper } from "./helpers";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getSoapNotesAdmin } from "../../../redux/State/clientSlice/soapNoteSlice";

const AdminSOAPNotes = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const soapNotes = useSelector((state) => state.soapNote.soapNotes);
  const filterData = useSelector((state) => state.billing.billingFilter);
  const loading = useSelector((state) => state.soapNote.loading);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;
    dispatch(
      getSoapNotesAdmin({
        page,
        pageSize,
        direction,
        orderBy,
        searchString,
        status,
        startDate,
        endDate,
        clientId,
        userId,
      })
    )
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [params, filterData, dispatch]);

  return (
    <Container>
      <Header className="padding8">
        <Text title={"SOAP Notes"} size="mediumBold" />
        <Header>
          <SearchBar />
        </Header>
      </Header>
      <FilterWrapper>
        <ReportFilters showUsers={true} showShorcuts={false} />
      </FilterWrapper>
      <CustomTable
        headers={Headers}
        data={soapNotes}
        TableBody={TableBody}
        loading={loading}
      />
    </Container>
  );
};

export default AdminSOAPNotes;
