import { useDispatch } from "react-redux";
import {
  ChevronLeft as Left,
  ChevronRight as Right,
} from "@mui/icons-material";

import { Text } from "../../../shared/uiComponents";
import { months } from "../Helpers/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearEvents } from "../../../redux/State/clientSlice/calendarSlice";
import {
  setMonth,
  setLastDayOfMonth,
  setLastDayOfPreviousMonth,
  setWeekDay,
  setYear,
} from "../../../redux/State/clientSlice/calendarSlice";

const Navigation = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const month = useSelector((state) => state.calendar.month);
  const year = useSelector((state) => state.calendar.year);

  const actionHandler = (month: number, year: number) => {
    dispatch(clearEvents());
    dispatch(setMonth(month));

    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    dispatch(setLastDayOfMonth(lastDayOfMonth));

    const lastDayOfLastMonth = new Date(year, month, 0).getDate();
    dispatch(setLastDayOfPreviousMonth(lastDayOfLastMonth));

    const nextWeekDay = new Date(year, month, 1).getDay();
    dispatch(setWeekDay(nextWeekDay));
  };

  const nextHandler = () => {
    dispatch(clearEvents());

    if (month === 11) {
      const nextYear = year + 1;

      dispatch(setYear(nextYear));
      actionHandler(0, nextYear);
      return;
    }

    const nextMonth = month + 1;
    actionHandler(nextMonth, year);
  };

  const prevHandler = () => {
    dispatch(clearEvents());

    if (month === 0) {
      const previousYear = year - 1;

      dispatch(setYear(previousYear));
      actionHandler(11, previousYear);
      return;
    }

    const prevMonth = month - 1;
    actionHandler(prevMonth, year);
  };

  return (
    <div className="date">
      <div onClick={prevHandler}>
        <Left fontSize="large" style={{ cursor: "pointer" }} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "200px",
          width: "100%",
          maxWidth: "270px",
        }}
      >
        <Text
          title={months[month]}
          className={"marginRight16"}
          size={"largeBold"}
        />
        <Text title={`${year}`} size={"largeBold"} />
      </div>

      <div onClick={nextHandler}>
        <Right fontSize="large" style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};

export default Navigation;
