import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import Delete from "../Delete";
import { CustomAccordion, Text } from "../../shared/uiComponents";
import { Loader, InfoRow as Row } from "../StyledComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../redux/store";
import { UserProperties } from "../../redux/API/userAPIProperties";
import {
  dismissClient,
  getUserClients,
} from "../../redux/State/clientSlice/userClientSlice";
import { DismissClientProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";
import { InfoClientProperties } from "../../redux/API/ClientAPIHelpers/clientProperties";

const ListItem = ({
  client,
  index,
  userId,
}: {
  client: InfoClientProperties;
  index: number;
  userId: string;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const fetchUserClients = useCallback(() => {
    if (!userId) return;
    dispatch(getUserClients({ userId, page: "1", pageSize: "8" }));
  }, [userId, dispatch]);

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    dispatch(dismissClient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        fetchUserClients();
      })
      .catch(errorNormalizer);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      key={index}
    >
      <Text
        title={`${index + 1}. ${client.fullName}`}
        className={"marginRight8"}
      />
      {!!client.id && !!userId && (
        <Delete
          deleteHandler={() =>
            dismissHandler({
              clientId: client.id,
              userId,
            })
          }
        />
      )}
    </div>
  );
};

const ClientsList = ({ item }: { item: UserProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { id } = item;

  const [clientsPageSize, setClientsPageSize] = useState<number>(8);

  const clients = useSelector((state) => state.userClient.userClients);
  const loading = useSelector(
    (state) => state.userClient.loadingGetUserClients
  );

  useEffect(() => {
    if (!id) return;
    dispatch(
      getUserClients({
        userId: id,
        page: "1",
        pageSize: clientsPageSize.toString(),
      })
    );
  }, [id, clientsPageSize, dispatch]);

  return (
    <CustomAccordion
      headerStyle={{ padding: 0 }}
      HeaderContent={() => (
        <Row>
          <Text title={"Clients Assigned: "} size={"smallBold"} />
          <Text title={`${clients.totalNumberOfItems}`} />
        </Row>
      )}
      Content={() => (
        <>
          {!!clients.query?.length && !loading ? (
            <>
              {clients.query.map((client, index) => (
                <ListItem index={index} client={client} userId={id} />
              ))}
              {clients.hasNextPage && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Text
                    title="Load More"
                    size="smallBold"
                    onClick={() => setClientsPageSize((prev) => prev + 8)}
                  />
                </div>
              )}
            </>
          ) : (
            <Text title={"None"} />
          )}
          {loading && <Loader />}
        </>
      )}
    />
  );
};
export default ClientsList;
