import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  PersonOutlined as Person,
  AdminPanelSettingsOutlined as Admin,
  PermContactCalendarOutlined as Auth,
  PeopleOutline as Users,
} from "@mui/icons-material";

import {
  Text,
  MobilePagination,
  UserStatusBadge,
  UserEmailStatusBadge,
} from "../../shared/uiComponents";
import {
  InfoRow,
  Loader,
  Row,
  Wrapper,
} from "../../components/StyledComponents";
import MobileSort from "../../components/MobileSort";
import UserAction from "../../components/UserAction";
import { AdminDataProperties } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getBCBAs } from "../../redux/State/userSlice";
import { SelectDataProperties } from "../../shared/uiComponents/Dropdown";

const sortData: Array<SelectDataProperties> = [
  { id: "fullName", name: "Full Name" },
  { id: "numberOfClients", name: "Number of Clients" },
  { id: "emailConfirmed", name: "Email Status" },
  { id: "isDeactivated", name: "Account Status" },
  { id: "authorizationType", name: "Authorization Type" },
];

const MobileView: FC<AdminDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;
  const [params] = useSearchParams();

  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector((state) => state.user.loading);

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBCBAs({ page, pageSize, orderBy, direction, searchString }));
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={sortData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "flex-end" }}>
              <UserAction item={item} fetchUsers={fetchUsers} />
            </Row>
            <Row>
              <Person />
              <Text title={item.fullName} className={"marginLeft8"} />
            </Row>
            <Row>
              <Users />
              <Text title={item.numberOfClients} className={"marginLeft8"} />
            </Row>
            <Row>
              <Auth />
              <Text
                title={
                  !!item.authorizationType ? item.authorizationType.name : "-"
                }
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Admin />
              <Text title={item.role.section.name} className={"marginLeft8"} />
            </Row>
            <InfoRow>
              <Text title={"Account Status"} size="tinyBold" />
              <UserStatusBadge active={!item.isDeactivated} />
            </InfoRow>
            <InfoRow>
              <Text title={"Email Status"} size="tinyBold" />
              <UserEmailStatusBadge confirmed={item.emailConfirmed} />
            </InfoRow>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
    </div>
  );
};

export default MobileView;
