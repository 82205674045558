import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CustomAccordion, Text } from "../../shared/uiComponents";
import { InfoRow as Row } from "../StyledComponents";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getUserInsurances } from "../../redux/State/userSlice";

const InsuranceList = ({ id }: { id: string }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const userInsurances = useSelector((state) => state.user.userInsurances);

  useEffect(() => {
    if (!id) return;
    dispatch(getUserInsurances(id));
  }, [id, dispatch]);

  return !!userInsurances.length ? (
    <CustomAccordion
      headerStyle={{ padding: 0 }}
      HeaderContent={() => (
        <Row>
          <Text title={"Insurances: "} size={"smallBold"} />
          <Text title={`${userInsurances.length}`} />
        </Row>
      )}
      Content={() => (
        <>
          {userInsurances.map((insurance, index) => (
            <Text
              title={`${index + 1}. ${insurance.name}`}
              className={"marginRight8"}
            />
          ))}
        </>
      )}
    />
  ) : null;
};

export default InsuranceList;
