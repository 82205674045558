import { FC } from "react";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

import { HeaderProperties } from "../../../../shared/uiComponents/Table/tableProperties";
import { TableNoData } from "../../../../shared/uiComponents";

import { BehaviorReductionProperties } from "../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import Actions from "./Actions";
import { useSelector } from "../../../../redux/store";
import { AdminTypes } from "../../../../components/Menu";

export interface BehaviorRowRendererProperties {
  data: Array<BehaviorReductionProperties>;
}

export const behaviorHeaders: Array<HeaderProperties> = [
  { id: "0", name: "Behavior name" },
  { id: "1", name: "Date created" },
  { id: "2", name: "Behavior type" },
  { id: "3", name: "Behavior Category" },
  { id: "4", name: "Actions" },
];

export const behaviorHeadersBT: Array<HeaderProperties> = [
  { id: "0", name: "Behavior name" },
  { id: "1", name: "Date created" },
  { id: "2", name: "Behavior type" },
  { id: "3", name: "Behavior Category" },
];

export const BehaviorTableBody: FC<BehaviorRowRendererProperties> = ({
  data,
}) => {
  const role = useSelector((state) => state.account.role);

  if (!data || !data.length) {
    return <TableNoData spanScope={behaviorHeaders.length} />;
  }

  return (
    <Body>
      {data.map((row) => (
        <TableRow key={row.id} style={{ cursor: "pointer" }}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
          <TableCell>{row.recordingType.text}</TableCell>
          <TableCell>{row.category.name}</TableCell>
          {!!role && role.section.id !== AdminTypes.bt && (
            <TableCell style={{ display: "flex", gap: "16px" }}>
              <Actions row={row} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </Body>
  );
};
