import { useState } from "react";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { CustomAlert, Text } from "../../../shared/uiComponents";
import { InfoRow } from "../../../components/StyledComponents";

import {
  BillingProperties,
  ModifiedSessionProperties,
} from "../../../redux/API/ClientAPIHelpers/billingProperties";

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Session Date", orderBy: "sessionDate" },
  { id: "3", name: "Session Type" },
  { id: "4", name: "Provider" },
  { id: "5", name: "Modified" },
  { id: "6", name: "Submit Date", orderBy: "reportSubmittedAt" },
  { id: "7", name: "Download Date", orderBy: "reportExportedAt" },
];

const ModifiedSessionDetails = ({
  item,
}: {
  item: ModifiedSessionProperties;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return (
    <>
      <CheckOutlined onClick={onOpen} />
      <CustomAlert
        open={open}
        onClose={onClose}
        title="Change Details"
        Content={() => {
          const {
            originalDate,
            originalEndTime,
            originalStartTime,
            dateModifiedAt,
            dateModifiedBy,
          } = item;
          return (
            <>
              <InfoRow>
                <Text title={"Original Date"} size="smallBold" />
                <Text
                  title={dayjs(originalDate).utc(false).format("MM/DD/YYYY")}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Original Start Time"} size="smallBold" />
                <Text
                  title={dayjs(originalStartTime).utc(false).format("hh:mm A")}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Original End Time"} size="smallBold" />
                <Text
                  title={dayjs(originalEndTime).utc(false).format("hh:mm A")}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Modified by"} size="smallBold" />
                <Text title={dateModifiedBy.fullName} />
              </InfoRow>
              <InfoRow>
                <Text title={"Modified Date"} size="smallBold" />
                <Text
                  title={dayjs(dateModifiedAt).utc(false).format("MM/DD/YYYY")}
                />
              </InfoRow>
            </>
          );
        }}
      />
    </>
  );
};

export const TableBody = ({ data }: { data: Array<BillingProperties> }) => {
  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>
            {dayjs.utc(row.sessionDate).format("MM-DD-YYYY")}
          </TableCell>
          <TableCell>{row.sessionType.name}</TableCell>
          <TableCell>{!!row.billedBy ? row.billedBy.fullName : "-"}</TableCell>
          <TableCell>
            {!!row.sessionDateModifiedDetails ? (
              <ModifiedSessionDetails item={row.sessionDateModifiedDetails} />
            ) : (
              <CloseOutlined />
            )}
          </TableCell>
          <TableCell>
            {dayjs(row.reportSubmittedAt).format("MM-DD-YYYY")}
          </TableCell>
          <TableCell>
            {!!row.reportExportedAt
              ? dayjs(row.reportExportedAt).format("MM-DD-YYYY")
              : "Not downloaded"}
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
