import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import {
  CustomButton,
  CustomAlert,
  Text,
} from "../../../../../../shared/uiComponents";
import { blue400 } from "../../../../../../shared/Helpers/colors";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import {
  getMoveTargetOptions,
  getTargets,
  moveTarget,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";

const Move = ({ id, programId }: { id: string; programId: string }) => {
  const [openMove, setOpenMove] = useState<boolean>(false);

  return (
    <>
      <CustomButton title="Move" onClick={() => setOpenMove(true)} />
      <CustomAlert
        open={openMove}
        onClose={() => setOpenMove(false)}
        title="Choose program to move"
        Content={() => (
          <MoveProgramContent
            id={id}
            programId={programId}
            onClose={() => setOpenMove(false)}
          />
        )}
      />
    </>
  );
};

const MoveProgramContent = ({
  id,
  programId,
  onClose,
}: {
  id: string;
  programId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const options = useSelector((state) => state.programTarget.moveOptions);
  const loading = useSelector(
    (state) => state.programTarget.loadingTargetOptions
  );

  useEffect(() => {
    if (!id || !programId) return;
    dispatch(getMoveTargetOptions({ id, programId }));
  }, [id, programId, dispatch]);

  const moveTargetHandler = (toProgramId: string) => {
    if (!id || !programId || !toProgramId) return;

    dispatch(moveTarget({ id, programId, toProgramId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        onClose();
        toast("Successful");
      })
      .catch(errorNormalizer);
  };

  return (
    <div>
      {!loading ? (
        options.map((option) => (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="marginBottom8"
          >
            <Text
              title={`${option.skillArea.name} -> ${option.program.name}`}
            />
            <Text
              title="Move"
              size="smallBold"
              textColor={blue400}
              onClick={() => moveTargetHandler(option.program.id)}
            />
          </div>
        ))
      ) : (
        <div style={{ width: "max-content", margin: "auto" }}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default Move;
