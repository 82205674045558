import { TargetAnnotationProperties } from "./graphAnnotationProperties";


export interface ProgramTargetProperties {
    name: string;
    goalName: string;
    sd: string;
    targetInstructions: string;
    errorCorrection: string;
    reinforcement: string;
    consecutiveDays: number | null;
    responsePercentage: number | null;
}

export interface GetTargetProperties {
    programId: string;
    id: string;
}

export interface CreateProgramTargetProperties extends ProgramTargetProperties {
    typeId: TargetTypes;
    steps?: Array<{ name: string }>;
    masteryCriteria: number;
}

export interface MoveTargetProperties extends GetTargetProperties {
    toProgramId: string;
}

export interface UpdateTargetProperties extends GetTargetProperties {
    data: ProgramTargetProperties;
}

export interface AddTargetSteps {
    programId: string;
    id: string;
    data: Array<TargetStepsDataProperties | UpdateTargetStepsProperties>;
}

export enum TargetTypes {
    "DTT" = 0,
    "TA" = 4,
}

export enum MasteryTypes {
    "AUTOMATIC" = 1,
    "MANUAL" = 2,
}

export interface TargetStepsDataProperties {
    name: string;
    note: string;
    enabled: boolean;
    useForMastery: boolean;
    controlId: string;
    orderNumber: number;
}

export interface UpdateTargetStepsProperties extends TargetStepsDataProperties {
    id: string;
}

export interface StepProperties {
    stepId: string;
    promptCodeId: string;
}

export interface TargetTypeProperties {
    id: number;
    name: string;
}

export interface TargetProperties extends ProgramTargetProperties {
    id: string;
    clientId: string;
    programId: string;
    dateMastered: string;
    dateOpened: string;
    isReopened: boolean;
    status: TargetTypeProperties;
    type: TargetTypeProperties;
    masteryCriteria: TargetTypeProperties;
}

export interface ClientTargetProperties extends TargetProperties {
    program: {
        id: string;
        name: string;
    };
}

export interface TargetHistoryProperties {
    id: string;
    madeAt: string;
    message: string;
    targetId: string;
}

export interface MoveOptionProperties {
    skillArea: { id: string; name: string };
    program: { id: string; name: string };
}

export interface ActiveTargetProperties {
    id: string;
    name: string;
    type: number;
    status: number;
    steps: Array<UpdateTargetStepsProperties> | null;
    targetInstructions: string;
}

export interface TargetAnnotationsAllProperties
    extends TargetAnnotationProperties {
    id: string;
}

export interface GetClientTargetsProperties {
    clientId: string;
    searchString?: string;
    page?: string;
    pageSize?: string;
}
