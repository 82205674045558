import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "../../../../../redux/store";
import { SelectChangeEvent } from "@mui/material";
import { v4 as randomId } from "uuid";
import { Dayjs } from "dayjs";
import { AddOutlined } from "@mui/icons-material";

import {
  validateDecimalNumber,
  validateNumber,
} from "../../../../../shared/Helpers/functions";
import { CustomInput, CustomSelect } from "../../../../../shared/uiComponents";
import { useCalculateHoursPerWeek } from "../hook";
import { EditAuthorizationProperties } from "./editAuthorizationContent";

const AddAuthorizationContent = ({
  endDateValue,
  startDateValue,
  update,
}: {
  startDateValue: Dayjs;
  endDateValue: Dayjs;
  update: (item: EditAuthorizationProperties) => void;
}) => {
  const sessionTypes = useSelector(
    (state) => state.authorization.insuranceAuthorizationSessionTypes
  );
  const [typeId, setTypeId] = useState<string>("0");
  const [frequency, setFrequency] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<string>("");
  const [customHoursPerWeek, setCustomHoursPerWeek] = useState<string>("");

  const { hoursPerWeek, loading } = useCalculateHoursPerWeek({
    startDateValue,
    endDateValue,
    totalHours,
    frequency,
  });

  useEffect(() => {
    if (!totalHours.length) return;
    setCustomHoursPerWeek(`${hoursPerWeek}`);
  }, [totalHours, hoursPerWeek]);

  const sessionTypeHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTypeId(value);
    const frequencyId = sessionTypes.find((x) => x.id === value)?.frequencies[0]
      .id;
    if (!frequencyId) return;
    setFrequency(frequencyId);
    setTotalHours("0");
  };

  const frequencyHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!validateNumber(value)) return;
    setFrequency(parseInt(value));
  };

  const onTotalHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value)) return;
    setTotalHours(value);
    setCustomHoursPerWeek("");
  };

  const onHoursPerWeekChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value)) return;
    setCustomHoursPerWeek(value);
  };

  const addDetailHandler = () => {
    const data: EditAuthorizationProperties = {
      id: randomId(),
      authorizationSessionTypeId: typeId,
      frequency,
      totalHours: parseFloat(totalHours),
      hoursPerWeek: frequency === 1 ? parseFloat(customHoursPerWeek) : null,
    };
    update(data);
    setCustomHoursPerWeek("");
  };

  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "12px",
        position: "relative",
      }}
      className="padding8 marginBottom8"
    >
      <div style={{ display: "flex", gap: "16px" }} className="marginBottom8">
        <div style={{ minWidth: "350px" }}>
          <CustomSelect
            label="Choose session type:"
            data={sessionTypes}
            value={typeId}
            setValue={sessionTypeHandler}
            className="marginBottom16"
          />
        </div>
        <CustomSelect
          label="Frequency"
          data={
            sessionTypes
              .find((x) => x.id === typeId)
              ?.frequencies.map((x) => ({
                id: `${x.id}`,
                label: x.name,
              })) as Array<any>
          }
          className="width150"
          value={`${frequency}`}
          setValue={frequencyHandler}
        />
      </div>
      <div style={{ display: "flex", gap: "16px" }} className="marginBottom8">
        <CustomInput
          label={`Total hours`}
          value={totalHours}
          setValue={onTotalHoursChange}
        />
        {!loading && (
          <>
            {frequency === 1 && (
              <CustomInput
                label={`Weekly limit`}
                value={customHoursPerWeek}
                setValue={onHoursPerWeekChange}
              />
            )}
            <AddOutlined
              fontSize="large"
              onClick={addDetailHandler}
              className="marginTop24"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AddAuthorizationContent;
