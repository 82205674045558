import { Navigate, Route, Routes } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import "./App.scss";
import { useInitializeTabId } from "../shared/Helpers/hooks";
import Layout from "../components/Menu";
import ReportsLayout from "../pages/Reports";
import Login from "../pages/Login";
import {
  ChangePassword,
  RecoverPassword,
  SetPassword,
} from "../pages/Password";
import ChangeEmail from "../pages/Email";
import {
  Components,
  ProtectedRoutes,
  LoginChecker,
  ReportComponents,
} from "./components";

dayjs.extend(utc);

function App() {
  useInitializeTabId();

  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Layout />}>
            {Components.map(({ Component, path }, index) => (
              <Route path={path} element={<Component />} key={index} />
            ))}
            <Route path="reports/" element={<ReportsLayout />}>
              {ReportComponents.map(({ Component, path }, index) => (
                <Route path={path} element={<Component />} key={index} />
              ))}
            </Route>
          </Route>
        </Route>
        <Route element={<LoginChecker />}>
          <Route path={"login"} element={<Login />} />
          <Route path={"confirm-email"} element={<SetPassword />} />
          <Route path={"recover-password"} element={<RecoverPassword />} />
          <Route path={"change-password"} element={<ChangePassword />} />
          <Route path={"change-email"} element={<ChangeEmail />} />
        </Route>
        <Route path={"*"} element={<Navigate to={"/login"} />} />
      </Routes>
    </div>
  );
}

export default App;
