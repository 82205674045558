import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  Text,
} from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../redux/store";
import { SkillProperties } from "../../../../../redux/API/ClientAPIHelpers/skillAreaProperties";
import {
  copySkillArea,
  getSkillAreas,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";

const Copy = ({ item }: { item: SkillProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [open, setOpen] = useState<boolean>(false);

  const onCopyClick = () => {
    setOpen(true);
  };

  const refreshSkillAreas = () => {
    const { clientId } = item;
    const includeArchived =
      params.get("includeArchived") === "true" ? true : false;

    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  };

  const copySkillAreaHandler = () => {
    const { clientId, id } = item;

    if (!clientId || !id) return;

    dispatch(copySkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        setOpen(false);
      })
      .catch(errorNormalizer);
  };
  return (
    <>
      <Text title="Copy" onClick={onCopyClick} />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title={"Do you want to copy?"}
        Content={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                title="Yes"
                className="marginRight8"
                onClick={copySkillAreaHandler}
              />
              <CustomButton
                title="No"
                secondaryButton
                onClick={() => setOpen(false)}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default Copy;
