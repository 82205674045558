import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { CustomCheckBox, CustomTable } from "../../../../shared/uiComponents";
import { HeaderProperties } from "../../../../shared/uiComponents/Table/tableProperties";
import { blue400 } from "../../../../shared/Helpers/colors";
import { SkillAreaMenu } from "../SkillAcquisition/SkillAreas";
import TableBody from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getMasteredTargets } from "../../../../redux/State/clientSlice/programTargetSlice";
import { getSkillAreas } from "../../../../redux/State/clientSlice/skillAreaSlice";

const Headers: HeaderProperties[] = [
  { id: "1", name: "Target Name" },
  { id: "2", name: "Date Opened" },
  { id: "3", name: "Date Mastered" },
  { id: "4", name: "Status" },
  { id: "5", name: "Target Type" },
  { id: "6", name: "Action" },
];

const MasteredTargets = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();

  const includeArchived =
    params.get("includeArchived") === "true" ? true : false;

  const targets = useSelector((state) => state.programTarget.masteredTargets);
  const skillAreas = useSelector((state) => state.skillArea.skillAreas);
  const skillAreaId = useSelector((state) => state.skillArea.skillAreaId);

  useEffect(() => {
    if (!skillAreaId) return;
    dispatch(getMasteredTargets(skillAreaId));
  }, [skillAreaId, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  }, [clientId, includeArchived, dispatch]);

  const showArchivedSkillAreasHandler = () => {
    params.set("includeArchived", `${!includeArchived}`);
    setParams(params);
  };

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {!!skillAreas &&
          skillAreas.map((item, index) => (
            <SkillAreaMenu item={item} key={index} />
          ))}
        <CustomCheckBox
          onChange={showArchivedSkillAreasHandler}
          item={{
            id: "0",
            checked: includeArchived,
            label: `Show Archived Skill Areas`,
          }}
        />
      </div>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={{ query: targets }}
        loading={false}
        hasPagination={false}
      />
    </div>
  );
};

export default MasteredTargets;
