import { AdminTypes } from "../../components/Menu";
import { useSelector } from "../../redux/store";
import AdminSOAPNotes from "./Admin";
import UserSoapNotes from "./User";

const SOAPNotes = () => {
  const role = useSelector((state) => state.account.role);

  if (!!role && role.section.id === AdminTypes.admin)
    return <AdminSOAPNotes />;

  return <UserSoapNotes />;
};

export default SOAPNotes;
