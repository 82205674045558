import { v4 as randomUUID } from "uuid";

export interface MenuBaseProperties {
  id: string;
  title: string;
  url: string;
  permission: string | null;
}
export interface MenuProperties extends MenuBaseProperties {
  subMenu?: Array<MenuBaseProperties>;
}

export const PERMISSIONS = {
  SUPERADMIN: "all",
  CLIENT: {
    READ: "client.read",
    CREATE: "client.create",
    EDIT: "client.edit",
    DELETE: "client.delete",
  },
  USER: {
    READ: "user.read",
    CREATE: "user.create",
    EDIT: "user.edit",
    DELETE: "user.delete",
  },
  SOAPNOTE: {
    READ: "soapnote.read",
    UNLOCK: "admin.soapnote.unlock",
    PDF: "soapnote.pdf",
    CREATE: "soapnote.create",
    EDIT: "soapnote.edit",
  },
  REPORT: {
    UTILIZATION: "admin.report.utilization.download",
    GRAPH: "report.graph.download",
    SOAPNOTE: "admin.report.soapnote.download",
    NONCREDENTIALED: "admin.report.noncredentialed.detailed.download",
    NONCREDENTIALED_TOTAL: "admin.report.noncredentialed.total.download",
    MBH: "admin.report.mbh.detailed.download",
  }
}

const REPORT_MENU = [
  { id: randomUUID(), title: 'MBH', url: '/reports/mbh', permission: PERMISSIONS.REPORT.MBH },
  { id: randomUUID(), title: 'Non Credentialed', url: '/reports/nc', permission: PERMISSIONS.REPORT.NONCREDENTIALED },
  { id: randomUUID(), title: 'Graph', url: '/reports/graph', permission: PERMISSIONS.REPORT.GRAPH },
  { id: randomUUID(), title: 'SOAP Notes', url: '/reports/soap', permission: PERMISSIONS.REPORT.SOAPNOTE },
  { id: randomUUID(), title: 'Utilization', url: '/reports/utilization', permission: PERMISSIONS.REPORT.UTILIZATION },
  { id: randomUUID(), title: 'Pending Dsu', url: '/reports/pending-dsu', permission: null },
  { id: randomUUID(), title: 'Expired Sessions', url: '/reports/expired-sessions', permission: null },
  { id: randomUUID(), title: 'Assessment', url: '/reports/assessment', permission: null },
  { id: randomUUID(), title: 'Limited Permit', url: '/reports/limited-permit', permission: null },
  { id: randomUUID(), title: 'Backdated Sessions', url: '/reports/backdated-sessions', permission: null },
];

const ADMIN: Array<MenuProperties> = [
  { id: randomUUID(), title: "Dashboard", url: "/dashboard", permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: "Clients", url: "/clients", permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: "All Users", url: "/user", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "Admin", url: "/admin", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "BCBA", url: "/bcba", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "Behavior Technician", url: "/bt", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "SOAP Notes", url: "/soap-notes", permission: PERMISSIONS.SOAPNOTE.READ },
  { id: randomUUID(), title: "Permissions", url: "/permissions", permission: PERMISSIONS.SUPERADMIN },
  {
    id: randomUUID(), title: "Reports", url: "/reports", permission: 'report', subMenu: REPORT_MENU
  },
];

const BCBA: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/clients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
  {
    id: randomUUID(), title: "Reports", url: "/reports", permission: 'report', subMenu: [
      { id: randomUUID(), title: 'Graph', url: '/reports/graph', permission: PERMISSIONS.REPORT.GRAPH },
      { id: randomUUID(), title: 'Assessment', url: '/reports/assessment', permission: null },
    ]
  },
]

const BT: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/clients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
]

export const MENU = {
  ADMIN,
  BCBA,
  BT,
  REPORT_MENU,
}
