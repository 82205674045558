import { useState, ChangeEvent, FormEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { EditOutlined as Edit } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";

import {
  CustomAlert,
  CustomButton,
  CustomInput,
} from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { UpdateClientBehaviorsProperties } from "../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getBehaviors,
  updateBehavior,
} from "../../../../../redux/State/clientSlice/behaviorReductionSlice";

const EditBehaviorDetails = ({
  data,
}: {
  data: UpdateClientBehaviorsProperties;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Edit">
        <Edit onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Edit Behavior Information"
        open={open}
        onClose={closeHandler}
        Content={() => <EditContent data={data} onClose={closeHandler} />}
      />
    </>
  );
};

const EditContent = ({
  data,
  onClose,
}: {
  data: UpdateClientBehaviorsProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const loading = useSelector((state) => state.behaviorReduction.loading);

  const [name, setName] = useState<string>(data.name);
  const [nameError, setNameError] = useState<string>("");
  const [goalName, setGoalName] = useState<string>(data.goalName);
  const [definition, setDefinition] = useState<string>(data.definition);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    setNameError("");
  };

  const onNameBlur = () => {
    if (!!name) return;
    setNameError("Required");
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGoalName(value);
  };

  const onDefinitionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDefinition(value);
  };

  const editBehaviorHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const includeArchived =
      params.get("includeArchivedBehavior") === "true" ? true : false;
    const clientId = data.clientId;
    const editeData: UpdateClientBehaviorsProperties = {
      ...data,
      name,
      goalName,
      definition,
      doNotZeroOut: false,
    };

    dispatch(updateBehavior(editeData))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviors({ clientId, includeArchived }));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <form onSubmit={editBehaviorHandler}>
      <CustomInput
        label="Name"
        value={name}
        setValue={onNameChange}
        className="marginBottom8"
        onBlur={onNameBlur}
        error={!!nameError}
        errorMessage={nameError}
      />
      <CustomInput
        label="Goal Name"
        value={goalName}
        setValue={onGoalNameChange}
        className="marginBottom8"
      />
      <CustomInput
        label="Definition"
        value={definition}
        setValue={onDefinitionChange}
        className="marginBottom8"
      />
      <CustomButton
        title="Save"
        type="submit"
        disabled={!!nameError}
        loading={loading}
      />
    </form>
  );
};

export default EditBehaviorDetails;
