import { useNavigate } from "react-router-dom";
import { ArrowBackIosNewOutlined as Back } from "@mui/icons-material";

import { Text } from "../../shared/uiComponents";
import { useScreenWidth } from "../../shared/Helpers/hooks";

const Previous = () => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  return screenWidth > 768 ? (
    <Text
      title="Previous"
      className="breadcrampLink"
      size="smallBold"
      onClick={() => navigate(-1)}
    />
  ) : (
    <Back onClick={() => navigate(-1)} />
  );
};

export default Previous;
