import { useRef, MutableRefObject } from "react";
import jsPDF from "jspdf";
import dayjs from "dayjs";

import { CustomButton } from "../../../../../../../../shared/uiComponents";
import "./downloadPdfAnnotations.scss";
import BodyPdfAnnotations from "./bodyPdfAnnotations";

const PdfAnnotations = ({
  annotationsList,
  clientName,
  name,
  title1,
  title2,
}: {
  annotationsList: any;
  clientName: string;
  name: string;
  title1: string;
  title2: string;
}) => {
  const reportTemplateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const today = new Date();
  const todayDayjs = dayjs(today);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      callback: (doc) =>
        doc.save(
          `${todayDayjs.format("hh_mm_MM/DD/YYYY")}-${clientName}-${name}`
        ),
    });
  };

  return (
    <>
      <CustomButton title={"Generate PDF"} onClick={handleGeneratePdf} />
      <BodyPdfAnnotations
        reportTemplateRef={reportTemplateRef}
        title1={title1}
        clientName={clientName}
        title2={title2}
        name={name}
        annotationsList={annotationsList}
      />
    </>
  );
};

export default PdfAnnotations;
