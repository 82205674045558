import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { Collapse, TableCell, TableRow, Tooltip } from "@mui/material";

import {
  Text,
  CustomButton,
  CustomAlert,
} from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { blue400 } from "../../../../../shared/Helpers/colors";
import { AdminTypes } from "../../../../../components/Menu";
import { InfoRow } from "../../../../../components/StyledComponents";
import Actions from "./Actions";
import { UpdateTargetContent } from "./Actions/EditTarget";
import GraphsTarget from "./Actions/TargetGraph/graphsTarget";
import TargetHistory from "./TargetInfo/targetHistory";
import { RowStateProperties, TargetHeader } from "./tableInfo";
import TargetInfo from "./TargetInfo";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getTargets,
  openTarget,
} from "../../../../../redux/State/clientSlice/programTargetSlice";
import { TargetProperties } from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Row = ({
  item,
  rowStates,
  setRowStates,
}: {
  item: TargetProperties;
  rowStates: Array<RowStateProperties>;
  setRowStates: Dispatch<SetStateAction<Array<RowStateProperties>>>;
}) => {
  const {
    id,
    name,
    sd,
    status,
    responsePercentage,
    dateOpened,
    dateMastered,
    type,
    programId,
    masteryCriteria,
    consecutiveDays,
  } = item;
  const [openRowActions, setOpenRowActions] = useState<boolean>(false);
  const [openSure, setOpenSure] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [isBT, setIsBT] = useState<boolean>(false);

  const loading = useSelector((state) => state.programTarget.loading);
  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    if (!role) return;
    setIsBT(role.section.id === AdminTypes.bt);
  }, [role]);

  const dispatch = useDispatch<DispatchProperties>();

  useEffect(() => {
    const open = rowStates.find((row) => row.id === id)?.open ?? false;

    setOpenRowActions(open);
  }, [rowStates, id]);

  const openTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(openTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenSure(false);
        toast("Opened");
      })
      .catch((error) => {
        errorNormalizer(error);
        setEditOpen(true);
      });
  };

  const openActionsHandler = () => {
    setRowStates((prev) =>
      prev.map((x) => {
        if (x.id === id) {
          return { ...x, open: !x.open };
        }
        return { ...x, open: false };
      })
    );
  };

  return (
    <>
      <TableRow key={id}>
        <Tooltip arrow title={!!sd ? sd : "No SD"} placement="top-start">
          <TableCell>
            <Text title={name} />
            <Text
              title={`SD: ${!!sd ? sd.substring(0, 60) : "No SD"}`}
              size="tiny"
            />
            <GraphsTarget
              targetId={id}
              targetName={name}
              type={type.id}
              isIcon={true}
            />
          </TableCell>
        </Tooltip>
        <TableCell>
          <Text title={status.name} />
        </TableCell>
        <TableCell>
          {responsePercentage ? (
            <Text title={`${responsePercentage}%`} />
          ) : (
            <Text title={"-"} />
          )}
        </TableCell>
        {!isBT && (
          <TableCell>
            {!!dateOpened ? (
              <Text title={dayjs(dateOpened).format("MM/DD/YYYY")} />
            ) : !openSure ? (
              <CustomButton
                title="Open Target"
                onClick={() => setOpenSure(true)}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CustomButton
                  title="Open"
                  onClick={openTargetHandler}
                  loading={loading}
                  disabled={loading}
                />
                <CustomButton
                  title="Cancel"
                  secondaryButton
                  onClick={() => setOpenSure(false)}
                />
              </div>
            )}
          </TableCell>
        )}
        {isBT && (
          <TableCell>
            {!!dateOpened ? (
              <Text title={dayjs(dateOpened).format("MM/DD/YYYY")} />
            ) : (
              <Text title="-" />
            )}
          </TableCell>
        )}
        <TableCell>
          <Text
            title={
              !!dateMastered ? dayjs(dateMastered).format("MM/DD/YYYY") : "-"
            }
          />
        </TableCell>
        <TableCell>
          <Text title={type.name} />
        </TableCell>
        <TableCell>
          {!!masteryCriteria ? <Text title={masteryCriteria.name} /> : ""}
        </TableCell>
        <TableCell>
          {!!consecutiveDays ? (
            <Text title={consecutiveDays} />
          ) : (
            <Text title={"-"} />
          )}
        </TableCell>
        <TableCell>
          <Text
            title={openRowActions ? "Close" : "Open"}
            textColor={blue400}
            size="smallBold"
            onClick={openActionsHandler}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: "10px 0" }} colSpan={TargetHeader.length}>
          <Collapse in={openRowActions} timeout={"auto"} unmountOnExit>
            {!isBT && <Actions item={item} />}
            {!isBT && (
              <TargetInfo
                item={item}
                id={id}
                programId={programId}
                open={openRowActions}
              />
            )}
            {isBT && <BTRow item={item} />}
          </Collapse>
        </TableCell>
      </TableRow>
      <CustomAlert
        open={editOpen}
        onClose={() => setEditOpen(false)}
        title="Please, fill required fields."
        Content={() => (
          <UpdateTargetContent
            props={item}
            id={id}
            programId={programId}
            closeHandler={() => setEditOpen(false)}
          />
        )}
      />
    </>
  );
};

export const BTRow = ({ item }: { item: TargetProperties }) => {
  const { targetInstructions, id, programId, name, goalName, sd } = item;

  return (
    <div className="targetInfo">
      <div
        style={{
          padding: "10px",
        }}
      >
        <div>
          <Text
            title="Target Details"
            className="title marginBottom8"
            size="mediumBold"
          />
          <InfoRow>
            <Text title="Target Name:" size="smallBold" />
            <Text title={name} />
          </InfoRow>
          <InfoRow>
            <Text title="Goal:" size="smallBold" />
            <Text title={goalName} />
          </InfoRow>
          <InfoRow>
            <Text title="SD:" size="smallBold" />
            <Text title={sd} />
          </InfoRow>
          <div>
            <Text title="Instructions:" size="smallBold" />
            <Text title={targetInstructions} />
          </div>
        </div>
      </div>
      <TargetHistory id={id} programId={programId} />
    </div>
  );
};

export default Row;
