import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import {
  Text,
  CustomInput,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../redux/store";
import {
  getTargets,
  updateTarget,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";
import {
  CreateProgramTargetProperties,
  TargetProperties,
} from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const TargetInstructions = ({
  data,
  id,
}: {
  data: TargetProperties;
  id: string;
}) => {
  const { targetInstructions } = data;
  const { programId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const [instructions, setInstructions] = useState<string>("");

  useEffect(() => {
    if (!targetInstructions) return;

    setInstructions(targetInstructions);
  }, [targetInstructions]);

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInstructions(value);
  };

  const updateTagetHandler = async () => {
    if (!programId || !id) return;

    const newData: CreateProgramTargetProperties = {
      ...data,
      targetInstructions: instructions,
      typeId: data.type.id,
      masteryCriteria: data.masteryCriteria.id,
    };

    dispatch(updateTarget({ data: newData, programId, id }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        toast("Updated");
      })
      .catch(errorNormalizer);
  };

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <Text
        title="Target Instructions"
        size="mediumBold"
        className="marginBottom8"
      />
      <CustomInput
        value={instructions}
        setValue={onTargetInstructionsChange}
        multiline={{ multiline: true, rowCount: 4 }}
        className="marginBottom16"
      />
      <CustomButton title="Save" onClick={updateTagetHandler} />
    </div>
  );
};

export default TargetInstructions;
