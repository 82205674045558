import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CustomAlert, CustomButton } from "../../../../../shared/uiComponents";
import AllocationDetails from "./allocationDetails";

const Allocate = ({ therapistId }: { therapistId: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [params] = useSearchParams();
  return (
    <>
      <CustomButton
        title="Allocate hours"
        onClick={() => setOpen(true)}
        disabled={!therapistId || !params.get("authId") || !params.get("detailsId")}
      />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={({ onClose }) => (
          <AllocationDetails therapistId={therapistId} onClose={onClose} />
        )}
      />
    </>
  );
};

export default Allocate;
