import { Container } from "../../pages/SOAPNotes/Admin/helpers";
import { Loader } from "../StyledComponents";
import AuthorizationInfo from "./authorizationsInfo";
import Illuminate from "../../shared/Assets/logos/IlluminateLogoMain.png";

import { useSelector } from "../../redux/store";
import { IMG } from "../Menu/styledComponents";
import { Text } from "../../shared/uiComponents";
import { AdminTypes } from "../Menu";

const Dashboard = () => {
  const loadingAccount = useSelector((state) => state.account.loading);
  const role = useSelector((state) => state.account.role);

  return (
    <Container style={{ textAlign: "center" }}>
      <Text title={"Welcome"} size="largeBold" className="marginTop16" />
      <IMG src={Illuminate} style={{ width: "40%" }} />
      {AdminTypes.bcba === role?.section.id && <AuthorizationInfo />}
      {loadingAccount && <Loader />}
    </Container>
  );
};

export default Dashboard;
