import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

import { CustomAlert, Text } from "../../shared/uiComponents";

import { DashboardAuthorizationProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const AuthorizationDetailedInfo = ({
  info,
}: {
  info: DashboardAuthorizationProperties;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <InfoOutlined onClick={() => setOpen(true)} />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"md"}
        Content={() => <AuthorizationMoreInfoContent info={info} />}
      />
    </>
  );
};

const AuthorizationMoreInfoContent = ({
  info,
}: {
  info: DashboardAuthorizationProperties;
}) => {
  return (
    <div style={{ width: "100%" }}>
      <TableContainer>
        <Table style={{ minWidth: "600px", width: "100%" }}>
          <TableHead className={"tableHeader"}>
            <TableCell style={{ minWidth: "150px" }}>
              <Text title={"Client"} />
            </TableCell>
            <TableCell>
              <TableRow>
                <TableCell style={{ width: "150px", borderWidth: "0" }}>
                  <Text title={"Authorization Period"} />
                </TableCell>
                <TableCell style={{ width: "200px", borderWidth: "0" }}>
                  <Text title={"CPT Code"} />
                </TableCell>
                <TableCell style={{ width: "200px", borderWidth: "0" }}>
                  <Text title={"Frequency Type"} />
                </TableCell>
                <TableCell style={{ width: "150px", borderWidth: "0" }}>
                  <Text title={"Allocated/Used/Remaining"} />
                </TableCell>
              </TableRow>
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ minWidth: "150px" }}>
                <Link to={`/clients/client/${info.client.id}`} target="_blank">
                  <Text title={info.client.fullName} />
                </Link>
              </TableCell>
              <TableCell colSpan={3}>
                {info.authorizations.map((item) => (
                  <TableRow style={{ borderBottom: "1px solid black" }}>
                    <TableCell style={{ width: "150px", borderWidth: 0 }}>
                      <Text
                        title={`${dayjs(item.authorizationStartDate).format(
                          "MM-DD-YYYY"
                        )} - ${dayjs(item.authorizationEndDate).format(
                          "MM-DD-YYYY"
                        )}`}
                      />
                    </TableCell>
                    <TableCell
                      style={{ width: "200px", borderWidth: 0 }}
                      padding="none"
                    >
                      {info.authorizations.map((item) =>
                        item.authorizationDetails.map((x) => (
                          <Text
                            title={x.sessionType.name}
                            style={{ height: "40px" }}
                          />
                        ))
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "200px",
                        borderWidth: 0,
                        paddingRight: "16px",
                      }}
                    >
                      {info.authorizations.map((item) =>
                        item.authorizationDetails.map((x) => (
                          <Text
                            title={x.frequency.name}
                            style={{ height: "40px" }}
                          />
                        ))
                      )}
                    </TableCell>
                    <TableCell style={{ width: "150px", borderWidth: 0 }}>
                      {info.authorizations.map((item) =>
                        item.authorizationDetails.map((x) => (
                          <Text
                            title={`${x.hoursAllocated}/${x.hoursUsed}/${x.hoursRemaining}`}
                            style={{ height: "40px" }}
                          />
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AuthorizationDetailedInfo;
