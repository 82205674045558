import { FetchAPI } from "../Main/fetchAPI";
import {
    ArchiveClientBehaviorsProperties,
    DeleteClientBehaviorsProperties,
    SaveClientBehaviorsProperties,
    UpdateClientBehaviorsProperties
} from "./behaviorReductionProperties";
import { BEHAVIORS, CLIENT_BEHAVIORS } from "../constants";

export const BehaviorReduction = {
    behaviors: {
        getTypes: () =>
            FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/types`,
            }),
        getCategories: () =>
            FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/categories`,
            }),
    },
    clients: {
        getClientBehaviors: ({ clientId, includeArchived }: { clientId: string, includeArchived: boolean }) =>
            FetchAPI.getJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}?includeArchived=${includeArchived}`,
            }),
        saveClientBehaviors: ({
            clientId,
            ...data
        }: SaveClientBehaviorsProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}`,
                data,
            }),
        updateClientBehaviors: ({
            clientId,
            id,
            ...data
        }: UpdateClientBehaviorsProperties) =>
            FetchAPI.putJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}/${id}`,
                data,
            }),
        deleteClientBehaviors: ({
            clientId,
            id,
        }: DeleteClientBehaviorsProperties) =>
            FetchAPI.deleteJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}/${id}`,
            }),
        archiveClientBehaviors: ({
            clientId,
            id,
        }: ArchiveClientBehaviorsProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}/${id}/archive`,
            }),
        unArchiveClientBehaviors: ({
            clientId,
            id,
        }: ArchiveClientBehaviorsProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENT_BEHAVIORS(clientId)}/${id}/un-archive`,
            }),
    },
}