import { useState } from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import {
  CustomButton,
  CustomAlert,
} from "../../../../../../../../shared/uiComponents";
import Delete from "../../../../../../../../components/Delete";
import PdfAnnotations from "./pdfAnnotations";
import { errorNormalizer } from "../../../../../../../../shared/Helpers/functions";

import {
  DispatchProperties,
  useSelector,
} from "../../../../../../../../redux/store";
import {
  getTargetAllAnnotations,
  deleteAnnotation,
} from "../../../../../../../../redux/State/clientSlice/graphAnnotationSlice";

const ViewAnnotationsPopup = ({ targetId, targetName }) => {
  const [viewAnnotations, setViewAnnotations] = useState<boolean>(false);
  const dispatch = useDispatch<DispatchProperties>();
  const annotationsList = useSelector(
    (state) => state.graphAnnotation.targetAnnotations
  );
  const clientInfo = useSelector((state) => state.client.client);

  const handlerDeleteAnnotation = (id: string) => {
    if (!id) return;
    dispatch(deleteAnnotation(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargetAllAnnotations(targetId));
        toast("Deleted");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomButton
        title="View Annotations"
        onClick={() => setViewAnnotations(true)}
      />
      <CustomAlert
        title="View Annotations"
        onClose={() => setViewAnnotations(false)}
        open={viewAnnotations}
        Content={() => (
          <div className="container-view">
            {annotationsList &&
              annotationsList.map((item) => (
                <div className="info-annotation" key={item.id}>
                  <p>{item.note}</p>
                  <p>{dayjs(new Date(item.date)).format("MM-DD-YYYY")}</p>
                  <Delete
                    deleteHandler={() => handlerDeleteAnnotation(item.id)}
                  />
                </div>
              ))}
            <div className="btns-popup">
              <CustomButton
                title={"Close"}
                secondaryButton
                onClick={() => setViewAnnotations(false)}
              />
              <PdfAnnotations
                annotationsList={annotationsList}
                clientName={clientInfo.fullName}
                name={targetName}
                title1="Client Name"
                title2="Target Name"
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default ViewAnnotationsPopup;
