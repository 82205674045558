import { FC, useState } from "react";
import { TableBody as Body, TableCell, TableRow, Tooltip } from "@mui/material";
import {
  CheckOutlined,
  CloseOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";

import {
  CustomAlert,
  TableNoData,
  Text,
} from "../../../../shared/uiComponents";
import { InfoRow } from "../../../../components/StyledComponents";

import { TargetProperties } from "../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

interface RowRendererProperties {
  data: Array<TargetProperties>;
}

const TableBody: FC<RowRendererProperties> = ({ data }) => {
  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row) => (
        <TableRow key={row.id} style={{ cursor: "pointer" }}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            {dayjs(row.dateOpened).utc(false).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell>
            {dayjs(row.dateMastered).utc(false).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell>{row.status.name}</TableCell>
          <TableCell>{row.type.name}</TableCell>
          <TableCell>
            <Action item={row} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};

const Action = ({ item }: { item: TargetProperties }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return (
    <>
      <Tooltip title="More information">
        <InfoOutlined onClick={onOpen} />
      </Tooltip>
      <CustomAlert
        title="Detailed Information"
        open={open}
        onClose={onClose}
        Content={() => {
          const {
            name,
            goalName,
            sd,
            type,
            errorCorrection,
            reinforcement,
            targetInstructions,
            dateMastered,
            responsePercentage,
            consecutiveDays,
            masteryCriteria,
            dateOpened,
            isReopened,
          } = item;
          return (
            <>
              <InfoRow>
                <Text title={"Target Name"} size="smallBold" />
                <Text title={name} />
              </InfoRow>
              <InfoRow>
                <Text title={"Target Instructions"} size="smallBold" />
                <Text title={targetInstructions} />
              </InfoRow>
              <InfoRow>
                <Text title={"Goal Name"} size="smallBold" />
                <Text title={goalName} />
              </InfoRow>
              <InfoRow>
                <Text title={"SD"} size="smallBold" />
                <Text title={sd} />
              </InfoRow>
              <InfoRow>
                <Text title={"Target Type"} size="smallBold" />
                <Text title={type.name} />
              </InfoRow>
              <InfoRow>
                <Text title={"Response Percentage"} size="smallBold" />
                <Text title={!!responsePercentage ? responsePercentage : "-"} />
              </InfoRow>
              <InfoRow>
                <Text title={"Consecutive Days"} size="smallBold" />
                <Text title={!!consecutiveDays ? consecutiveDays : "-"} />
              </InfoRow>
              <InfoRow>
                <Text title={"Error Correction"} size="smallBold" />
                <Text title={errorCorrection} />
              </InfoRow>
              <InfoRow>
                <Text title={"Reinforcement"} size="smallBold" />
                <Text title={reinforcement} />
              </InfoRow>
              <InfoRow>
                <Text title={"Date Opened"} size="smallBold" />
                <Text
                  title={dayjs(dateOpened).utc(false).format("MM/DD/YYYY")}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Reopened"} size="smallBold" />
                <div>
                  {isReopened ? (
                    <CheckOutlined fontSize="small" />
                  ) : (
                    <CloseOutlined fontSize="small" />
                  )}
                </div>
              </InfoRow>
              <InfoRow>
                <Text title={"Date Mastered"} size="smallBold" />
                <Text
                  title={dayjs(dateMastered).utc(false).format("MM/DD/YYYY")}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Mastery Criteria"} size="smallBold" />
                <Text title={masteryCriteria.name} />
              </InfoRow>
            </>
          );
        }}
      />
    </>
  );
};

export default TableBody;
