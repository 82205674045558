import TargetHistory from "./targetHistory";
import TargetInstructions from "./targetInstructions";

import { TargetProperties } from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

interface TargetInfoProperties {
  id: string;
  programId: string;
  item: TargetProperties;
  open: boolean;
}

const TargetInfo = ({ id, programId, item, open }: TargetInfoProperties) => {
  return (
    <div className="targetInfo">
      <TargetInstructions data={item} id={id} />
      {open && <TargetHistory id={id} programId={programId} />}
    </div>
  );
};

export default TargetInfo;
