import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { Text } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { Wrapper } from "../../../components/StyledComponents";
import SessionData from "./sessionData";
import EndSession from "./endSession";
import SessionPrograms from "./sessionPrograms";
import Behaviours from "./ClientBehaviors/behaviours";
import HiddenTargets from "./hiddenTargets";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getPromptCodes } from "../../../redux/State/clientSlice/dataCollectionSlice";
import {
  getAllActiveClientTargets,
  getSessionTargets,
} from "../../../redux/State/clientSlice/sessionSlice";

const Session = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { state } = useLocation();
  const { clientId, sessionId } = useParams();

  const promptsData = useSelector((state) => state.dataCollection.promptCodes);

  useEffect(() => {
    if (!!promptsData.length) return;
    dispatch(getPromptCodes());
  }, [promptsData.length, dispatch]);

  const fetchActiveTargets = useCallback(() => {
    if (!sessionId) return;
    dispatch(getSessionTargets(sessionId));
  }, [sessionId, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getAllActiveClientTargets({ clientId }))
      .then(unwrapResult)
      .then(() => {
        fetchActiveTargets();
      })
      .catch(errorNormalizer);
  }, [clientId, dispatch, fetchActiveTargets]);

  useEffect(() => {
    let start = new Date();
    const interval = setInterval(() => {
      const now = new Date();
      const diff = Math.floor((now.getTime() - start.getTime()) / 60000);
      if (diff > 30) {
        start = new Date(start.setHours(start.getHours() + 1));
        toast("Please remember to have at least three targets and behaviors per hours!");
      }
    }, 900000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Wrapper style={{ maxWidth: "760px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!!state && (
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Text title={"Date: "} size="smallBold" />
                <Text title={dayjs(state.date).format("MM/DD/YYYY")} />
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Text title={"Start time: "} size="smallBold" />
                <Text
                  title={dayjs(state.startTime).utc(false).format("hh:mm A")}
                />
              </div>
            </div>
          )}
          <HiddenTargets />
        </div>
      </Wrapper>
      <SessionData />
      <SessionPrograms />
      <Behaviours />
      <EndSession />
    </>
  );
};

export default Session;
