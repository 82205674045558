import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { CustomAlert, Text } from "../../../../shared/uiComponents";
import { blue400 } from "../../../../shared/Helpers/colors";
import { InfoRow } from "../../../../components/StyledComponents";
import { AdminTypes } from "../../../../components/Menu";
import AdminInfo from "./adminInfo";
import SessionDate from "./sessionDate";
import SessionActions from "./sessionActions";
import SoapNoteStatus from "./soapNoteStatus";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getSessionTypes } from "../../../../redux/State/clientSlice/sessionSlice";
import { clearSkillAreas } from "../../../../redux/State/clientSlice/skillAreaSlice";
import { setInfoEvent } from "../../../../redux/State/clientSlice/calendarSlice";

const InfoEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.info);

  const closeHandler = () => {
    dispatch(setInfoEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      onClose={closeHandler}
      title={`Session Details`}
      Content={() => <InfoContent />}
    />
  );
};

const InfoContent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const data = useSelector((state) => state.calendar.infoEventData);
  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    if (!!sessionTypes.length || !days.length) return;
    dispatch(getSessionTypes());
  }, [dispatch, sessionTypes, days.length]);

  const navigateClientHandler = () => {
    dispatch(clearSkillAreas());
    dispatch(setInfoEvent(false));
  };

  return !!data ? (
    <>
      <InfoRow>
        <Text title={"Client Name"} size={"smallBold"} />
        <div>
          <Link to={`/clients/client/${data.client.id}`} target="_blank">
            <Text
              title={data.client.fullName}
              textColor={blue400}
              onClick={navigateClientHandler}
            />
          </Link>
        </div>
      </InfoRow>
      <InfoRow>
        <Text title={"Session Type"} size={"smallBold"} />
        <Text title={data.sessionType.name} />
      </InfoRow>
      {role?.section.id === AdminTypes.admin && <AdminInfo />}
      <SessionDate />
      <SessionActions />
      {!!data.sessionId && <SoapNoteStatus />}
    </>
  ) : (
    <Text title={"No Information"} center className="padding16" />
  );
};

export default InfoEvent;
