import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material";
import { toast } from "react-toastify";

import { InfoRow as Row } from "../../../../components/StyledComponents";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../shared/Helpers/functions";
import ActivateSession from "../../../Calendar/Actions/activateEvent";
import { CustomButton, Picker, Text } from "../../../../shared/uiComponents";
import { useResetCalendarData } from "../../Helpers/hooks";
import { AdminTypes } from "../../../../components/Menu";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { UpdateSessionProperties } from "../../../../redux/API/ClientAPIHelpers/sessionsProperties";
import { updateSession } from "../../../../redux/State/clientSlice/sessionSlice";

export const SessionInfoButtonsWrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  padding: "8px 0",
  margin: "auto",
  alignItems: "center",
  lineHeight: "24px",
  ">div": {
    wordWrap: "break-word",
    width: "calc(100% - 32px)",
    marginRight: "16px",
  },
  "@media (max-width: 425px)": {
    flexDirection: "column",
    ">div": {
      width: "100%",
      marginBottom: "16px",
      marginRight: 0,
    },
  },
}));

const SessionDate = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();

  const loading = useSelector((state) => state.session.loading);
  const role = useSelector((state) => state.account.role);
  const data = useSelector((state) => state.calendar.infoEventData);

  const [date, setDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  const dateChangeHandler = (value: Dayjs | null) => {
    setDate(value);
  };

  const startTimeChangeHandler = (value: Dayjs | null) => {
    setStartTime(value);
  };

  const endTimeChangeHandler = (value: Dayjs | null) => {
    setEndTime(value);
  };

  const saveHandler = () => {
    if (!data || !date || !endTime || !startTime) return;
    const sessionId = data.sessionId;
    const newDate = dateNormalizer(date);

    let updatedSession: UpdateSessionProperties = {
      date: newDate,
      endTime: endTime.format("YYYY-MM-DDTHH:mm"),
      id: data.id,
      startTime: startTime.format("YYYY-MM-DDTHH:mm"),
    };
    if (!date.isSame(startTime, "date") || !date.isSame(endTime, "date")) {
      updatedSession = {
        id: data.id,
        date: newDate,
        endTime: `${newDate}T${endTime.format("HH:mm")}`,
        startTime: `${newDate}T${startTime.format("HH:mm")}`,
      };
    }

    dispatch(updateSession({ sessionId, data: updatedSession }))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!data) return;
    const date = dayjs(data.date);
    const startTime = dayjs(data.startTime).utc(false);
    const endTime = dayjs(data.endTime).utc(false);
    setDate(date);
    setStartTime(startTime);
    setEndTime(endTime);
  }, [data]);

  return (
    <>
      <Row>
        <Text title={"Date"} size={"smallBold"} />
        <Picker.CustomDate
          value={date}
          onChange={dateChangeHandler}
          label=""
          disabled={
            role?.section.id !== AdminTypes.admin || !data?.sessionId
          }
        />
      </Row>
      <Row>
        <Text title={"Start Time"} size={"smallBold"} />
        <Picker.CustomTime
          value={startTime}
          onChange={startTimeChangeHandler}
          label=""
          disabled={
            role?.section.id !== AdminTypes.admin || !data?.sessionId
          }
        />
      </Row>
      <Row>
        <Text title={"End Time"} size={"smallBold"} />
        <Picker.CustomTime
          value={endTime}
          onChange={endTimeChangeHandler}
          label=""
          disabled={
            role?.section.id !== AdminTypes.admin || !data?.sessionId
          }
        />
      </Row>
      {role?.section.id === AdminTypes.admin && !!data?.sessionId && (
        <>
          <SessionInfoButtonsWrapper>
            <CustomButton
              title="Save Changes"
              onClick={saveHandler}
              className="width150"
              loading={loading}
              secondaryButton
            />
            <ActivateSession />
          </SessionInfoButtonsWrapper>
        </>
      )}
    </>
  );
};

export default SessionDate;
