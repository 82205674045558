import { useDispatch } from "react-redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  CustomButton,
  CustomAlert,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../redux/store";
import {
  getTargets,
  holdTarget,
  openTarget,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";
import { TargetProperties } from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Hold = ({ target }: { target: TargetProperties }) => {
  const { id, programId, status } = target;
  const dispatch = useDispatch<DispatchProperties>();

  const [openHold, setOpenHold] = useState<boolean>(false);
  const [openUnHold, setOpenUnHold] = useState<boolean>(false);

  const holdTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(holdTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenHold(false);
        toast("Successful");
      })
      .catch(errorNormalizer);
  };

  const unHoldTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(openTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenHold(false);
        toast("Successful");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomButton
        title={status.id === 3 ? "Unhold" : "Put on Hold"}
        onClick={
          status.id === 3 ? () => setOpenUnHold(true) : () => setOpenHold(true)
        }
      />
      <CustomAlert
        title="Do you want to put target on hold?"
        onClose={() => setOpenHold(false)}
        open={openHold}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton title="Hold target" onClick={holdTargetHandler} />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenHold(false)}
            />
          </div>
        )}
      />
      <CustomAlert
        title="Do you want to unhold target?"
        onClose={() => setOpenUnHold(false)}
        open={openUnHold}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton title="Unhold target" onClick={unHoldTargetHandler} />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenUnHold(false)}
            />
          </div>
        )}
      />
    </>
  );
};

export default Hold;
