import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";

import { CustomSelect, Text } from "../../shared/uiComponents";
import { AdminTypes } from "../../components/Menu";
import { HeaderWrapper } from "./helpers";

import Assessment from "./Assessment";
import TreatmentPlanning from "./TreatmentPlanning";
import BehaviorTreatment from "./BehaviorTreatment";
import DirectSupervision from "./DirectSupervision";
import BehaviorTreatmentModification from "./BehaviorTreatmentModification";
import ParentTraining from "./ParentTraining";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getSessionInfo } from "../../redux/State/clientSlice/sessionSlice";
import { getSessionTypes } from "../../redux/State/clientSlice/sessionSlice";

const SOAPNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [service, setService] = useState<string>("");

  const role = useSelector((state) => state.account.role);

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const { id, sessionType, client, date } = useSelector(
    (state) => state.session.sessionInfo
  );
  const supervisor = useSelector(
    (state) => state.soapNote.soapNoteData.supervisor
  );

  const isBT = !!role && role.section.id === AdminTypes.bt;

  const selectServiceHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setService(value);
  };

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;

    dispatch(getSessionInfo(sessionId));
  }, [params, dispatch]);

  useEffect(() => {
    if (!id) return;
    setService(`${sessionType.id}`);
  }, [id, sessionType, dispatch]);

  useEffect(() => {
    if (!!sessionTypes.length) return;
    dispatch(getSessionTypes());
  }, [dispatch, sessionTypes]);

  useEffect(() => {
    if (!isBT) return;
    setService("3");
  }, [isBT]);

  return (
    <div
      className="container"
      style={{ flexDirection: "column", maxWidth: "900px" }}
    >
      <HeaderWrapper className="title marginBottom16">
        <Text title="SOAP Note" size="mediumBold" />
        {!!date && (
          <Text
            title={`Date: ${dayjs(date).format("MM/DD/YYYY")}`}
            size="mediumBold"
          />
        )}
        {!!client?.fullName && (
          <Text title={client.fullName} size="smallBold" />
        )}
        {!!supervisor?.id && (
          <Text title={`Supervisor: ${supervisor.fullName}`} size="smallBold" />
        )}
      </HeaderWrapper>
      <CustomSelect
        label="Service:"
        value={service}
        data={sessionTypes}
        setValue={selectServiceHandler}
        disabled={true}
      />
      <div className="marginBottom16" />
      {service === "1" && <Assessment />}
      {service === "2" && <TreatmentPlanning />}
      {service === "3" && <BehaviorTreatment />}
      {service === "4" && <DirectSupervision />}
      {service === "5" && <BehaviorTreatmentModification />}
      {service === "6" && <ParentTraining />}
      {service === "7" && <TreatmentPlanning />}
    </div>
  );
};
export default SOAPNote;
