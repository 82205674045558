import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import {
  CheckboxWrapper,
  AdminContainer as Container,
} from "../../components/StyledComponents";
import { CheckboxDataProperties } from "../../shared/uiComponents/Checkbox";
import { CustomButton, CustomInput, Text } from "../../shared/uiComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";
import Permissions from "./permission";

import { DispatchProperties, useSelector } from "../../redux/store";
import { AddRoleProperties } from "../../redux/API/identityAPIProperties";
import { addRole } from "../../redux/State/identitySlice/roleSlice";

const AddPermission = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.role.loading);

  const [roleName, setRoleName] = useState<string>("");
  const [roles, setRoles] = useState<Array<CheckboxDataProperties>>([]);
  const [section, setSection] = useState<string>("1");

  const setRoleNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRoleName(value);
  };

  const saveRoleHandler = () => {
    const newClaims = roles
      .filter((role) => role.checked)
      .map((role) => role.id);

    const data: AddRoleProperties = {
      claims: newClaims,
      section: parseInt(section),
      name: roleName,
    };
    dispatch(addRole(data))
      .then(unwrapResult)
      .then(() => {
        setRoles([]);
        navigate(-1);
      })
      .catch(errorNormalizer);
  };

  const updateRoles = useCallback(
    (data: Array<CheckboxDataProperties>) => setRoles(data),
    []
  );

  return (
    <Container>
      <Text
        title={"Add Custom Role"}
        size={"largeBold"}
        className={"marginBottom16"}
      />
      <CheckboxWrapper>
        <CustomInput
          label={"Role Name"}
          value={roleName}
          setValue={setRoleNameHandler}
          className={"marginBottom16"}
        />
        <Permissions
          roles={roles}
          updateRoles={updateRoles}
          section={section}
          setSection={setSection}
        />
      </CheckboxWrapper>
      <CustomButton
        title={"Save Role"}
        onClick={saveRoleHandler}
        disabled={!roleName}
        loading={loading}
      />
    </Container>
  );
};

export default AddPermission;
