import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { CustomButton } from "../../../shared/uiComponents";
import SearchSelect from "../../../shared/uiComponents/Dropdown/searchSelect";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown/searchSelect";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  setClient,
  setTherapist,
  clearEvents,
} from "../../../redux/State/clientSlice/calendarSlice";
import { getUsers } from "../../../redux/State/userSlice";
import { getClients } from "../../../redux/State/clientSlice/clientSlice";
import {
  getTherapists,
  getUserClients,
} from "../../../redux/State/clientSlice/userClientSlice";

const AdminTools = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const USER = useCheckPermission(PERMISSIONS.USER.READ);
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const loadingClientList = useSelector((state) => state.client.loading);
  const loadingUserList = useSelector((state) => state.user.loading);
  const clientsData = useSelector((state) => state.client.clients);
  const usersData = useSelector((state) => state.user.users);
  const therapists = useSelector((state) => state.userClient.therapists);
  const choosenUser = useSelector(
    (state) => state.calendar.filterData.therapist
  );
  const choosenClient = useSelector(
    (state) => state.calendar.filterData.client
  );

  const [users, setUsers] = useState<Array<SelectDataProperties>>([]);
  const [clients, setClients] = useState<Array<SelectDataProperties>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageSizeClient, setPageSizeClient] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");
  const [searchStringClient, setSearchStringClient] = useState<string>("");
  const [loadMoreClient, setLoadMoreClient] = useState<boolean>(false);
  const [loadMoreUser, setLoadMoreUser] = useState<boolean>(false);

  const therapistHandler = (value: string) => {
    const user = usersData.query?.find((x) => x.id === value);
    if (!!user) dispatch(setTherapist(user));
    const therapist = therapists?.find((x) => x.id === value);
    if (!!therapist) dispatch(setTherapist(therapist));
    dispatch(clearEvents());
  };

  const clientHandler = (value: string) => {
    if (!clientsData.query) return;
    const client = clientsData.query.find((x) => x.id === value);
    dispatch(setClient(client));
    dispatch(clearEvents());
  };

  const displayDefaultViewHandler = useCallback(() => {
    dispatch(setClient(null));
    dispatch(setTherapist(null));
    dispatch(clearEvents());
    setSearchString("");
    setSearchStringClient("");
  }, [dispatch]);

  useEffect(() => {
    if (!USER.permissionGranted) return;
    const clientId = choosenClient?.id;

    if (!searchString) dispatch(setTherapist(null));

    const timeout = setTimeout(() => {
      if (!clientId) {
        dispatch(getUsers({ page: "1", pageSize: `${pageSize}`, searchString }))
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const userData: Array<SelectDataProperties> = response.query.map(
              (x) => ({
                id: x.id,
                label: `${x.fullName} (${x.role.section.name})`,
              })
            );
            setUsers(userData);
            setLoadMoreUser(
              !!response.totalNumberOfItems && response.totalNumberOfItems > 8
            );
          })
          .catch(errorNormalizer);
        return;
      }
      dispatch(getTherapists(clientId));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pageSize, searchString, choosenClient, dispatch, USER.permissionGranted]);

  useEffect(() => {
    if (!CLIENT.permissionGranted) return;
    if (!searchStringClient) dispatch(setClient(null));

    const timeout = setTimeout(() => {
      const userId = choosenUser?.id;
      if (!!userId) {
        dispatch(
          getUserClients({
            userId,
            page: "1",
            pageSize: `${pageSizeClient}`,
            searchString: searchStringClient,
            insuranceIds: [],
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const data: Array<SelectDataProperties> = response.query.map(
              (x) => ({
                id: x.id,
                label: x.fullName,
              })
            );
            setClients(data);
            setLoadMoreClient(
              !!response.totalNumberOfItems && response.totalNumberOfItems > 8
            );
          })
          .catch(errorNormalizer);
      }

      dispatch(
        getClients({
          page: "1",
          pageSize: `${pageSizeClient}`,
          searchString: searchStringClient,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<SelectDataProperties> = response.query.map((x) => ({
            id: x.id,
            label: x.fullName,
          }));
          setClients(data);
          setLoadMoreClient(
            !!response.totalNumberOfItems && response.totalNumberOfItems > 8
          );
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    searchStringClient,
    dispatch,
    pageSizeClient,
    choosenUser,
    CLIENT.permissionGranted,
  ]);

  useEffect(() => {
    if (!therapists || !therapists.length) return;
    if (!searchString.length) {
      const list = therapists.map((x) => ({ id: x.id, label: x.fullName }));
      setUsers(list);
      setLoadMoreUser(false);
      return;
    }
    const list = therapists
      .filter((x) =>
        x.fullName.toLowerCase().includes(searchString.toLowerCase())
      )
      .map((x) => ({ id: x.id, label: x.fullName }));
    setUsers(list);
  }, [therapists, searchString]);

  useEffect(() => {
    if (!choosenUser) setSearchString("");
    if (!choosenClient) setSearchStringClient("");
  }, [choosenClient, choosenUser]);

  return (
    <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {USER.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={users}
            label="Find Provider:"
            setSearchString={setSearchString}
            searchString={searchString}
            loading={loadingUserList}
            setValue={therapistHandler}
            loadMore={{
              activate: loadMoreUser,
              setSize: setPageSize,
            }}
          />
        </div>
      )}
      {CLIENT.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={clients}
            label="Find Client:"
            setSearchString={setSearchStringClient}
            searchString={searchStringClient}
            loading={loadingClientList}
            setValue={clientHandler}
            loadMore={{
              activate: loadMoreClient,
              setSize: setPageSizeClient,
            }}
          />
        </div>
      )}
      <CustomButton
        title="Reset view"
        className="marginTop24 marginBottom8"
        secondaryButton
        onClick={displayDefaultViewHandler}
      />
    </div>
  );
};
export default AdminTools;
