import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import { clientReducer } from "./clientReducer";
import { initialAddClientState } from "./clientInitialStates";
import ClientForm from "./clientForm";

import { DispatchProperties } from "../../../redux/store";
import { addClient } from "../../../redux/State/clientSlice/clientSlice";

const AddClient = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const [clientInfo, setClientInfo] = useReducer(
    clientReducer,
    initialAddClientState
  );

  const addClientHandler = () => {
    dispatch(addClient(clientInfo))
      .then(unwrapResult)
      .then(() => navigate(-1))
      .catch(errorNormalizer);
  };

  return (
    <ClientForm
      clientInfo={clientInfo}
      setClientInfo={setClientInfo}
      saveClientHandler={addClientHandler}
    />
  );
};

export default AddClient;
