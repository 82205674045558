import { combineReducers } from "@reduxjs/toolkit";
import IdentityReducer from "./State/identitySlice";
import UserReducer from "./State/userSlice";
import ExportReducer from "./State/exportSlice";
import ClientReducer from "./State/clientSlice";
import InsuranceAuthorizationReducer from "./State/clientSlice/insuranceAuthorizationSlice";

const RootReducer = combineReducers({
  client: ClientReducer.client,
  skillArea: ClientReducer.skillArea,
  userClient: ClientReducer.userClient,
  clientProgram: ClientReducer.clientProgram,
  calendar: ClientReducer.calendar,
  programTarget: ClientReducer.programTarget,
  dataCollection: ClientReducer.dataCollaction,
  behaviorReduction: ClientReducer.behaviorReduction,
  graph: ClientReducer.graph,
  graphAnnotation: ClientReducer.graphAnnotation,
  soapNote: ClientReducer.soapNote,
  billing: ClientReducer.billing,
  session: ClientReducer.session,
  excelReport: ClientReducer.excelReport,
  account: IdentityReducer.account,
  role: IdentityReducer.role,
  identityUser: IdentityReducer.user,
  export: ExportReducer,
  user: UserReducer,
  authorization: InsuranceAuthorizationReducer,
})

export default RootReducer;
