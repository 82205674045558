import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CustomButton, Text, CustomTable } from "../../shared/uiComponents";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import SearchBar from "../../components/SearchBar";
import { Container, Header } from "../../components/StyledComponents";
import { AdminTypes } from "../../components/Menu";
import { PERMISSIONS } from "../../App/constants";
import { TableBody, Headers } from "./tableInfo";
import MobileView from "./mobileView";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getBTs } from "../../redux/State/userSlice";

const BT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const { permissionGranted } = useCheckPermission(PERMISSIONS.USER.CREATE);
  const [params] = useSearchParams();

  const usersData = useSelector((state) => state.user.bts);
  const loading = useSelector((state) => state.user.loading);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBTs({ page, pageSize, orderBy, direction, searchString }));
  }, [params, dispatch]);

  const addAdminHandler = () => {
    window.localStorage.setItem("formType", AdminTypes[2]);
    navigate("/bt/new");
  };

  return (
    <Container>
      <Header>
        <Text title={"Behavior Technician"} size="mediumBold" />
        <Header>
          {permissionGranted && (
            <CustomButton
              title={"Add BT"}
              className={"marginRight16"}
              onClick={addAdminHandler}
            />
          )}
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={usersData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...usersData} />
    </Container>
  );
};

export default BT;
