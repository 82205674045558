import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { ArchiveOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { CustomAlert, CustomButton } from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { ButtonWrapper } from "../../../../../components/StyledComponents";

import { DispatchProperties } from "../../../../../redux/store";
import { ArchiveClientBehaviorsProperties } from "../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import {
  archiveBehavior,
  getBehaviors,
} from "../../../../../redux/State/clientSlice/behaviorReductionSlice";

const Archive = ({ data }: { data: ArchiveClientBehaviorsProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Archive">
        <ArchiveOutlined onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Do you want to archive?"
        open={open}
        onClose={closeHandler}
        Content={() => <ArchiveContent data={data} onClose={closeHandler} />}
      />
    </>
  );
};

const ArchiveContent = ({
  data,
  onClose,
}: {
  data: ArchiveClientBehaviorsProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const archiveHandler = () => {
    const includeArchived =
      params.get("includeArchivedBehavior") === "true" ? true : false;
    const clientId = data.clientId;
    dispatch(archiveBehavior(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviors({ clientId, includeArchived }));
      })
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  return (
    <ButtonWrapper>
      <CustomButton title="Yes" onClick={archiveHandler} />
      <CustomButton title="Cancel" secondaryButton onClick={onClose} />
    </ButtonWrapper>
  );
};

export default Archive;
