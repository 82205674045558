import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorProperties } from "../../API/identityAPIProperties";
import { GetExcelReportProperties } from "../../API/ClientAPIHelpers/soapNoteProperties";
import API from "../../API/Main/API";

export const getPendingDSUSoapNotes = createAsyncThunk(
    'soapNote/get/pending-dsu',
    async (data: GetExcelReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.ExcelReport.getPendingDSUSoapNotes(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const getExpiredSessions = createAsyncThunk(
    'soapNote/get/expired-sessions',
    async (data: GetExcelReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.ExcelReport.getExpiredSessions(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const getAssessmentReport = createAsyncThunk(
    'soapNote/get/assessment/basic/report',
    async (data: GetExcelReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.ExcelReport.getAssessmentReport(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

interface StateProperties {
    loading: boolean;
    error: ErrorProperties;
}

const InitialExcelReportState: StateProperties = {
    loading: false,
    error: {
        status: 0,
        title: "",
    },
}

const excelReportSlice = createSlice({
    name: "excelReport",
    initialState: InitialExcelReportState,
    reducers: {},
    extraReducers: (builder) => {
        //getExpiredSessions
        builder.addCase(getExpiredSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getExpiredSessions.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(getExpiredSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getPendingDSUSoapNotes
        builder.addCase(getPendingDSUSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getPendingDSUSoapNotes.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(getPendingDSUSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAssessmentReport
        builder.addCase(getAssessmentReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAssessmentReport.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(getAssessmentReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export default excelReportSlice.reducer;