import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import API from "../../API/Main/API";
import {
    SessionDataProperties,
    SupervisedSessionInfoProperties,
    TargetSessionStatuses,
    UpdateSessionProperties
} from "../../API/ClientAPIHelpers/sessionsProperties";
import {
    GetSessionProperties,
    SessionProperties,
    SessionTypeProperties,
} from "../../API/ClientAPIHelpers/calendarProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";
import { SessionTargetsProperties } from "../../API/ClientAPIHelpers/dataCollectionProperties";
import {
    ClientTargetProperties,
    GetClientTargetsProperties,
    UpdateTargetStepsProperties,
} from "../../API/ClientAPIHelpers/programTargetsProperties";
import { LocationTypes } from "../../API/ClientAPIHelpers/soapNoteProperties";

export interface SupervisedSessionProperties extends GetSessionProperties {
    location: LocationTypes
}

interface ActiveSessionStateProperties {
    loading: boolean;
    sessionTypes: Array<SessionTypeProperties>
    targets: Array<{
        id: string,
        orderId: number,
        targetSessionStatus: TargetSessionStatuses,
        name: string;
        steps: Array<UpdateTargetStepsProperties> | null,
    }>,
    sessionInfo: GetSessionProperties;
    sessionTargets: Array<SessionTargetsProperties>;
    activeClientTargets: Array<ClientTargetProperties>;
    supervisedSessionInfo: Array<SupervisedSessionProperties>;
    supervisorSessionInfo: Array<SupervisedSessionInfoProperties>;
    error: ErrorProperties,
}

const initialSessionState: ActiveSessionStateProperties = {
    loading: false,
    targets: [],
    sessionTypes: [],
    supervisedSessionInfo: [],
    supervisorSessionInfo: [],
    sessionInfo: {
        id: "",
        client: {
            id: "",
            fullName: "",
            dateOfBirthday: "",
            email: "",
            gender: "",
            defaultBcba: {
                id: "",
                fullName: "",
                firstName: "",
                lastName: "",
                email: "",
                role: {
                    id: "",
                    name: "",
                    section: {
                        id: 0,
                        name: ""
                    }
                },
            }
        },
        user: {
            id: "",
            fullName: "",
            firstName: "",
            lastName: "",
            email: "",
            role: {
                id: "",
                name: "",
                section: {
                    id: 0,
                    name: ""
                }
            },
        },
        sessionType: {
            id: 0,
            name: "",
            type: 0,
            allowedRoleSections: [],
        },
        reportStatus: {
            status: 0,
            name: "",
        },
        date: "",
        startTime: "",
        endTime: "",
        isCompleted: false,
        reportId: "",
        sessionId: "",
        title: "",
        type: "",
    },
    sessionTargets: [],
    activeClientTargets: [],
    error: {
        status: 0,
        title: '',
    }
}

export const startEventSession = createAsyncThunk(
    '/calendar/start-session',
    async (eventId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.startEventSession(eventId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SessionProperties;
    }
)

export const getSessionTypes = createAsyncThunk(
    '/sessions',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.getSessionTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSupervisedSessionsInfo = createAsyncThunk(
    '/sessions/get/info/supervised',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.getSupervisedSessionsInfo(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
);

export const getSupervisorSessionsInfo = createAsyncThunk(
    '/sessions/get/info/supervisor',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.getSupervisorSessionsInfo(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
);

export const startSession = createAsyncThunk(
    '/sessions/start',
    async (data: SessionDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.startSession(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SessionProperties;
    }
);

export const endSession = createAsyncThunk(
    '/sessions/end',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.endSession(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SessionProperties;
    }
);

export const getSessionInfo = createAsyncThunk(
    '/sessions/get/info',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.getSessionInfo(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as GetSessionProperties;
    }
);

export const getSessionTargets = createAsyncThunk(
    '/sessions/targets/all',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.DataCollection.sessions.targets.getSessionTargets(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<SessionTargetsProperties>;
    }
)

export const getAllActiveClientTargets = createAsyncThunk(
    '/client/targets/all/active',
    async (data: GetClientTargetsProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.client.getClientTargets(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ClientTargetProperties>;
    }
)

export const activateSession = createAsyncThunk(
    '/sessions/activate',
    async (sessionId: string, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.activateSession(sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
);

export const updateSession = createAsyncThunk(
    '/sessions/update',
    async ({ data, sessionId }: { sessionId: string, data: UpdateSessionProperties }, thunkAPI) => {
        const response = await API.ClientAPI.Sessions.updateSession(sessionId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
);

const SessionSlice = createSlice({
    name: "session",
    initialState: initialSessionState,
    reducers: {
        setTargets(state, action) {
            state.targets = action.payload;
        },
        clearTargets(state) {
            state.targets = initialSessionState.targets;
        },
        setOpenTargetNew(state, action) {
            const id = action.payload;
            const newTargets = state.targets.map(x => {
                if (x.id !== id) return x;
                return { ...x, targetSessionStatus: TargetSessionStatuses.new };
            });
            state.targets = newTargets;
        },
        setActiveTargetHidden(state, action) {
            const id = action.payload;
            const newTargets = state.targets.map(x => {
                if (x.id !== id) return x;
                return { ...x, targetSessionStatus: TargetSessionStatuses.hidden };
            });
            state.targets = newTargets;
        },
        setHiddenTargetActive(state, action) {
            const id = action.payload;
            const newTargets = state.targets.map(x => {
                if (x.id !== id) return x;
                return { ...x, targetSessionStatus: TargetSessionStatuses.active };
            });
            state.targets = newTargets;
        },
        setDate(state, action) {
            const date = !!action.payload ? action.payload.split("T")[0] : "";
            state.sessionInfo.date = date;
        },
        setStartTime(state, action) {
            const date = action.payload.split('T')[0];
            const time = action.payload.split('T')[1];
            const startTime = time.split(time.includes("+") ? '+' : '-')[0];
            state.sessionInfo.startTime = `${date}T${startTime}`;
        },
        setEndTime(state, action) {
            const date = action.payload.split('T')[0];
            const time = action.payload.split('T')[1];
            const endTime = time.split(time.includes("+") ? '+' : '-')[0];
            state.sessionInfo.endTime = `${date}T${endTime}`;
        },
        setSessionInfoReportId(state, action) {
            state.sessionInfo.reportId = action.payload;
        },
        setSessionInfoUser(state, action) {
            state.sessionInfo.user = action.payload;
        },
        clearSessionInfo(state) {
            state.sessionInfo = initialSessionState.sessionInfo
        }
    },
    extraReducers: (builder) => {
        //activateSession
        builder.addCase(activateSession.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(activateSession.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(activateSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //updateSession
        builder.addCase(updateSession.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(updateSession.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updateSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //endSession
        builder.addCase(endSession.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(endSession.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(endSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //get sessions types
        builder.addCase(getSessionTypes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSessionTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.sessionTypes = [{ id: 0, type: 0, name: "Select session type" }, ...action.payload];
        })
        builder.addCase(getSessionTypes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })
        //getSessionInfo
        builder.addCase(getSessionInfo.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(getSessionInfo.fulfilled, (state, action) => {
            state.loading = false;
            const startTime = action.payload.startTime.split('+')[0];
            const endTime = action.payload.endTime.split('+')[0];
            state.sessionInfo = {
                ...action.payload,
                startTime,
                endTime,
            };
        });
        builder.addCase(getSessionInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //getSupervisedSessionsInfo
        builder.addCase(getSupervisedSessionsInfo.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(getSupervisedSessionsInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.supervisedSessionInfo = action.payload;
        });
        builder.addCase(getSupervisedSessionsInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //getSupervisorSessionsInfo
        builder.addCase(getSupervisorSessionsInfo.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        });
        builder.addCase(getSupervisorSessionsInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.supervisorSessionInfo = action.payload;
        });
        builder.addCase(getSupervisorSessionsInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        });

        //getSessionTargets
        builder.addCase(getSessionTargets.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        })
        builder.addCase(getSessionTargets.fulfilled, (state, action) => {
            state.loading = false;
            const targets = action.payload.map((x, index) => ({
                id: x.target.id,
                orderId: index,
                targetSessionStatus: TargetSessionStatuses.active,
                name: x.target.name,
                steps: x.target.steps,
            }))
            state.sessionTargets = action.payload;
            const activeTargets = state.targets.map((x, index) => {
                const activeTarget = targets.find(y => x.id === y.id);
                if (!!activeTarget) return {
                    ...activeTarget,
                    orderId: state.targets[index].orderId,
                    targetSessionStatus: state.targets[index].targetSessionStatus === TargetSessionStatuses.hidden ?
                        state.targets[index].targetSessionStatus :
                        activeTarget.targetSessionStatus,
                };
                return x;
            })
            state.targets = activeTargets;
        })
        builder.addCase(getSessionTargets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //getAllActiveClientTargets
        builder.addCase(getAllActiveClientTargets.pending, (state) => {
            state.loading = true;
            state.error = initialSessionState.error;
        })
        builder.addCase(getAllActiveClientTargets.fulfilled, (state, action) => {
            state.loading = false;
            const targets = action.payload.map(x => ({
                id: x.id,
                orderId: -1,
                targetSessionStatus: TargetSessionStatuses.new,
                name: x.name,
                steps: null,
            }))
            state.activeClientTargets = action.payload;
            state.targets = targets;
        })
        builder.addCase(getAllActiveClientTargets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const {
    setDate,
    setEndTime,
    setStartTime,
    setSessionInfoReportId,
    setSessionInfoUser,
    setTargets,
    clearTargets,
    setOpenTargetNew,
    setActiveTargetHidden,
    setHiddenTargetActive,
    clearSessionInfo,
} = SessionSlice.actions;

export default SessionSlice.reducer;