import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { DispatchProperties } from "../../../redux/store";
import { getDTTPrompts, getTAPrompts } from "../../../redux/State/clientSlice/dataCollectionSlice";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { DTTPromptsResponseProperties, TAPromptsResponseProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";

export const useFetchTargetHistoryDTT = (targetId: string) => {
    const { sessionId } = useParams();
    const dispatch = useDispatch<DispatchProperties>();
    const [loading, setLoading] = useState<boolean>(false);
    const [promptsDTT, setPromptsDTT] = useState<
        Array<DTTPromptsResponseProperties>
    >([]);

    const fetchHistory = useCallback(() => {
        if (!sessionId || !targetId) return;
        setLoading(true)
        dispatch(getDTTPrompts({ targetId, sessionId }))
            .then(unwrapResult)
            .then((response) => setPromptsDTT(response))
            .catch(errorNormalizer)
            .finally(() => setLoading(false));
    }, [sessionId, targetId, dispatch])

    return { loading, promptsDTT, fetchHistory }
}

export const useFetchTargetHistoryTA = (targetId: string) => {
    const { sessionId } = useParams();
    const dispatch = useDispatch<DispatchProperties>();
    const [loading, setLoading] = useState<boolean>(false);
    const [promptsTA, setPromptsTA] = useState<
        Array<TAPromptsResponseProperties>
    >([]);

    const fetchHistory = useCallback(() => {
        if (!sessionId || !targetId) return;
        setLoading(true);
        dispatch(getTAPrompts({ targetId, sessionId }))
            .then(unwrapResult)
            .then((response) => setPromptsTA(response))
            .catch(errorNormalizer)
            .finally(() => setLoading(false));
    }, [sessionId, targetId, dispatch])

    return { loading, promptsTA, fetchHistory }
}