import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  Picker,
  Text,
  CustomButton,
} from "../../../shared/uiComponents";
import {
  errorNormalizer,
  dateNormalizer,
} from "../../../shared/Helpers/functions";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { DayProperties } from "../Helpers/interfaces";
import { useResetCalendarData } from "../Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { setCopyEvent } from "../../../redux/State/clientSlice/calendarSlice";
import { copyEvents } from "../../../redux/State/clientSlice/calendarSlice";
import { CopyEventsProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";

const CopyEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.copy);
  const selectedDay = useSelector((state) => state.calendar.selectedDay);

  const closeHandler = () => {
    dispatch(setCopyEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      title={"Copy events"}
      onClose={closeHandler}
      Content={() => <CopyContent day={selectedDay} onClose={closeHandler} />}
      popupHeight="500px"
    />
  );
};

const CopyContent = ({
  onClose,
  day,
}: {
  day: DayProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();

  const [startValue, setStartValue] = useState<Dayjs>(
    dayjs(`${day.month + 1}/${day.day}/${day.year}`)
  );
  const [endValue, setEndValue] = useState<Dayjs>(
    dayjs(`${day.month + 1}/${day.day}/${day.year}`)
  );
  const [copyToValue, setCopyToValue] = useState<Dayjs | null>(null);
  const { hasError, message } = useCompareDates(startValue, endValue);

  const onCopyConfirmClick = () => {
    if (!startValue || !endValue || !copyToValue) return;
    const startDate = dateNormalizer(startValue);
    const endDate = dateNormalizer(endValue);
    const toDate = dateNormalizer(copyToValue);

    const data: CopyEventsProperties = {
      startDate,
      endDate,
      toDate,
    };

    dispatch(copyEvents(data))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Done");
        onClose();
      })
      .catch(errorNormalizer);
  };

  const onStartTimeChange = (value: Dayjs | null) => {
    if (!value) return;
    setStartValue(value);
  };

  const onEndTimeChange = (value: Dayjs | null) => {
    if (!value) return;
    setEndValue(value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "space-between",
        }}
        className="marginBottom16"
      >
        <Picker.CustomDate
          label="Start"
          value={startValue}
          onChange={onStartTimeChange}
        />
        <Picker.CustomDate
          label="End"
          value={endValue}
          onChange={onEndTimeChange}
          error={hasError}
          errorMessage={message}
        />
      </div>
      <Picker.CustomDate
        label="Copy to"
        value={copyToValue}
        onChange={(date) => setCopyToValue(date)}
      />
      <Text
        title={`You are going to copy all events between `}
        size="mediumBold"
        className="paddingTop16"
      />
      <Text
        title={`${startValue.format("MM-DD-YYYY")} and ${endValue.format(
          "MM-DD-YYYY"
        )}`}
        size="mediumBold"
      />
      <Text
        title={`to ${
          !!copyToValue ? copyToValue.format("MM-DD-YYYY") : "MM-DD-YYYY"
        }`}
        size="mediumBold"
      />
      <ButtonWrapper>
        <CustomButton
          title="Copy"
          secondaryButton
          onClick={onCopyConfirmClick}
        />
        <CustomButton
          title="Clear"
          onClick={() => {
            setStartValue(dayjs(`${day.month}/${day.day}/${day.year}`));
            setEndValue(dayjs(`${day.month}/${day.day}/${day.year}`));
            setCopyToValue(null);
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default CopyEvent;
