import { useEffect, Dispatch, FC, SetStateAction, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

import { CustomCheckBox, Text, CustomSelect } from "../../shared/uiComponents";
import { CheckboxDataProperties } from "../../shared/uiComponents/Checkbox";
import { AdminContainer as Container } from "../../components/StyledComponents";

import { DispatchProperties, useSelector } from "../../redux/store";
import {
  getClaims,
  getSections,
} from "../../redux/State/identitySlice/roleSlice";

interface PermissionProperties {
  roles: Array<CheckboxDataProperties>;
  updateRoles: (data: Array<CheckboxDataProperties>) => void;
  section: string;
  setSection: Dispatch<SetStateAction<string>>;
}

const Permissions: FC<PermissionProperties> = ({
  roles,
  updateRoles,
  section,
  setSection,
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const allClaims = useSelector((state) => state.role.claims);
  const userClaims = useSelector((state) => state.role.role.claims);
  const sections = useSelector((state) => state.role.sections);
  const isReadonly = useSelector((state) => state.role.role.isReadonly);
  const loading = useSelector((state) => state.role.loading);

  useEffect(() => {
    if (!!allClaims) return;
    dispatch(getClaims());
  }, [allClaims, dispatch]);

  useEffect(() => {
    if (!!sections) return;
    dispatch(getSections());
  }, [sections, dispatch]);

  useEffect(() => {
    if (!allClaims) return;
    const modifiedRoles: CheckboxDataProperties[] = [];
    for (let claim of allClaims) {
      const { claims } = claim;
      for (let claim of claims) {
        const { claimValue, displayName } = claim;
        const item: CheckboxDataProperties = {
          id: claimValue,
          label: displayName,
          checked: userClaims.some((x) => x.claimValue === claimValue),
          disabled: isReadonly,
        };

        modifiedRoles.push(item);
      }
    }

    updateRoles(modifiedRoles);
  }, [allClaims, userClaims, updateRoles, isReadonly]);

  const onSectionChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSection(value);
  };

  const onRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const edited = [...roles].map((x) => {
      if (x.id !== name) return x;
      return {
        id: x.id,
        label: x.label,
        checked: checked,
        disabled: isReadonly,
      };
    });
    updateRoles(edited);
  };

  if (!allClaims || loading) {
    return (
      <Container>
        <div style={{ width: "max-content", margin: "auto" }}>
          <CircularProgress color="inherit" />
        </div>
      </Container>
    );
  }

  return (
    <>
      <CustomSelect
        label={"Section"}
        data={sections}
        value={section}
        setValue={onSectionChange}
        className={"marginBottom16"}
      />
      {allClaims.map((claim, index) => {
        const { claims, displayName } = claim;
        return (
          <div key={index} style={{ display: "flex", flexDirection: "column" }}>
            <Text title={displayName} size={"mediumBold"} />
            {claims.map((claim, index) => {
              const { claimValue } = claim;
              return (
                <CustomCheckBox
                  style={{ marginLeft: "16px" }}
                  item={
                    roles.find((x) => x.id === claimValue) || {
                      id: "1",
                      checked: false,
                      disabled: true,
                    }
                  }
                  key={index}
                  onChange={onRoleChange}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default Permissions;
