import { FC, FocusEventHandler } from "react";
import PhoneInput, {
  isPossiblePhoneNumber,
  Value,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import "./input.scss";

import { red500 } from "../../Helpers/colors";
import Text from "../Text";

interface PhoneInputProperties {
  className?: string;
  label: string;
  setValue: (value: Value) => void;
  value: Value;
  error?: boolean;
  errorMessage?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const CustomPhoneInput: FC<PhoneInputProperties> = ({
  className,
  label,
  setValue,
  value,
  error = false,
  errorMessage = "",
  onBlur,
}) => {
  return (
    <div className={className}>
      <Text
        className={`inputLabel ${!!label ? "active" : ""}`}
        title={label}
        size={"tinyBold"}
      />
      <PhoneInput
        defaultCountry="US"
        className="customPhoneInput"
        onChange={setValue}
        value={value}
        maxLength={!!value && isPossiblePhoneNumber(value) ? value.length : 20}
        onBlur={onBlur}
      />
      <Text
        className={`error ${error ? "active" : ""}`}
        title={errorMessage}
        size={"tiny"}
        textColor={red500}
      />
    </div>
  );
};

export default CustomPhoneInput;
