import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomButton } from "../../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../../shared/Helpers/functions";
import { Loader } from "../../../../../../../components/StyledComponents";
import UpdateStep from "./updateStep";
import AddStep from "./addStep";

import {
  DispatchProperties,
  useSelector,
} from "../../../../../../../redux/store";
import {
  addTargetSteps,
  getTargetSteps,
} from "../../../../../../../redux/State/clientSlice/programTargetSlice";
import {
  GetTargetProperties,
  TargetStepsDataProperties,
  UpdateTargetStepsProperties,
} from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

interface StepsTargetContentProperties extends GetTargetProperties {
  onClose: () => void;
}

const Content = ({ id, programId, onClose }: StepsTargetContentProperties) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [targetSteps, setTargetSteps] = useState<
    Array<TargetStepsDataProperties | UpdateTargetStepsProperties>
  >([]);

  const loading = useSelector(
    (state) => state.programTarget.loadingGetTargetSteps
  );

  useEffect(() => {
    dispatch(getTargetSteps({ id, programId }))
      .then(unwrapResult)
      .then((response) => {
        const steps = response.map((x) => ({ ...x, controlId: x.id }));
        setTargetSteps(steps);
      })
      .catch(errorNormalizer);
  }, [id, programId, dispatch]);

  const addTargetStepHandler = (data: TargetStepsDataProperties) => {
    const newArr = targetSteps.slice();

    if (!newArr.length) {
      newArr.push(data);
    } else {
      newArr.unshift(data);
    }

    setTargetSteps(newArr);
  };

  const updateTargetStepHandler = (data: UpdateTargetStepsProperties) => {
    const newArr = targetSteps.slice().map((x) => {
      if (data.controlId === x.controlId) return data;
      return x;
    });
    setTargetSteps(newArr);
  };

  const saveStepsHandler = () => {
    if (!id || !programId) return;
    dispatch(addTargetSteps({ id, programId, data: targetSteps }))
      .then(unwrapResult)
      .then(() => onClose())
      .catch(({ title, errors }) => {
        if (!!errors) {
          const err: Array<string> = Object.values(errors);
          err.map((error) => toast(error[0]));
          return;
        }
        toast(title);
      });
  };

  return (
    <>
      {!loading ? (
        !!targetSteps &&
        [...targetSteps]
          .sort(
            (
              a: TargetStepsDataProperties | UpdateTargetStepsProperties,
              b: TargetStepsDataProperties | UpdateTargetStepsProperties
            ) => {
              if (a.orderNumber > b.orderNumber) return 1;
              if (a.orderNumber < b.orderNumber) return -1;
              return 0;
            }
          )
          .map((step, index) => (
            <UpdateStep
              key={index}
              data={step as UpdateTargetStepsProperties}
              updateTargetStep={updateTargetStepHandler}
              targetSteps={targetSteps}
            />
          ))
      ) : (
        <Loader />
      )}
      <AddStep
        data={{
          controlId: "",
          orderNumber: targetSteps.length + 1,
          name: "",
          note: "",
          enabled: true,
          useForMastery: true,
        }}
        addTargetStep={addTargetStepHandler}
      />
      <CustomButton
        secondaryButton
        title="Save All"
        onClick={saveStepsHandler}
      />
    </>
  );
};

export default Content;
