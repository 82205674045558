import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { Text } from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../redux/store";
import { SkillProperties } from "../../../../../redux/API/ClientAPIHelpers/skillAreaProperties";
import {
  archiveSkillArea,
  getSkillAreas,
  unArchiveSkillArea,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";

const Archive = ({ item }: { item: SkillProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const refreshSkillAreas = () => {
    const { clientId } = item;
    const includeArchived =
      params.get("includeArchived") === "true" ? true : false;

    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  };

  const onArchiveClick = () => {
    const { clientId, id } = item;
    if (!clientId || !id) return;
    dispatch(archiveSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const onUnarchiveClick = () => {
    const { clientId, id } = item;
    if (!clientId || !id) return;
    dispatch(unArchiveSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        toast("Success");
      })
      .catch(errorNormalizer);
  };
  return (
    <>
      {!item.isArchived ? (
        <Text title="Archive" onClick={onArchiveClick} />
      ) : (
        <Text title="Unarchive" onClick={onUnarchiveClick} />
      )}
    </>
  );
};

export default Archive;
