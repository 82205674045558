import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";

import {
  CustomAlert,
  CustomButton,
  Text,
} from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../redux/store";
import {
  deleteSkillArea,
  getSkillAreas,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";
import { SkillProperties } from "../../../../../redux/API/ClientAPIHelpers/skillAreaProperties";

const Delete = ({ item }: { item: SkillProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [open, setOpen] = useState<boolean>(false);

  const onDeleteClick = () => {
    setOpen(true);
  };

  const refreshSkillAreas = () => {
    const { clientId } = item;
    const includeArchived =
      params.get("includeArchived") === "true" ? true : false;

    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  };

  const deleteSkillAreaHandler = () => {
    const { clientId, id } = item;

    if (!clientId || !id) return;

    dispatch(deleteSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        setOpen(false);
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <Text title="Delete" onClick={onDeleteClick} />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title={"Do you want to delete?"}
        Content={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                title="Yes"
                className="marginRight8"
                onClick={deleteSkillAreaHandler}
              />
              <CustomButton
                title="No"
                secondaryButton
                onClick={() => setOpen(false)}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default Delete;
