import { styled } from "@mui/material/styles";
import { white100 } from "../../../shared/Helpers/colors";

export const Container = styled("div")(() => ({
  backgroundColor: white100,
  width: "100%",
  padding: "24px",
  margin: "auto",
  borderRadius: "12px",
  minWidth: "720px",
  maxWidth: "1440px",
  "@media (max-width: 768px)": {
    minWidth: "220px",
    padding: "8px",
  },
}));

export const Wrapper = styled("div")<{ unlockConfirm: boolean }>((props) => ({
  width: props.unlockConfirm ? "40px" : "20px",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
}));

export const HeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "&>div": {
    width: "50%",
  },
}));

export const FilterWrapper = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
}));