import { Outlet, useLocation } from "react-router-dom";

import { Text } from "../../shared/uiComponents";
import { Container, Header } from "../../components/StyledComponents";
import { MENU } from "../../App/constants";

const Reports = () => {
  const { pathname } = useLocation();
  const reportTitle = MENU.REPORT_MENU.find((x) => x.url === pathname)?.title;
  return (
    <Container>
      <Header>
        <Text
          title={`${!!reportTitle ? reportTitle : ""} Reports`}
          size="mediumBold"
        />
      </Header>
      <Outlet />
    </Container>
  );
};

export default Reports;
