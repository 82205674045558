import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { EditOutlined as Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { errorNormalizer } from "../../shared/Helpers/functions";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import { PERMISSIONS } from "../../App/constants";
import AccountDetails from "./accountDetails";
import { AdminTypes } from "../Menu";
import Delete from "../Delete";

import { DispatchProperties } from "../../redux/store";
import { UserProperties } from "../../redux/API/userAPIProperties";
import { deleteUser } from "../../redux/State/userSlice";

const UserAction = ({
  item,
  fetchUsers,
}: {
  item: UserProperties;
  fetchUsers: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const EDIT = useCheckPermission(PERMISSIONS.USER.EDIT);
  const DELETE = useCheckPermission(PERMISSIONS.USER.DELETE);
  const { pathname } = useLocation();

  const onDeleteClick = (id: string) => {
    dispatch(deleteUser(id))
      .then(unwrapResult)
      .then(() => {
        toast(`Deleted`);
        fetchUsers();
      })
      .catch(errorNormalizer);
  };
  const onEditClick = () => {
    window.localStorage.setItem("formType", AdminTypes[item.role.section.id]);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {EDIT.permissionGranted && (
        <>
          <Link to={`${pathname}/${item.id}`} className={"marginRight16"}>
            <Tooltip title="Edit">
              <Edit fontSize="small" onClick={onEditClick} />
            </Tooltip>
          </Link>
        </>
      )}
      <AccountDetails item={item} />
      {DELETE.permissionGranted && (
        <Delete deleteHandler={() => onDeleteClick(item.id)} />
      )}
    </div>
  );
};

export default UserAction;
