import { Autocomplete, Chip, MenuItem, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import Text from "../Text";
import { white100 } from "../../Helpers/colors";

interface SearchMultipleSelectProperties {
  label: string;
  data: Array<{ id: string; name: string }>;
  values: Array<string>;
  className: string;
  disabled: boolean;
  setValues: (value: Array<string>) => void;
}

const CustomSearchMultipleSelect: FC<SearchMultipleSelectProperties> = ({
  label,
  data,
  values,
  setValues,
  className,
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Array<{ id: string; name: string }>>([]);
  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  const changeHandler = (
    _: any,
    value: Array<{ id: string; name: string }>
  ) => {
    const filteredData = value.filter((z) => !!z.id);
    const ids = filteredData.map((x) => x.id);
    setValues(ids);
    setValue(filteredData);
  };

  useEffect(() => {
    if (!values) return;
    const filteredData = data.filter((x) => values.includes(x.id));
    setValue(filteredData);
  }, [values, data]);

  return (
    <div className={className}>
      <Text
        title={label}
        className={`inputLabel ${!!label ? "active" : ""}`}
        size={"tinyBold"}
      />
      <Autocomplete
        multiple
        disableCloseOnSelect
        open={open}
        onChange={changeHandler}
        onOpen={openHandler}
        onClose={closeHandler}
        value={!!value.length ? value : [{ id: "", name: "N/A" }]}
        options={data}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            sx={{
              background: white100,
            }}
            {...params}
          />
        )}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          if (!option.id) {
            return (
              <MenuItem
                {...props}
                value={option.id}
                onClick={() => setValue([])}
              >
                {option.name}
              </MenuItem>
            );
          }
          return (
            <MenuItem {...props} value={option.id}>
              {option.name}
            </MenuItem>
          );
        }}
        renderTags={(options) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {options.map((value) => (
              <Chip
                key={value.id}
                label={value.name}
                className={"marginBottom8"}
                style={{ width: "max-content" }}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default CustomSearchMultipleSelect;
