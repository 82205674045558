import { FC, useEffect, useState } from "react";
import { TableBody as Body } from "@mui/material";

import { TableNoData } from "../../../../../shared/uiComponents";
import { HeaderProperties } from "../../../../../shared/uiComponents/Table/tableProperties";
import Row from "./targetRow";

import { TargetProperties } from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

interface RowRendererProperties {
  data: Array<TargetProperties>;
}

export interface RowStateProperties {
  id: string;
  open: boolean;
}

export const TargetHeader: Array<HeaderProperties> = [
  { id: "0", name: "Target Name" },
  { id: "1", name: "Status" },
  { id: "2", name: "Response Rate" },
  { id: "3", name: "Date Opened" },
  { id: "4", name: "Date Mastered" },
  { id: "5", name: "Target Type" },
  { id: "6", name: "Mastery Type" },
  { id: "7", name: "Consecutive Days" },
  { id: "8", name: "Actions" },
];

export const TargetBody: FC<RowRendererProperties> = ({ data }) => {
  const [rowOpenStates, setRowOpenStates] = useState<Array<RowStateProperties>>(
    []
  );

  useEffect(() => {
    if (!data || !data.length) return;

    const states = data.map((item) => ({ id: item.id, open: false }));
    setRowOpenStates(states);
  }, [data]);

  if (!data || !data.length) {
    return <TableNoData spanScope={TargetHeader.length} />;
  }

  return (
    <Body>
      {!!data &&
        data.map((item, index) => (
          <Row
            item={item}
            key={index}
            rowStates={rowOpenStates}
            setRowStates={setRowOpenStates}
          />
        ))}
    </Body>
  );
};
