import { useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";

import { black500 } from "../../../../../../shared/Helpers/colors";
import Text from "../../../../../../shared/uiComponents/Text";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import { getTargetHistory } from "../../../../../../redux/State/clientSlice/programTargetSlice";

const TargetHistory = ({
  id,
  programId,
}: {
  id: string;
  programId: string;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const history = useSelector((state) => state.programTarget.history);
  const loading = useSelector((state) => state.programTarget.loadingHistory);

  useEffect(() => {
    if (!id || !programId) return;
    dispatch(getTargetHistory({ id, programId }));
  }, [id, programId, dispatch]);

  return (
    <div
      style={{
        borderLeft: `2px solid ${black500}`,
        padding: "10px",
      }}
    >
      <Text title="History" size="mediumBold" className="marginBottom8" />
      {!loading &&
        !!history &&
        history.map((item, index) => (
          <div key={index}>
            <Text
              title={`${dayjs(item.madeAt).format("MM/DD/YYYY hh:mm:ss A")}`}
              size="smallBold"
            />
            <Text
              title={item.message}
              size="smallBold"
              className="blueBorderBottom marginBottom8"
            />
          </div>
        ))}
      {loading && (
        <div style={{ width: "max-content", margin: "auto" }}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default TargetHistory;
