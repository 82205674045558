import { ChangeEvent, FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  VisibilityOutlined as ShowPassword,
  VisibilityOffOutlined as HidePassword,
} from "@mui/icons-material";

import { ImageWrapper, WhiteContainer } from "../Login/styledComponents";
import { IMG } from "../../components/Menu/styledComponents";
import { Text, CustomInput, CustomButton } from "../../shared/uiComponents";
import Logo from "../../shared/Assets/logos/IlluminateLogoMain.png";
import { errorNormalizer } from "../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../redux/store";
import { recoverAccountPassword } from "../../redux/State/identitySlice/accountSlice";

export const ChangePasswordContent = () => {
  const loading = useSelector((state) => state.account.loading);

  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { search } = useLocation();

  const token = encodeURIComponent(
    new URLSearchParams(search).get("token") as string
  );
  const userId = new URLSearchParams(search).get("userId") as string;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      recoverAccountPassword({
        userId,
        token,
        password,
        confirmPassword,
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast("Your password is updated");
        navigate("/login");
      })
      .catch(errorNormalizer);
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPasswordError("");
    setPassword(event.target.value);
  };

  const onPasswordConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPasswordError("");
    setConfirmPassword(event.target.value);
  };

  const onConfirmPasswordBlur = () => {
    setConfirmPasswordError(
      password !== confirmPassword ? "Passwords are not matching" : ""
    );
  };

  return (
    <WhiteContainer>
      <Text
        title={"Recover password"}
        size={"largeBold"}
        className={"marginBottom24"}
      />

      <form onSubmit={handleSubmit}>
        <CustomInput
          value={password}
          setValue={onPasswordChange}
          label={"Password"}
          className={"marginBottom16"}
          type={showPassword ? "text" : "password"}
          EndAdornment={{
            Icon: showPassword ? ShowPassword : HidePassword,
            onClickEndAdornment: () => setShowPassword(!showPassword),
          }}
        />
        <CustomInput
          value={confirmPassword}
          setValue={onPasswordConfirmChange}
          label={"Confirm Password"}
          className={"marginBottom16"}
          type={showPassword ? "text" : "password"}
          onBlur={onConfirmPasswordBlur}
          error={!!confirmPasswordError}
          errorMessage={confirmPasswordError}
          EndAdornment={{
            Icon: showPassword ? ShowPassword : HidePassword,
            onClickEndAdornment: () => setShowPassword(!showPassword),
          }}
        />

        <CustomButton
          title={"Recover password"}
          type={"submit"}
          loading={loading}
          disabled={!!confirmPasswordError || !password || !confirmPassword}
        />
      </form>

      <ImageWrapper>
        <IMG src={Logo} alt={"Logo"} />
      </ImageWrapper>
    </WhiteContainer>
  );
};
