import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomButton, CustomAlert } from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import {
  copyTarget,
  getTargets,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";

const Copy = ({ id, programId }: { id: string; programId: string }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector(
    (state) => state.programTarget.loading
  );
  const [openCopy, setOpenCopy] = useState<boolean>(false);

  const copyTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(copyTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenCopy(false);
        toast("Copied");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomButton title="Copy" onClick={() => setOpenCopy(true)} />
      <CustomAlert
        title="Are you sure?"
        onClose={() => setOpenCopy(false)}
        open={openCopy}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              title="Copy target"
              onClick={copyTargetHandler}
              loading={loading}
              disabled={loading}
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenCopy(false)}
            />
          </div>
        )}
      />
    </>
  );
};

export default Copy;
