import { useEffect, useState, useRef, MutableRefObject } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import dayjs from "dayjs";

import CustomSelect from "../../../../../../shared/uiComponents/Dropdown";
import {
  DisplayDataProperties,
  LabelPeriods,
  Periods,
  LabelPeriodsYFrequency,
  ScaleValueProperties,
  ScaleYValueProperties,
  commonOptions,
  intervalData,
  saveCanvas,
  selectTimeList,
  BehaviorAnnotationProperties,
} from "./graphHelpers";
import { LineDisplay, LineDownload } from "./line";
import AnnotationsTarget from "./GraphAnnotations/annotationsTarget";
import { BehaviorGraphNotes } from "./graphNotes";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import { getClient } from "../../../../../../redux/State/clientSlice/clientSlice";
import { getBehaviorReductionAllAnnotations } from "../../../../../../redux/State/clientSlice/graphAnnotationSlice";
import { BehaviorReductionProperties } from "../../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import {
  getBehaviorDurationGraph,
  getBehaviorFrequencyGraph,
} from "../../../../../../redux/State/clientSlice/graphSlice";
import BodyPdfAnnotations from "../../../SkillAcquisition/Targets/Actions/TargetGraph/GraphAnnotations/bodyPdfAnnotations";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  annotationPlugin
);

const GraphBehaviorContent = ({
  data,
  behaviorId,
}: {
  data: BehaviorReductionProperties;
  behaviorId: string;
}) => {
  const today = dayjs();
  const todayString = today.format("MM/DD/YYYY");
  const threeMonthPast = today.subtract(3, "month").format("MM/DD/YYYY");
  const { name, category, recordingType } = data;

  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [selectValue, setSelectValue] = useState<string>("0");
  const [activeInterval, setActiveInterval] = useState<string>("3M");
  const [periodicity, setPeriodicity] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(threeMonthPast);

  const [displayData, setDisplayData] = useState<Array<DisplayDataProperties>>(
    []
  );
  // eslint-disable-next-line
  const [annotationLines, setAnnotationLines] = useState<
    Array<BehaviorAnnotationProperties>
  >([]);
  const clientInfo = useSelector((state) => state.client.client);

  const annotationsList = useSelector(
    (state) => state.graphAnnotation.behaviorAnnotations
  );

  const [scaleX, setScaleX] = useState<any>({
    type: "time",
    time: {
      unit: "day",
      tooltipFormat: "PP",
    },
    title: {
      display: true,
      text: "Date",
    },
  });
  const [scaleY, setScaleY] = useState<any>({
    title: {
      display: true,
      text:
        recordingType.text === "Frequency"
          ? "Daily Frequency"
          : "Duration (min)",
    },
    suggestedMin: 0,
    suggestedMax: 5,
  });

  const handleIntervalChange = (interval: string) => {
    setActiveInterval(interval);
    const date = intervalData.find((x) => x.id === interval);
    if (!date) return;
    setStartDate(date.value);
  };

  const selectValueHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectValue(value);

    const unitValue = parseInt(value);
    setPeriodicity(`${unitValue + 1}`);

    const scaleX: ScaleValueProperties = {
      type: "time",
      time: {
        unit: Periods[unitValue],
      },
      title: {
        display: true,
        text: LabelPeriods[unitValue],
      },
    };
    setScaleX(scaleX);
    if (recordingType.text === "Frequency") {
      const scaleY: ScaleYValueProperties = {
        title: {
          display: true,
          text: LabelPeriodsYFrequency[unitValue],
        },
        suggestedMin: 0,
        suggestedMax: 5,
      };
      setScaleY(scaleY);
    }
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClient(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (
      !commonOptions.plugins ||
      !commonOptions.plugins.title ||
      !recordingType.text ||
      !clientInfo.fullName ||
      !category.name ||
      !name
    )
      return;
    let totalText: string = "";
    if (recordingType.text === "Frequency") {
      totalText = "Summary";
    } else if (recordingType.text === "Duration") {
      totalText = "Total";
    }
    const title = `${clientInfo.fullName} ${name} ${totalText} ${recordingType.text} ${category.name}`;
    commonOptions.plugins.title.text = title;
  }, [recordingType.text, category.name, clientInfo.fullName, name]);

  useEffect(() => {
    if (!behaviorId) return;
    if (recordingType.text === "Frequency") {
      dispatch(
        getBehaviorFrequencyGraph({
          behaviorId,
          startDate,
          endDate: todayString,
          periodicity,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const data = response.map((item) => ({
            x: dayjs(item.date).format("YYYY-MM-DD"),
            y: item.count,
          }));
          setDisplayData(data);
        })
        .catch(errorNormalizer);
    }
    if (recordingType.text === "Duration") {
      dispatch(
        getBehaviorDurationGraph({
          behaviorId,
          startDate,
          endDate: todayString,
          periodicity,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          const data = response.map((item) => ({
            x: dayjs(item.date).format("YYYY-MM-DD"),
            y: item.duration / 60,
          }));
          setDisplayData(data);
        })
        .catch(errorNormalizer);
    }
  }, [
    behaviorId,
    startDate,
    periodicity,
    recordingType,
    todayString,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !commonOptions.plugins ||
      !commonOptions.plugins.annotation ||
      !annotationsList
    )
      return;
    const dataAnnotations = annotationsList
      .map((item) => ({
        id: item.id,
        behaviorId: item.behaviorReductionId,
        note: item.note,
        date: dayjs(new Date(item.date)).format("MM-DD-YYYY"),
      }))
      .filter((item) => new Date(item.date) >= new Date(startDate));
    setAnnotationLines(dataAnnotations);
    const annotationsAll = annotationsList
      .map((annotation) => ({
        type: "line",
        borderColor: "red",
        borderWidth: 2,
        scaleID: "x",
        id: annotation.id,
        value: new Date(annotation.date),
      }))
      .filter((item) => new Date(item.value) >= new Date(startDate));
    commonOptions.plugins.annotation.annotations = annotationsAll;
  }, [annotationsList, startDate]);

  useEffect(() => {
    dispatch(getBehaviorReductionAllAnnotations(behaviorId))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [behaviorId, dispatch]);

  const filteredAnnotations = annotationsList.filter(
    (annotation) => new Date(annotation.date) >= new Date(startDate)
  );

  const reportTemplateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      callback: (doc) =>
        doc.save(
          `${today.format("hh_mm_MM/DD/YYYY")}-${clientInfo.fullName}-${name}`
        ),
    });
  };

  const handleOnclickDownload = () => {
    const fileName = `${today.format("hh_mm_MM/DD/YYYY")}-${
      clientInfo.fullName
    }-${name}`;
    saveCanvas(fileName);
    handleGeneratePdf();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        <AnnotationsTarget behaviorReductionId={behaviorId} name={name} />
        <LineDisplay
          displayData={displayData}
          scaleX={scaleX}
          startDate={startDate}
          scaleY={scaleY}
        />

        <LineDownload
          displayData={displayData}
          scaleX={scaleX}
          startDate={startDate}
          scaleY={scaleY}
        />
        <div className="btnContainer">
          <div className="filterBlock">
            <button
              className="btnFilter"
              onClick={handleOnclickDownload}
              style={{ borderLeft: "none" }}
            >
              Download
            </button>
            {intervalData.map((interval, i) => (
              <div key={i} className="wrapper-filter">
                <div
                  className={`${
                    activeInterval === interval.id
                      ? "activeBtn btnFilter"
                      : "btnFilter"
                  }`}
                  onClick={() => handleIntervalChange(interval.id)}
                >
                  {interval.id}
                </div>
              </div>
            ))}
          </div>
          <CustomSelect
            data={selectTimeList}
            value={selectValue}
            setValue={selectValueHandler}
          />
          <BodyPdfAnnotations
            reportTemplateRef={reportTemplateRef}
            title1="Client Name"
            title2="Target Name"
            clientName={clientInfo.fullName}
            name={name}
            annotationsList={filteredAnnotations}
          />
        </div>
        <BehaviorGraphNotes behaviorId={behaviorId} />
      </div>
    </>
  );
};

export default GraphBehaviorContent;
