import { FC, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";
import { InfoOutlined as Info } from "@mui/icons-material";
import { TableBody as Body, TableCell, TableRow, Tooltip } from "@mui/material";
import Edit from "@mui/icons-material/EditOutlined";

import Delete from "../../components/Delete";
import { CustomAlert, TableNoData, Text } from "../../shared/uiComponents";
import { HeaderProperties } from "../../shared/uiComponents/Table/tableProperties";
import { errorNormalizer } from "../../shared/Helpers/functions";

import { DispatchProperties } from "../../redux/store";
import { ExtendedRoleProperties } from "../../redux/API/identityAPIProperties";
import {
  deleteRole,
  getRoles,
} from "../../redux/State/identitySlice/roleSlice";

interface RowRendererProperties {
  data: ExtendedRoleProperties[] | null;
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Role name" },
  { id: "1", name: "Usage" },
  { id: "2", name: "Created" },
  { id: "3", name: "Last Updated" },
  { id: "4", name: "Actions" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const deleteAdminHandler = (id: string | undefined) => {
    if (!id) return;
    dispatch(deleteRole(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getRoles());
        toast(`Deleted`);
      })
      .catch(errorNormalizer);
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.numberOfUsage}</TableCell>
          <TableCell>{dayjs(row.createdAt).format("MM-DD-YYYY")}</TableCell>
          <TableCell>
            {!!row.modifiedAt
              ? dayjs(row.modifiedAt).format("MM-DD-YYYY")
              : "-"}
          </TableCell>
          <TableCell>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {!row.isReadonly && (
                <>
                  <Link
                    to={`/permissions/${row.id}`}
                    className={"marginRight16"}
                  >
                    <Tooltip title="Edit">
                      <Edit fontSize="small" />
                    </Tooltip>
                  </Link>
                  <Tooltip title="Delete">
                    <Delete deleteHandler={() => deleteAdminHandler(row.id)} />
                  </Tooltip>
                </>
              )}
              <RoleInfo role={row} />
            </div>
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};

export const RoleInfo = ({ role }: { role: ExtendedRoleProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { claims } = role;
  return (
    <>
      <div onClick={() => setOpen(true)} className={"marginLeft16"}>
        <Tooltip title="More Information">
          <Info />
        </Tooltip>
      </div>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title="Accesses:"
        Content={() => (
          <>
            {claims.map((claim, index) => (
              <Text
                key={index}
                title={claim.displayName as string}
                size="smallBold"
              />
            ))}
          </>
        )}
      />
    </>
  );
};
