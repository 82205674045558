import { useState } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CloseOutlined as Close,
  CheckOutlined as Check,
  ContentCopyOutlined as Copy,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  copyProgram,
  getPrograms,
} from "../../../../../redux/State/clientSlice/clientProgramSlice";
import { ProgramProperties } from "../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";

const Wrapper = styled("div")<{ copyConfirm: boolean }>((props) => ({
  width: props.copyConfirm ? "40px" : "20px",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
}));

const CopyProgram = ({ data }: { data: ProgramProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const skillAreaId = useSelector((state) => state.skillArea.skillAreaId);

  const [copyConfirm, setCopyConfirm] = useState<boolean>(false);

  const copyProgramHandler = () => {
    dispatch(copyProgram(data))
      .then(unwrapResult)
      .then(() => {
        if (!skillAreaId) return;
        dispatch(getPrograms({ skillAreaId }));
      });
  };

  return (
    <Wrapper copyConfirm={copyConfirm}>
      <Tooltip title="Copy">
        {copyConfirm ? (
          <>
            <div onClick={() => setCopyConfirm(false)}>
              <Close fontSize="small" />
            </div>
            <div onClick={copyProgramHandler}>
              <Check fontSize="small" />
            </div>
          </>
        ) : (
          <div onClick={() => setCopyConfirm(true)}>
            <Copy fontSize="small" />
          </div>
        )}
      </Tooltip>
    </Wrapper>
  );
};

export default CopyProgram;
