import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RemoveRedEyeOutlined as View } from "@mui/icons-material";

import { InfoRow as Row } from "../../../../components/StyledComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { Text } from "../../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getSoapNotePDF } from "../../../../redux/State/exportSlice";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const AdminInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const loadingExport = useSelector((state) => state.export.loading);
  const data = useSelector((state) => state.calendar.infoEventData);

  const viewSoapNoteHandler = () => {
    if (!data?.reportId) return;
    const reportId = data.reportId;
    dispatch(
      getSoapNotePDF({
        reportId,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      {!!data && (
        <>
          <Row>
            <Text title={"Provider Name"} size={"smallBold"} />
            <Text title={data.user.fullName} />
          </Row>
          <Row>
            <Text title={"Provider Role"} size={"smallBold"} />
            <Text title={data.user.role.section.name} />
          </Row>
          <Row>
            <Text title={"Session Status"} size={"smallBold"} />
            <Text title={data.isCompleted ? "Completed" : "Not Completed"} />
          </Row>
          <Row>
            <Text title={"SOAP Note Status:"} size={"smallBold"} />
            <div style={{ display: "flex", gap: "16px" }}>
              <Text
                title={
                  !!data.reportStatus ? data.reportStatus.name : "Not Created"
                }
              />
              {SoapNotesStatuses.submitted === data.reportStatus?.status &&
                !loadingExport && <View onClick={viewSoapNoteHandler} />}
            </div>
          </Row>
        </>
      )}
    </>
  );
};

export default AdminInfo;
