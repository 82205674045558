import { useEffect, ChangeEvent, Dispatch, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import {
  CustomInput,
  CustomSelect,
  MultipleSelect,
  Text,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { AdminTypes } from "../../../components/Menu";
import { InfoRow } from "../../../components/StyledComponents";
import { SaveBehaviorTreatmentProperties } from "../SoapNoteProperties/behaviorTreatmentTypeProperties";
import { useBehaviorTreatmentOptions } from "../hook";
import {
  SessionBehaviorsInfo,
  SessionTargetsInfo,
  SignatureInput,
  TimeInfo,
} from "../helpers";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  behaviorTreatmentErrorReducer,
  initialBehaviorTreatmentErrorState,
} from "./behaviorTreatmentReducer";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {} from "../../../redux/State/clientSlice/soapNoteSlice";
import { getSupervisorSessionsInfo } from "../../../redux/State/clientSlice/sessionSlice";

const frequencyAndDurationDescription = `Describe frequency, duration and topography of maladaptive behavior.
Describe techniques used as interventions for the behaviors.
State if no maladaptive behaviors occurred.`;

const BehaviorTreatmentData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveBehaviorTreatmentProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { user } = useSelector((state) => state.session.sessionInfo);

  const locationList = useSelector((state) => state.soapNote.locations);
  const supervisor = useSelector(
    (state) => state.soapNote.soapNoteData.supervisor
  );
  const supervisorSessionInfo = useSelector(
    (state) => state.session.supervisorSessionInfo
  );

  const errorList = useSelector((state) => state.soapNote.error.errors);

  const [errors, setErrors] = useReducer(
    behaviorTreatmentErrorReducer,
    initialBehaviorTreatmentErrorState
  );

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSupervisorSessionsInfo(sessionId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  const {
    bariesrsList,
    technuquiesList,
    behaviorTreatmentFutureList,
    clientMoodList,
    clientResponseToTreatmentList,
    levelOfMaladaptiveBehaviorList,
    maladaptiveBehaviorTechniquesList,
    skillDomainAddressedList,
    reinforsmentList,
  } = useBehaviorTreatmentOptions();

  const levelOfbehaviorHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLevelOfMaladaptiveBehavior });
    if (!errors.LevelOfMaladaptiveBehavior) return;
    setErrors({ payload: false, type: ERRORS.LevelOfMaladaptiveBehavior });
  };

  const responseToTreatmentHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setClientResponseToTreatment });
    if (!errors.ClientResponseToTreatment) return;
    setErrors({ payload: false, type: ERRORS.ClientResponseToTreatment });
  };

  const describeSessionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSessionDescription });
    if (!errors.SessionDescription) return;
    setErrors({ payload: false, type: ERRORS.SessionDescription });
  };

  const barrierToTreatmentOtherHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setBarrierToTreatmentOther });
    if (!errors.BarrierToTreatmentOther) return;
    setErrors({ payload: false, type: ERRORS.BarrierToTreatmentOther });
  };

  const howBarrierAffectHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setHowBarrierAffect });
    if (!errors.HowBarrierAffect) return;
    setErrors({ payload: false, type: ERRORS.HowBarrierAffect });
  };

  const workedGoalsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setWorkedGoals });
    if (!errors.WorkedGoals) return;
    setErrors({ payload: false, type: ERRORS.WorkedGoals });
  };

  const describeFrequencyAndDurationHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      payload: value,
      type: ACTIONS.setFrequencyAndDurationOfMaladaptiveBehaviors,
    });
    if (!errors.FrequencyAndDurationOfMaladaptiveBehaviors) return;
    setErrors({
      payload: false,
      type: ERRORS.FrequencyAndDurationOfMaladaptiveBehaviors,
    });
  };

  const bariersTreatmentHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setBarrierToTreatmentResponses, payload: value });
    if (!errors.BarrierToTreatmentResponses) return;
    setErrors({ payload: false, type: ERRORS.BarrierToTreatmentResponses });
  };

  const behaviorTechniquesHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({
      payload: value,
      type: ACTIONS.setBehaviorTreatmentTechniquesResponses,
    });
    if (!errors.BehaviorTreatmentTechniquesResponses) return;
    setErrors({
      payload: false,
      type: ERRORS.BehaviorTreatmentTechniquesResponses,
    });
  };

  const reinforcerHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setReinforcementResponses, payload: value });
    if (!errors.Reinforcement) return;
    setErrors({ payload: false, type: ERRORS.Reinforcement });
  };

  const malaptiveTechniquesHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({
      payload: value,
      type: ACTIONS.setMaladaptiveTechniquesResponses,
    });
    if (!errors.MaladaptiveBehaviorTechniquesResponses) return;
    setErrors({
      payload: false,
      type: ERRORS.MaladaptiveBehaviorTechniquesResponses,
    });
  };

  const skillDomainHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({
      payload: value,
      type: ACTIONS.setSkillDomainAddressed,
    });
    if (!errors.SkillDomainAddressedResponses) return;
    setErrors({ payload: false, type: ERRORS.SkillDomainAddressedResponses });
  };

  const clientMoodHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setClientMoodResponses });
    if (!errors.ClientMoodResponses) return;
    setErrors({ payload: false, type: ERRORS.ClientMoodResponses });
  };

  const futurePlansHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setFuturePlanResponses });
    if (!errors.BehaviorTreatmentFuturePlanResponses) return;
    setErrors({
      payload: false,
      type: ERRORS.BehaviorTreatmentFuturePlanResponses,
    });
  };

  const locationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value.toUpperCase(), type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <>
      <TimeInfo disabled={disabled} />
      {user.role.section.id === AdminTypes.bt && (
        <CustomInput
          label="Behavior Technician:"
          value={user.fullName}
          setValue={() => {}}
          className="marginBottom16"
          disabled={true}
        />
      )}
      {!!supervisorSessionInfo.length && !!supervisor && (
        <div className="padding8" style={{ minWidth: "300px" }}>
          <Text title={"Supervised session info:"} size="smallBold" />
          <InfoRow>
            <Text title={"Supervisor:"} size="smallBold" />
            <Text title={supervisor.fullName} />
          </InfoRow>
          {supervisorSessionInfo.map((x) => (
            <>
              {!!x.location && (
                <InfoRow>
                  <Text title={"Location:"} size="smallBold" />
                  <Text size="small" title={x.location.name} />
                </InfoRow>
              )}
              <InfoRow>
                <Text title={"Date:"} size="smallBold" />
                <Text
                  size="small"
                  title={`${dayjs(x.date).utc(false).format("MM/DD/YYYY")}`}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"Start time:"} size="smallBold" />
                <Text
                  size="small"
                  title={`${dayjs(x.startTime).utc(false).format("hh:mm A")}`}
                />
              </InfoRow>
              <InfoRow>
                <Text title={"End time:"} size="smallBold" />
                <Text
                  size="small"
                  title={`${dayjs(x.endTime).utc(false).format("hh:mm A")}`}
                />
              </InfoRow>
            </>
          ))}
        </div>
      )}
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={locationHandler}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="Describe the session: "
        value={data.sessionDescription}
        setValue={describeSessionHandler}
        className="marginBottom16"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
      />
      <MultipleSelect
        label="Barriers to Treatment:"
        data={bariesrsList}
        setValue={bariersTreatmentHandler}
        className={"marginBottom16"}
        initialValue={data.barrierToTreatmentResponses}
        disabled={disabled}
      />
      {data.barrierToTreatmentResponses.find(
        (x) => bariesrsList.find((y) => y.id === x)?.text === "Other"
      ) && (
        <CustomInput
          label="If other, please describe: "
          value={data.barrierToTreatmentOther}
          setValue={barrierToTreatmentOtherHandler}
          className="marginBottom16"
          multiline={{
            multiline: true,
            rowCount: 3,
          }}
          disabled={disabled}
        />
      )}
      <CustomInput
        label="How did the above barrier affect the session? "
        value={data.howBarrierAffect}
        setValue={howBarrierAffectHandler}
        className="marginBottom16"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
      />
      <MultipleSelect
        label="Skill domains addressed: "
        data={skillDomainAddressedList}
        setValue={skillDomainHandler}
        initialValue={data.skillDomainAddressedResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <MultipleSelect
        label="Techniques Used: "
        data={technuquiesList}
        setValue={behaviorTechniquesHandler}
        initialValue={data.behaviorTreatmentTechniquesResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <MultipleSelect
        label="Reinforcers Used in Sessions: "
        data={reinforsmentList}
        setValue={reinforcerHandler}
        initialValue={data.reinforcementResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="Describe 3 goals that were addressed in the session:"
        value={data.workedGoals}
        setValue={workedGoalsHandler}
        className="marginBottom16"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
      />
      <CustomSelect
        label="Level of maladaptive behavior:"
        data={levelOfMaladaptiveBehaviorList}
        value={`${data.levelOfMaladaptiveBehavior}`}
        setValue={levelOfbehaviorHandler}
        className="marginBottom16"
        disabled={disabled}
      />
      <MultipleSelect
        label="Maladaptive behavior techniques used:"
        data={maladaptiveBehaviorTechniquesList}
        setValue={malaptiveTechniquesHandler}
        className="marginBottom16"
        initialValue={data.maladaptiveBehaviorTechniquesResponses}
        disabled={disabled}
      />
      <CustomInput
        label="Describe the frequency and duration of the maladaptive behavior and the techniques used as an intervention:"
        value={data.frequencyAndDurationOfMaladaptiveBehaviors}
        setValue={describeFrequencyAndDurationHandler}
        className="marginBottom16"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        description={frequencyAndDurationDescription}
        disabled={disabled}
      />

      <MultipleSelect
        label="Client mood:"
        data={clientMoodList}
        setValue={clientMoodHandler}
        className="marginBottom16"
        initialValue={data.clientMoodResponses}
        disabled={disabled}
      />
      <CustomSelect
        label="Client response to treatment:"
        data={clientResponseToTreatmentList}
        value={`${data.clientResponseToTreatment}`}
        setValue={responseToTreatmentHandler}
        className="marginBottom16"
        disabled={disabled}
      />
      <MultipleSelect
        label="Plan for Future Session:"
        data={behaviorTreatmentFutureList}
        setValue={futurePlansHandler}
        initialValue={data.behaviorTreatmentFuturePlanResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <Text
        title="Session summary"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          error={errors.Signature}
          value={data.signature}
          setValue={signatureHandler}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </>
  );
};
export default BehaviorTreatmentData;
