import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomRadio,
  Text,
} from "../../../../shared/uiComponents";
import { blue400 } from "../../../../shared/Helpers/colors";
import { InfoRow, Loader } from "../../../../components/StyledComponents";

import { DispatchProperties } from "../../../../redux/store";
import {
  AuthorizationDetailsProperties,
  GetAllocatedHoursDetailAuthorizationProperties,
  InsuranceAllocatedAuthorizationDetailsProperties,
  InsuranceAuthorizationDetailsProperties,
} from "../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import {
  getAllocatedHoursAuthorizationDetails,
  getAvailableHoursDetails,
} from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const AuthorizationDetails = ({
  data,
  authorizationId,
}: {
  authorizationId: string;
  data: Array<InsuranceAuthorizationDetailsProperties>;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();

  const [availableHoursDetails, setAvailableHoursDetails] = useState<
    Array<AuthorizationDetailsProperties>
  >([]);

  useEffect(() => {
    if (!authorizationId || !clientId) return;
    dispatch(getAvailableHoursDetails({ authorizationId, clientId }))
      .then(unwrapResult)
      .then((response) => setAvailableHoursDetails(response))
      .catch(errorNormalizer);
  }, [clientId, authorizationId, dispatch]);

  const selectDetailsHandler = (id: string) => {
    params.set("detailsId", id);
    params.set("authId", authorizationId);
    setParams(params);
  };

  return !!data ? (
    <>
      {data.map((x) => {
        const availableDetails = availableHoursDetails.find(
          (y) => y.id === x.id
        );
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <div style={{ width: "20px" }}>
              <CustomRadio
                onChange={() => selectDetailsHandler(x.id)}
                id={x.id}
                value={params.get("detailsId")}
              />
            </div>
            <div style={{ width: "230px" }}>{x.sessionType.name} </div>
            <div style={{ width: "50px" }}>{x.totalHours} </div>
            <div style={{ width: "50px" }}>
              {!!x.hoursPerWeek && x.hoursPerWeek}
            </div>
            <div style={{ width: "100px" }}>
              {!!availableDetails
                ? availableDetails.frequency.frequency === 2
                  ? `${availableDetails.totalHours} h`
                  : `${availableDetails.hoursPerWeek} h/week`
                : 0}
            </div>
            <AssignedHoursDetails
              authorizationDetailId={x.id}
              authorizationId={authorizationId}
            />
          </div>
        );
      })}
    </>
  ) : (
    <Loader />
  );
};

const AssignedHoursDetails = ({
  authorizationDetailId,
  authorizationId,
}: {
  authorizationId: string;
  authorizationDetailId: string;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [details, setDetails] = useState<
    Array<InsuranceAllocatedAuthorizationDetailsProperties>
  >([]);

  const [label, setLabel] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const { clientId } = useParams();

  useEffect(() => {
    if (!clientId) return;
    const data: GetAllocatedHoursDetailAuthorizationProperties = {
      authorizationId,
      clientId,
      authorizationDetailId,
    };
    dispatch(getAllocatedHoursAuthorizationDetails(data))
      .then(unwrapResult)
      .then((response) => {
        const hoursPerWeek = response.reduce(
          (total, x) => total + x.hoursPerWeek,
          0
        );
        const totalHours = response.reduce(
          (total, x) => total + x.totalHours,
          0
        );
        const label = !!hoursPerWeek
          ? `${hoursPerWeek} h/week`
          : `${totalHours} h`;
        setLabel(label);
        setDetails(response);
      })
      .catch(() => {});
  }, [clientId, authorizationDetailId, authorizationId, dispatch]);

  return (
    <>
      <div
        style={{
          textDecoration: "underline",
          color: blue400,
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        {label}
      </div>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title="Details"
        Content={() =>
          !!details.length ? (
            <>
              <InfoRow>
                <Text title={"Name"} size="smallBold" />
                <Text title={"Frequency - Hours"} />
              </InfoRow>
              {details.map((x, index) => (
                <InfoRow>
                  <Text
                    title={`${index + 1}. ${x.user.fullName}`}
                    size="smallBold"
                  />
                  <Text
                    title={`${x.frequency.name} - ${
                      !!x.hoursPerWeek ? x.hoursPerWeek : x.totalHours
                    }`}
                  />
                </InfoRow>
              ))}
            </>
          ) : (
            <Text title={"No Data"} />
          )
        }
      />
    </>
  );
};

export default AuthorizationDetails;
