import { CreateProgramTargetProperties } from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

export enum ACTIONS {
    setName = "setName",
    setGoalName = "setGoalName",
    setSD = "setSD",
    setTargetInstructions = "setTargetInstructions",
    setTypeId = "setTypeId",
    setErrorCorrection = "setErrorCorrection",
    setReinforcement = "setReinforcement",
    setConsecutiveDays = "setConsecutiveDays",
    setMasteryCriteria = "setMasteryCriteria",
    setResponsePercentage = "setResponsePercentage",
}


export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
}

export const TargetReducer = (state: CreateProgramTargetProperties, action: ActionProperties): CreateProgramTargetProperties => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setName:
            return {
                ...state,
                name: payload
            }
        case ACTIONS.setGoalName:
            return {
                ...state,
                goalName: payload
            }
        case ACTIONS.setSD:
            return {
                ...state,
                sd: payload
            }
        case ACTIONS.setTargetInstructions:
            return {
                ...state,
                targetInstructions: payload
            }
        case ACTIONS.setTypeId:
            return {
                ...state,
                typeId: payload
            }
        case ACTIONS.setErrorCorrection:
            return {
                ...state,
                errorCorrection: payload
            }
        case ACTIONS.setReinforcement:
            return {
                ...state,
                reinforcement: payload
            }
        case ACTIONS.setMasteryCriteria:
            return {
                ...state,
                masteryCriteria: payload
            }
        case ACTIONS.setConsecutiveDays:
            return {
                ...state,
                consecutiveDays: payload
            }
        case ACTIONS.setResponsePercentage:
            return {
                ...state,
                responsePercentage: payload
            }
        default:
            return state;
    }
}