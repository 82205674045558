import { useState } from "react";
import { ContentCopyOutlined as Copy } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { CustomAlert } from "../../../../../shared/uiComponents";
import CopyAuthorizationContent from "./copyAuthorizationContent";

import { ActiveAuthorizationProperties } from "../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";

const CopyAuthorization = ({
  authorizationData,
}: {
  authorizationData: ActiveAuthorizationProperties;
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const editHandler = () => {
    setEditOpen(true);
  };

  return (
    <>
      <Tooltip title={"Copy"}>
        <Copy onClick={editHandler} fontSize="small" />
      </Tooltip>
      <CustomAlert
        open={editOpen}
        onClose={() => setEditOpen(false)}
        title="Copy Insurance Authorization"
        Content={({ onClose }) => (
          <CopyAuthorizationContent
            authorizationData={authorizationData}
            onClose={onClose}
          />
        )}
      />
    </>
  );
};

export default CopyAuthorization;
