import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  LogoutOutlined as Logout,
} from "@mui/icons-material";

import {
  useMultipleTabs,
  useRefreshToken,
  useScreenWidth,
} from "../../shared/Helpers/hooks";
import Lighthouse from "../../shared/Assets/logos/IlluminateLogoMain.png";
import { MENU } from "../../App/constants";
import { CustomButton } from "../../shared/uiComponents";
import {
  AppBar,
  Container,
  CustomToolbar,
  DrawerHeader,
  DrawerWrapper,
  FlexCenter,
  IMG,
  Main,
  Wrapper,
} from "./styledComponents";
import MenuElements from "./menuElements";
import { Loader } from "../StyledComponents";
import Previous from "./previous";

import { DispatchProperties, useSelector } from "../../redux/store";
import { resetState } from "../../redux/resetState";
import {
  getAccountRoles,
  refresh,
} from "../../redux/State/identitySlice/accountSlice";
import { getRefreshToken } from "../../redux/API/Main/helpers";
import SavedDraftsInfo from "./savedDraftsInfo";

export interface AdminTypeProperties {
  isSuperAdmin?: boolean;
  isAdmin: boolean;
  isBCBA: boolean;
  isBT: boolean;
}

export enum AdminTypes {
  "admin",
  "bcba",
  "bt",
}

const initialAdminTypesState: AdminTypeProperties = {
  isAdmin: false,
  isSuperAdmin: false,
  isBCBA: false,
  isBT: false,
};

const Layout = () => {
  useRefreshToken();
  useMultipleTabs();

  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const outlet = useOutlet();
  const dispatch = useDispatch<DispatchProperties>();

  const [open, setOpen] = useState<boolean>(false);
  const [{ isAdmin, isBCBA, isBT }, setAdminType] =
    useState<AdminTypeProperties>(initialAdminTypesState);

  const role = useSelector((state) => state.account.role);
  const user = useSelector((state) => state.account.user);
  const loading = useSelector((state) => state.account.loading);

  useEffect(() => {
    const token = getRefreshToken();
    if (!!user.id || !token) return;
    dispatch(refresh(token));
  }, [user, dispatch]);

  useEffect(() => {
    if (!role) return;
    setAdminType({
      isSuperAdmin: role.name === "super-admin",
      isAdmin: role.section.id === AdminTypes.admin,
      isBCBA: role.section.id === AdminTypes.bcba,
      isBT: role.section.id === AdminTypes.bt,
    });
  }, [role]);

  useEffect(() => {
    if (!!role) return;
    dispatch(getAccountRoles());
  }, [role, dispatch]);

  useEffect(() => {
    if (!outlet) navigate("/dashboard");
  }, [outlet, navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOutHandler = () => {
    dispatch(resetState());
    navigate("/login");
  };

  return (
    <Container>
      <AppBar position="fixed" open={open}>
        <CustomToolbar>
          <FlexCenter>
            {screenWidth < 1025 && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Previous />
          </FlexCenter>
          <SavedDraftsInfo />
          <FlexCenter>
            {screenWidth > 768 ? (
              <CustomButton
                title={"Log out"}
                onClick={logOutHandler}
                secondaryButton
              />
            ) : (
              <Logout onClick={logOutHandler} />
            )}
          </FlexCenter>
        </CustomToolbar>
      </AppBar>
      <DrawerWrapper
        variant={screenWidth > 1024 ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IMG src={Lighthouse} />
          {screenWidth < 1025 && (
            <div style={{ position: "fixed", top: 12, left: 0 }}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          )}
        </DrawerHeader>
        {!loading ? (
          <div onClick={handleDrawerClose}>
            {isAdmin && <MenuElements menu={MENU.ADMIN} />}
            {isBCBA && <MenuElements menu={MENU.BCBA} />}
            {isBT && <MenuElements menu={MENU.BT} />}
          </div>
        ) : (
          <Loader />
        )}
      </DrawerWrapper>

      <Main open={open}>
        <DrawerHeader />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Main>
    </Container>
  );
};

export default Layout;
