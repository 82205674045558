import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SelectChangeEvent } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomAlert,
  CustomSelect,
  Text,
  Picker,
  CustomButton,
} from "../../../shared/uiComponents";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { dateNormalizer } from "../../../shared/Helpers/functions";
import { DayProperties } from "../Helpers/interfaces";
import { useResetCalendarData } from "../Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { setDeleteEvent } from "../../../redux/State/clientSlice/calendarSlice";
import {
  deleteEvents,
  deleteUpcomingEvent,
  getAllEventsForOneDay,
} from "../../../redux/State/clientSlice/calendarSlice";
import {
  DeleteEventsProperties,
  DeleteUpcomingEventsProperties,
} from "../../../redux/API/ClientAPIHelpers/calendarProperties";

const DeleteEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.delete);

  const selectedDay = useSelector((state) => state.calendar.selectedDay);
  const closeHandler = () => {
    dispatch(setDeleteEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      title={"Delete events"}
      onClose={closeHandler}
      Content={() => (
        <DeleteContent dayInfo={selectedDay} onClose={closeHandler} />
      )}
    />
  );
};

const DeleteContent = ({
  onClose,
  dayInfo,
}: {
  onClose: () => void;
  dayInfo: DayProperties;
}) => {
  const dayInfoValue = dayjs(
    `${dayInfo.month + 1}/${dayInfo.day}/${dayInfo.year}`
  );
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { resetData } = useResetCalendarData();

  const [startValue, setStartValue] = useState<Dayjs>(dayInfoValue);
  const [startValueForClient, setStartValueForClient] =
    useState<Dayjs>(dayInfoValue);
  const [endValue, setEndValue] = useState<Dayjs>(dayInfoValue);
  const [clientList, setClientList] = useState<Array<SelectDataProperties>>([]);
  const [clientId, setClientId] = useState<string>("");
  const { hasError, message } = useCompareDates(startValue, endValue);

  useEffect(() => {
    const date = dateNormalizer(dayInfoValue);

    const includeBTEvents =
      params.get("includeBTEvents") === "true" ? true : false;

    if (!date) return;
    dispatch(getAllEventsForOneDay({ date, includeBTEvents }))
      .then(unwrapResult)
      .then((response) => {
        const uniqueClientList: Array<SelectDataProperties> = [];
        response.forEach((event) => {
          if (
            !uniqueClientList.find((client) => client.id === event.client.id)
          ) {
            uniqueClientList.push({
              id: event.client.id,
              name: event.client.fullName,
            });
          }
        });
        setClientList(uniqueClientList);
        if (!!uniqueClientList.length) {
          setClientId(uniqueClientList[0].id as string);
        }
      });
  }, [dispatch, dayInfoValue, params]);

  const onClientChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setClientId(value);
  };

  const deleteAllEventsHandler = () => {
    if (!startValue || !endValue) return;
    const startDate = dateNormalizer(startValue);
    const endDate = dateNormalizer(endValue);

    const data: DeleteEventsProperties = {
      startDate,
      endDate,
    };
    dispatch(deleteEvents(data))
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) {
          throw new Error("Failed");
        }
        resetData();
        toast("Deleted");
        onClose();
      })
      .catch((error) => toast(error.message || error.title));
  };

  const deleteClientEventsHandler = () => {
    if (!startValueForClient || !clientId) return;
    const startDate = dateNormalizer(startValueForClient);

    const data: DeleteUpcomingEventsProperties = {
      clientId: clientId,
      startDate,
    };
    dispatch(deleteUpcomingEvent(data))
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) {
          throw new Error("Failed");
        }
        resetData();
        toast("Deleted");
        onClose();
      })
      .catch((error) => toast(error.message || error.title));
  };

  const onStartTimeChange = (value: Dayjs | null) => {
    if (!value) return;
    setStartValue(value);
  };

  const onEndTimeChange = (value: Dayjs | null) => {
    if (!value) return;
    setEndValue(value);
  };

  const onStartTimeClientChange = (value: Dayjs | null) => {
    if (!value) return;
    setStartValueForClient(value);
  };

  return (
    <div>
      <div>
        <Text
          title="Delete all events:"
          className="marginBottom16"
          size="mediumBold"
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          className={"marginBottom16"}
        >
          <Picker.CustomDate
            label="Start"
            value={startValue}
            onChange={onStartTimeChange}
            className="marginRight8"
          />
          <Picker.CustomDate
            label="End"
            value={endValue}
            onChange={onEndTimeChange}
            error={hasError}
            errorMessage={message}
          />
        </div>
        <CustomButton
          title="Delete all"
          className={"marginBottom16"}
          onClick={deleteAllEventsHandler}
        />
        <Text
          title="Delete client events:"
          className="marginBottom16"
          size="mediumBold"
        />
        <div className={"marginBottom16"}>
          <Picker.CustomDate
            label="Start"
            value={startValueForClient}
            onChange={onStartTimeClientChange}
            className={"marginBottom8"}
          />
          <CustomSelect
            label="Select Client"
            data={clientList}
            value={clientId}
            setValue={onClientChange}
            className={"marginBottom16"}
          />
        </div>

        <CustomButton
          title="Delete Client events"
          onClick={deleteClientEventsHandler}
        />
      </div>
    </div>
  );
};

export default DeleteEvent;
