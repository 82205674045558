import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CircularProgress, styled } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import { Text } from "../../../shared/uiComponents";
import { black500, blue400, red300 } from "../../../shared/Helpers/colors";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import Delete from "../../../components/Delete";
import { AdminTypes } from "../../../components/Menu";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { TherapistProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";
import {
  dismissClient,
  getTherapists,
} from "../../../redux/State/clientSlice/userClientSlice";
import { DismissClientProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";

const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "360px",
}));

const TherapistsList = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const therapists = useSelector((state) => state.userClient.therapists);

  const [value, setValue] = useState<number>(0);
  const [bcbas, setBcbas] = useState<Array<TherapistProperties>>([]);
  const [bts, setBts] = useState<Array<TherapistProperties>>([]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!therapists) return;
    const bcbas = therapists.filter(
      (therapist) => therapist.role.section.id === AdminTypes.bcba
    );
    const bts = therapists.filter(
      (therapist) => therapist.role.section.id === AdminTypes.bt
    );
    setBcbas(bcbas);
    setBts(bts);
  }, [therapists]);

  if (!therapists) {
    return (
      <Wrapper>
        <div style={{ width: "max-content", margin: "auto" }}>
          <CircularProgress />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div style={{ display: "flex", gap: "8px" }}>
        {["All", "BCBA", "BT"].map((x, index) => (
          <Text
            key={index}
            title={x}
            size={"mediumBold"}
            onClick={() => setValue(index)}
            textColor={value === index ? blue400 : black500}
            className="paddingLeft8 paddingRight8"
          />
        ))}
      </div>
      {[therapists, bcbas, bts].map((users, index) => (
        <div hidden={value !== index} key={index}>
          <List therapists={users} />
        </div>
      ))}
    </Wrapper>
  );
};

const List = ({ therapists }: { therapists: Array<TherapistProperties> }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    if (!clientId || !userId) return;
    dispatch(dismissClient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        dispatch(getTherapists(clientId));
      })
      .catch(errorNormalizer);
  };

  return (
    <div className="paddingTop8">
      {!!therapists &&
        therapists.map((therapist, index) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text
              key={index}
              title={therapist.fullName}
              textColor={
                therapist.role.section.id === AdminTypes.bcba
                  ? red300
                  : therapist.role.section.id === AdminTypes.bt
                  ? blue400
                  : black500
              }
              size="tinyBold"
            />
            {!!therapist.id && !!clientId && (
              <Delete
                deleteHandler={() =>
                  dismissHandler({
                    clientId: clientId,
                    userId: therapist.id,
                  })
                }
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default TherapistsList;
