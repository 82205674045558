import AddEvent from "./addEvent";
import AdminAddEvent from "./adminAddEvent";
import CopyEvent from "./copyEvent";
import DeleteEvent from "./deleteEvent";

const CalendarPopups = () => (
  <>
    <AddEvent />
    <CopyEvent />
    <DeleteEvent />
    <AdminAddEvent />
  </>
);

export default CalendarPopups;
