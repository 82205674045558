import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Done } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams, useSearchParams } from "react-router-dom";
import { Add } from "@mui/icons-material";

import {
  CustomCheckBox,
  CustomInput,
  Text,
} from "../../../../../shared/uiComponents";
import {
  black500,
  blue100,
  red200,
  white100,
} from "../../../../../shared/Helpers/colors";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { AdminTypes } from "../../../../../components/Menu";
import Delete from "./delete";
import Copy from "./copy";
import Archive from "./archive";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  addSkillAreas,
  getSkillAreas,
  setSkillAreaId,
  updateSkillAreas,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";
import {
  SkillAreaProperties,
  SkillProperties,
} from "../../../../../redux/API/ClientAPIHelpers/skillAreaProperties";

export const SkillAreaMenu = ({ item }: { item: SkillProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const choosenId = useSelector((state) => state.skillArea.skillAreaId);

  const [name, setName] = useState<string>(item.name);
  const [menu, setMenu] = useState<boolean>(false);
  const [rename, setRename] = useState<boolean>(false);

  const onRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMenu(true);
  };

  const renameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const onRenameClick = () => {
    setMenu(false);
    setRename(true);
  };

  const refreshSkillAreas = () => {
    const { clientId } = item;
    const includeArchived =
      params.get("includeArchived") === "true" ? true : false;

    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  };

  const updateNameHandler = () => {
    const { clientId, id } = item;
    const data: SkillAreaProperties = {
      clientId,
      name,
    };
    dispatch(updateSkillAreas({ data, id }))
      .then(unwrapResult)
      .then(() => {
        setMenu(false);
        setRename(false);
        refreshSkillAreas();
      })
      .catch(errorNormalizer);
  };

  const chooseSkillAreaHandler = (id: string) => {
    if (!id) return;
    dispatch(setSkillAreaId(id));
    setMenu(false);
  };

  return (
    <div
      onContextMenu={onRightClick}
      onClick={() => chooseSkillAreaHandler(item.id)}
      className={"padding8 margin8 lightBlackBorder"}
      style={{
        position: "relative",
        borderBottom: choosenId === item.id ? `2px solid ${blue100}` : "none",
      }}
    >
      {rename ? (
        <CustomInput
          value={name}
          setValue={renameHandler}
          EndAdornment={{
            Icon: Done,
            onClickEndAdornment: updateNameHandler,
          }}
        />
      ) : (
        <Text
          title={item.name}
          size="tinyBold"
          textColor={
            choosenId === item.id
              ? blue100
              : item.isArchived
              ? red200
              : black500
          }
          onClick={() => {}}
        />
      )}
      <div
        style={{
          position: "absolute",
          display: menu && choosenId === item.id ? "block" : "none",
          top: "100%",
          left: "50%",
          backgroundColor: white100,
          border: "1px solid black",
          marginTop: "10px",
          padding: "10px",
          zIndex: 100,
          width: "90px",
        }}
        onClick={() => setMenu(false)}
        onMouseLeave={() => setMenu(false)}
      >
        <Text title="Rename" onClick={onRenameClick} />
        <Delete item={item} />
        <Copy item={item} />
        <Archive item={item} />
      </div>
    </div>
  );
};

const SkillArea = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();
  const includeArchived =
    params.get("includeArchived") === "true" ? true : false;

  const [name, setName] = useState<string>("");

  const skillAreas = useSelector((state) => state.skillArea.skillAreas);
  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  }, [clientId, includeArchived, dispatch]);

  const addSkillAreaHandler = async () => {
    if (!clientId || !name) return;

    dispatch(addSkillAreas({ clientId, name }))
      .then(unwrapResult)
      .then((response) => {
        dispatch(getSkillAreas({ clientId, includeArchived }));
        const { id } = response;
        dispatch(setSkillAreaId(id));
        setName("");
      })
      .catch(errorNormalizer);
  };

  const nameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const showArchivedSkillAreasHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    params.set("includeArchived", `${!checked}`);
    setParams(params);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!!role && role.section.id !== AdminTypes.bt && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            <CustomInput
              placeholder="Add Skill Area"
              value={name}
              setValue={nameHandler}
              className={"marginTop8"}
              EndAdornment={{
                Icon: Add,
                onClickEndAdornment: addSkillAreaHandler,
              }}
            />
            <CustomCheckBox
              onChange={showArchivedSkillAreasHandler}
              item={{
                id: "0",
                checked: includeArchived,
                label: `Show Archived Skill Areas`,
              }}
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {!!skillAreas &&
          skillAreas.map((item, index) => (
            <SkillAreaMenu item={item} key={index} />
          ))}
      </div>
    </div>
  );
};

export default SkillArea;
