import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ImageWrapper, WhiteContainer } from "../Login/styledComponents";
import { IMG } from "../../components/Menu/styledComponents";
import { Text, CustomInput, CustomButton } from "../../shared/uiComponents";
import { errorNormalizer, validateEmail } from "../../shared/Helpers/functions";
import Logo from "../../shared/Assets/logos/IlluminateLogoMain.png";

import { DispatchProperties, useSelector } from "../../redux/store";
import { forgetPassword } from "../../redux/State/identitySlice/accountSlice";

export const RecoveryPasswordContent = () => {
  const [params] = useSearchParams();
  const loading = useSelector((state) => state.account.loading);

  const dispatch = useDispatch<DispatchProperties>();

  const [email, setEmail] = useState<string>(params.get("email") ?? "");
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (!!email && !validateEmail(email)) {
      setEmailError("Invalid email");
    }
  }, [email]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(forgetPassword(email))
      .then(unwrapResult)
      .then(() => toast("Your recovery link is sent"))
      .catch(errorNormalizer);
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  return (
    <WhiteContainer>
      <Text
        title={"Recover password"}
        size={"largeBold"}
        className={"marginBottom24"}
      />

      <form onSubmit={handleSubmit}>
        <CustomInput
          value={email}
          setValue={onEmailChange}
          label={"Email"}
          className={"marginBottom16"}
          type={"email"}
          error={!!emailError}
          errorMessage={emailError}
        />

        <CustomButton
          title={"Send email"}
          type={"submit"}
          loading={loading}
          disabled={!!emailError}
        />
      </form>

      <ImageWrapper>
        <IMG src={Logo} alt={"Logo"} />
      </ImageWrapper>
    </WhiteContainer>
  );
};
