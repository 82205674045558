import { ChangeEvent, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CustomTable, CustomCheckBox } from "../../../../shared/uiComponents";
import { blue400 } from "../../../../shared/Helpers/colors";
import { AdminTypes } from "../../../../components/Menu";
import SkillArea from "./SkillAreas";
import AddProgram from "./Actions/addProgram";
import UploadPrograms from "./Actions/uploadExcelFile";
import {
  ProgramTableBody,
  programHeaders,
  programHeadersBT,
} from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getPrograms } from "../../../../redux/State/clientSlice/clientProgramSlice";

const SkillAcquisition: FC = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();

  const programs = useSelector((state) => state.clientProgram.programs);
  const skillAreaId = useSelector((state) => state.skillArea.skillAreaId);
  const role = useSelector((state) => state.account.role);
  const loading = useSelector((state) => state.clientProgram.loading);

  const includeArchived =
    params.get("includeArchivedPrograms") === "true" ? true : false;

  useEffect(() => {
    if (!skillAreaId) return;
    dispatch(getPrograms({ skillAreaId, includeArchived }));
  }, [skillAreaId, includeArchived, dispatch]);

  const showArchivedProgramsHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    params.set("includeArchivedPrograms", `${checked}`);
    setParams(params);
  };

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SkillArea />
        <UploadPrograms />
      </div>
      <CustomTable
        headers={
          !!role && role.section.id !== AdminTypes.bt
            ? programHeaders
            : programHeadersBT
        }
        TableBody={ProgramTableBody}
        data={{ query: programs }}
        loading={loading}
        hasPagination={false}
      />
      {!!role && role.section.id !== AdminTypes.bt && (
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <AddProgram />
          <CustomCheckBox
            onChange={showArchivedProgramsHandler}
            item={{
              id: "0",
              checked: includeArchived,
              label: `Show Archived Programs`,
              disabled: loading,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SkillAcquisition;
