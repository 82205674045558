import { ChangeEvent, useState, useEffect, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  CustomInput,
  CustomSelect,
} from "../../../../../shared/uiComponents";
import { SelectDataProperties } from "../../../../../shared/uiComponents/Dropdown";
import { AdminTypes } from "../../../../../components/Menu";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getBehaviorCategories,
  getBehaviorTypes,
  getBehaviors,
  saveBehavior,
} from "../../../../../redux/State/clientSlice/behaviorReductionSlice";
import { SaveClientBehaviorsProperties } from "../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const AddBehavior = () => {
  const [open, setOpen] = useState<boolean>(false);

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);
  const role = useSelector((state) => state.account.role);

  if (!!role && role.section.id === AdminTypes.bt) return null;

  return (
    <div style={{ width: "200px" }} className="marginBottom8">
      <CustomButton title="Create new behavior" onClick={openHandler} />
      <CustomAlert
        title="Add new behavior"
        open={open}
        onClose={closeHandler}
        Content={AddBehaviorContent}
      />
    </div>
  );
};

const AddBehaviorContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoryError, setCategoryError] = useState<string>("");
  const [goalName, setGoalName] = useState<string>("");
  const [definition, setDefinition] = useState<string>("");
  const [recordingType, setRecordingType] = useState<number>(0);
  const [recordingTypeError, setRecordingTypeError] = useState<string>("");
  const [typesData, setTypesData] = useState<Array<SelectDataProperties>>([]);

  const loading = useSelector((state) => state.behaviorReduction.loading);
  const categories = useSelector(
    (state) => state.behaviorReduction.behaviorCategories
  );
  const types = useSelector((state) => state.behaviorReduction.behaviorTypes);

  useEffect(() => {
    dispatch(getBehaviorCategories());
    dispatch(getBehaviorTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!!typesData.length || !types.length) return;
    const value = types.map((t) => ({ id: t.id, name: t.text }));
    setTypesData(value);
  }, [typesData, types]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    setNameError("");
  };

  const onNameBlur = () => {
    if (!!name) return;
    setNameError("Required");
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGoalName(value);
  };

  const onDefinitionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDefinition(value);
  };

  const onCategoryIdChange = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    setCategoryId(value);
    setCategoryError("");
  };

  const onCategoryBlur = () => {
    if (!!categoryId) return;
    setCategoryError("Required");
  };

  const onTypeIdChange = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    setRecordingType(value);
    setRecordingTypeError("");
  };

  const onRecordingTypeBlur = () => {
    if (!!recordingType) return;
    setRecordingTypeError("Required");
  };

  const addBehaviorHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name) setNameError("Required");
    if (!recordingType) setRecordingTypeError("Required");
    if (!categoryId) setCategoryError("Required");

    if (!clientId || !name || !recordingType || !categoryId) return;
    const includeArchived =
      params.get("includeArchivedBehavior") === "true" ? true : false;
    const data: SaveClientBehaviorsProperties = {
      clientId,
      name,
      categoryId,
      goalName,
      definition,
      recordingType,
      doNotZeroOut: false,
    };

    dispatch(saveBehavior(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviors({ clientId, includeArchived }));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <form onSubmit={addBehaviorHandler}>
      <CustomInput
        label="Name"
        value={name}
        setValue={onNameChange}
        className="marginBottom8"
        onBlur={onNameBlur}
        error={!!nameError}
        errorMessage={nameError}
      />
      <CustomSelect
        label="Category"
        value={`${categoryId}`}
        data={categories}
        setValue={onCategoryIdChange}
        className="marginBottom8"
        onBlur={onCategoryBlur}
        error={!!categoryError}
        errorMessage={categoryError}
      />
      <CustomInput
        label="Goal Name"
        value={goalName}
        setValue={onGoalNameChange}
        className="marginBottom8"
      />
      <CustomInput
        label="Definition"
        value={definition}
        setValue={onDefinitionChange}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
      />
      <CustomSelect
        label="Recording type"
        value={`${recordingType}`}
        setValue={onTypeIdChange}
        data={typesData}
        className="marginBottom16"
        onBlur={onRecordingTypeBlur}
        error={!!recordingTypeError}
        errorMessage={recordingTypeError}
      />
      <CustomButton
        title="Save"
        type="submit"
        disabled={
          !clientId || !!nameError || !!recordingTypeError || !!categoryError
        }
        loading={loading}
      />
    </form>
  );
};

export default AddBehavior;
