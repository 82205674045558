import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  DisplayDataProperties,
  commonOptions,
  whiteBackgroundPlugin,
} from "../../../../BehaviorReduction/Actions/BehaviorReductionGraph/graphHelpers";

export const LineDisplay = ({
  scaleX,
  startDate,
  displayData,
  scaleY,
}: {
  scaleX: any;
  startDate: string;
  displayData: Array<DisplayDataProperties>;
  scaleY: any;
}) => {
  const [xLineAverage, setXLineAverage] = useState<number>(0);

  useEffect(() => {
    const length = displayData.length;
    const total = displayData.reduce((total, item) => total + item.y, 0);
    setXLineAverage(total / length);
  }, [displayData]);

  return (
    <Line
      options={{
        ...commonOptions,
        responsive: true,
        scales: { x: scaleX, y: scaleY },
      }}
      data={{
        labels: [
          new Date(startDate),
          new Date().setDate(new Date().getDate() + 2),
        ],
        datasets: [
          {
            label: "",
            data: displayData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Average",
            data: Array(displayData.length).fill(xLineAverage),
            fill: false,
            borderColor: "red",
            borderDash: [5, 5],
          },
        ],
      }}
    />
  );
};

export const LineDownload = ({
  scaleX,
  startDate,
  displayData,
  scaleY,
}: {
  scaleX: any;
  startDate: string;
  displayData: Array<DisplayDataProperties>;
  scaleY: any;
}) => {
  const [xLineAverage, setXLineAverage] = useState<number>(0);

  useEffect(() => {
    const length = displayData.length;
    const total = displayData.reduce((total, item) => total + item.y, 0);
    setXLineAverage(total / length);
  }, [displayData]);

  return (
    <Line
      options={{
        ...commonOptions,
        responsive: false,
        scales: { x: scaleX, y: scaleY },
      }}
      style={{ display: "none" }}
      plugins={[whiteBackgroundPlugin]}
      data={{
        labels: [new Date(startDate), new Date()],
        datasets: [
          {
            label: "",
            data: displayData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Average",
            data: Array(displayData.length).fill(xLineAverage),
            fill: false,
            borderColor: "red",
            borderDash: [5, 5],
          },
        ],
      }}
      height={300}
      width={500}
      id="stackId"
    />
  );
};
