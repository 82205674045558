import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import { CustomAlert } from "../../../../shared/uiComponents";
import { CustomButton, Text } from "../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { ButtonWrapper } from "../../../../components/StyledComponents";

import { DispatchProperties } from "../../../../redux/store";
import { ActiveAuthorizationProperties } from "../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import {
  deleteAuthorization,
  getInsuranceAuthorizations,
} from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const DeleteAuthorization = ({
  authorizationData,
}: {
  authorizationData: ActiveAuthorizationProperties;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const [open, setOpen] = useState<boolean>(false);

  const openDeleteHandler = () => {
    setOpen(true);
  };

  const fetchAuthorizations = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    if (!clientId) return;
    dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
  };

  const deleteAuthorizationHandler = () => {
    if (!clientId || !authorizationData?.id) return;
    dispatch(
      deleteAuthorization({ authorizationId: authorizationData.id, clientId })
    )
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) {
          throw new Error("Failed");
        }
        fetchAuthorizations();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <Tooltip title="Delete">
        <DeleteOutlineOutlined onClick={openDeleteHandler} />
      </Tooltip>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title="Deleting Insurance Authorization."
        Content={({ onClose }) => (
          <div style={{ textAlign: "center" }}>
            <Text
              title={"Are you sure?"}
              size="largeBold"
              className="marginBottom16"
            />
            <ButtonWrapper>
              <CustomButton title="Cancel" secondaryButton onClick={onClose} />
              <CustomButton
                title="Confirm"
                onClick={deleteAuthorizationHandler}
              />
            </ButtonWrapper>
          </div>
        )}
      />
    </>
  );
};

export default DeleteAuthorization;
