import { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material";

import {
  CustomCheckBox,
  CustomInput,
} from "../../../../../../../shared/uiComponents";
import { blue400 } from "../../../../../../../shared/Helpers/colors";

import {
  TargetStepsDataProperties,
  UpdateTargetStepsProperties,
} from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const StepsWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  ".inputs": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "max-content",
    ">div": {
      marginRight: "16px",
    },
  },
  ".checkboxes": {
    display: "flex",
    width: "max-content",
  },
  "@media (max-width: 900px)": {
    flexWrap: "wrap",
    border: `1px solid ${blue400}`,
    borderRadius: "12px",
    padding: "16px",
    ".inputs": {
      marginBottom: "16px",
    },
  },
  "@media (max-width: 600px)": {
    ".inputs": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));

const UpdateStep = ({
  data,
  targetSteps,
  updateTargetStep,
}: {
  updateTargetStep: (data: UpdateTargetStepsProperties) => void;
  data: UpdateTargetStepsProperties;
  targetSteps: Array<UpdateTargetStepsProperties | TargetStepsDataProperties>;
}) => {
  const [error, setError] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<number>(0);
  const [enable, setEnable] = useState<boolean>(true);
  const [useForMastery, setUseForMaster] = useState<boolean>(true);

  useEffect(() => {
    setName(data.name);
    setNote(data.note);
    setOrderNumber(data.orderNumber);
    setEnable(data.enabled);
    setUseForMaster(data.useForMastery);
  }, [data]);

  useEffect(() => {
    if (!enable) {
      setUseForMaster(false);
    }
  }, [enable]);

  const onOrderNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!value) {
      setOrderNumber(0);
      return;
    }

    if (value > targetSteps.length) {
      setOrderNumber(targetSteps.length);
      return;
    }
    setOrderNumber(value);
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const onNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNote(value);
  };

  useEffect(() => {
    setError(!name);
  }, [name]);

  const editTargetStepHandler = () => {
    const step: UpdateTargetStepsProperties = {
      id: data.id,
      controlId: data.controlId,
      orderNumber,
      name,
      note,
      enabled: enable[0].checked,
      useForMastery: useForMastery[0].checked,
    };
    updateTargetStep(step);
  };

  const onEnableChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setEnable(checked);
  };

  const onUseForMasterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setUseForMaster(checked);
  };

  return (
    <StepsWrapper className="marginBottom16">
      <div className="inputs">
        <CustomInput
          label="Order"
          value={orderNumber}
          setValue={onOrderNumberChange}
          className="width60"
          onBlur={editTargetStepHandler}
        />
        <CustomInput
          label="Step Name"
          value={name}
          setValue={onNameChange}
          error={error}
          onBlur={editTargetStepHandler}
        />
        <CustomInput
          label="Note"
          value={note}
          setValue={onNoteChange}
          onBlur={editTargetStepHandler}
        />
      </div>
      <div className="checkboxes">
        <CustomCheckBox
          item={{ checked: enable, id: "1" }}
          onChange={onEnableChange}
          labelPlacement="top"
          onBlur={editTargetStepHandler}
        />
        <CustomCheckBox
          item={{ checked: useForMastery, id: "2" }}
          onChange={onUseForMasterChange}
          onBlur={editTargetStepHandler}
          labelPlacement="top"
        />
      </div>
    </StepsWrapper>
  );
};

export default UpdateStep;
