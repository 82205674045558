import { InfoClientProperties, UpdateClientInfoProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";

export enum ACTIONS {
    setId = "setId",
    setState = "setState",
    setFirstName = "setFirstName",
    setLastName = "setLastName",
    setDateOfBirth = "setDateOfBirth",
    setGender = "setGender",
    setEmail = "setEmail",
    setSecondaryEmail = "setSecondaryEmail",
    setParentPhoneNumber = "setParentPhoneNumber",
    setSecondaryPhoneNumber = "setSecondaryPhoneNumber",
    setAddress = "setAddress",
    setInsuranceNumber = "setInsuranceNumber",
    setCity = "setCity",
    setZip = "setZip",
    setStatus = "setStatus",
    setDefaultBcbaId = "setDefaultBcbaId",
    setTherapists = "setTherapists",
    setInsuranceId = "setInsuranceId",
    setDiagnosisCodeId = "setDiagnosisCodeId",
    setCaseCoordinatorId = "setCaseCoordinatorId",
    setNickName = "setNickName",
    setMBHId = "setMBHId",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
}

export const clientReducer = (
    state: InfoClientProperties | UpdateClientInfoProperties,
    action: ActionProperties
) => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setId:
            return {
                ...state,
                id: payload,
            };
        case ACTIONS.setDiagnosisCodeId:
            return {
                ...state,
                diagnosisCodeId: payload,
            };
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            };
        case ACTIONS.setFirstName:
            return {
                ...state,
                firstName: payload,
            };
        case ACTIONS.setNickName:
            return {
                ...state,
                nickName: payload,
            };
        case ACTIONS.setLastName:
            return {
                ...state,
                lastName: payload,
            };
        case ACTIONS.setDateOfBirth:
            return {
                ...state,
                dateOfBirthday: payload,
            };
        case ACTIONS.setGender:
            return {
                ...state,
                gender: payload,
            };
        case ACTIONS.setEmail:
            return {
                ...state,
                email: payload,
            };
        case ACTIONS.setParentPhoneNumber:
            return {
                ...state,
                parentPhoneNumber: payload,
            };
        case ACTIONS.setAddress:
            return {
                ...state,
                address: payload,
            };
        case ACTIONS.setInsuranceNumber:
            return {
                ...state,
                insuranceNumber: payload,
            };
        case ACTIONS.setCity:
            return {
                ...state,
                city: payload,
            };
        case ACTIONS.setZip:
            return {
                ...state,
                zip: payload,
            };
        case ACTIONS.setStatus:
            return {
                ...state,
                status: payload,
            };
        case ACTIONS.setDefaultBcbaId:
            return {
                ...state,
                defaultBcba: payload,
            };
        case ACTIONS.setTherapists:
            return {
                ...state,
                therapists: payload,
            };
        case ACTIONS.setInsuranceId:
            return {
                ...state,
                insuranceId: payload,
            };
        case ACTIONS.setSecondaryEmail:
            return {
                ...state,
                secondaryEmail: payload,
            };
        case ACTIONS.setSecondaryPhoneNumber:
            return {
                ...state,
                secondaryNumber: payload,
            };
        case ACTIONS.setCaseCoordinatorId:
            return {
                ...state,
                caseCoordinatorId: payload,
            };
        case ACTIONS.setMBHId:
            return {
                ...state,
                mbhId: payload,
            };
        default:
            return state;
    }
};
