import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./targets.scss";
import {
  Text,
  CustomTable,
  CustomAccordion,
  CustomButton,
} from "../../../../../shared/uiComponents";
import { AdminTypes } from "../../../../../components/Menu";
import { TargetBody, TargetHeader } from "./tableInfo";
import Actions from "./Actions";
import TargetInfo from "./TargetInfo";
import TargetDetails from "./Actions/targetDetails";
import { BTRow } from "./targetRow";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getTargets } from "../../../../../redux/State/clientSlice/programTargetSlice";
import { TargetProperties } from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Targets = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const programName = window.localStorage.getItem("programName");
  const [isBT, setIsBT] = useState<boolean>(false);

  const { programId } = useParams();

  const programTargets = useSelector((state) => state.programTarget.targets);
  const role = useSelector((state) => state.account.role);
  const loading = useSelector((state) => state.programTarget.loadingGetTargets);

  useEffect(() => {
    if (!role) return;
    setIsBT(role.section.id === AdminTypes.bt);
  }, [role]);

  useEffect(() => {
    if (!programId) return;

    dispatch(getTargets(programId));
  }, [programId, dispatch]);

  const navigateCreateNewTargetHandler = () => {
    navigate(`/clients/client/target/${programId}`);
  };

  return (
    <div className="container" style={{ flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!!programName && (
          <Text
            title={programName}
            size="mediumBold"
            className="marginBottom16"
          />
        )}
        {!isBT && (
          <CustomButton
            title="Create new target"
            onClick={navigateCreateNewTargetHandler}
            className="marginBottom16"
          />
        )}
      </div>
      <div className="targetsDesktop">
        <CustomTable
          data={{ query: programTargets }}
          headers={TargetHeader}
          TableBody={TargetBody}
          loading={loading}
          hasPagination={false}
        />
      </div>
      <div className="targetsMobile">
        {!!programTargets &&
          programTargets.map((target) => (
            <div className="targets" key={target.id}>
              <CustomAccordion
                HeaderContent={() => <TargetDetails target={target} />}
                Content={({ open }) => (
                  <TargetContent target={target} open={open} />
                )}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const TargetContent = ({
  target,
  open,
}: {
  target: TargetProperties;
  open: boolean;
}) => {
  const [isBT, setIsBT] = useState<boolean>(false);
  const { id, programId } = target;
  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    if (!role) return;
    setIsBT(role.section.id === AdminTypes.bt);
  }, [role]);

  return !isBT ? (
    <>
      <Actions item={target} />
      <TargetInfo item={target} id={id} programId={programId} open={open} />
    </>
  ) : (
    <BTRow item={target} />
  );
};

export default Targets;
