import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import {
  EditOutlined as Edit,
  InfoOutlined as Info,
} from "@mui/icons-material";

import { CustomAlert } from "../../shared/uiComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import Delete from "../../components/Delete";
import { AdminTypes } from "../../components/Menu";
import { PERMISSIONS } from "../../App/constants";
import { ClientDetailedInfo } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { InfoClientProperties } from "../../redux/API/ClientAPIHelpers/clientProperties";
import {
  deleteClient,
  getClients,
} from "../../redux/State/clientSlice/clientSlice";

const Action = ({ item }: { item: InfoClientProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const EDIT = useCheckPermission(PERMISSIONS.CLIENT.EDIT);
  const DELETE = useCheckPermission(PERMISSIONS.CLIENT.DELETE);

  const role = useSelector((state) => state.account.role);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const showClientInfo = () => {
    setInfoOpen(true);
  };

  const fetchClients = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const searchString = params.get("search") || "";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const status = params.get("status");

    dispatch(
      getClients({ page, pageSize, direction, orderBy, searchString, status })
    );
  };

  const deleteClientHandler = () => {
    dispatch(deleteClient(item.id))
      .then(unwrapResult)
      .then(() => {
        toast(`Deleted`);
        fetchClients();
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {EDIT.permissionGranted &&
        role?.section.id === AdminTypes.admin && (
          <>
            <Link to={`/clients/${item.id}`} className={"marginRight16"}>
              <Tooltip title="Edit">
                <Edit fontSize="small" />
              </Tooltip>
            </Link>
          </>
        )}
      <div
        onClick={showClientInfo}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="More Information">
          <Info />
        </Tooltip>
      </div>
      {DELETE.permissionGranted && (
        <Delete deleteHandler={deleteClientHandler} />
      )}
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Detailed Information"}
        Content={() => <ClientDetailedInfo {...item} />}
      />
    </div>
  );
};

export default Action;
