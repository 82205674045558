import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CustomButton, Text, CustomTable } from "../../shared/uiComponents";
import SearchBar from "../../components/SearchBar";
import { Container, Header } from "../../components/StyledComponents";
import MobileView from "./mobileView";
import { TableBody, Headers } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getUsers } from "../../redux/State/userSlice";

const AllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();

  const usersData = useSelector((state) => state.user.users);
  const loading = useSelector((state) => state.user.loading);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const section = params.get("status");

    dispatch(
      getUsers({ page, pageSize, orderBy, direction, searchString, section })
    );
  }, [params, dispatch]);

  const addAdminHandler = () => {
    window.localStorage.removeItem("formType");
    navigate("/user/new");
  };

  return (
    <Container>
      <Header>
        <Text title={"All Users"} size="mediumBold" />
        <Header>
          <CustomButton
            title={"Add user"}
            className={"marginRight16"}
            onClick={addAdminHandler}
          />
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={usersData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...usersData} />
    </Container>
  );
};

export default AllUsers;
