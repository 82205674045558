import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
    BillingSessionsProperties,
    GetBillingProperties,
    GetTotalBillingProperties,
} from "../../API/ClientAPIHelpers/billingProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface BillingFilterProperties {
    clientId: string;
    userId: string;
    onlyNew: boolean;
    startDate: string;
    endDate: string;
}

interface BillingInitialStateProperties {
    loading: boolean,
    mbhSessions: BillingSessionsProperties,
    backdatedSessions: BillingSessionsProperties,
    ncSessions: BillingSessionsProperties,
    lpSessions: BillingSessionsProperties,
    billingFilter: BillingFilterProperties;
    error: ErrorProperties,
}

const initialState: BillingInitialStateProperties = {
    loading: false,
    mbhSessions: {
        query: null,
    },
    backdatedSessions: {
        query: null,
    }, 
    ncSessions: {
        query: null,
    },
    lpSessions: {
        query: null,
    },
    billingFilter: {
        clientId: "",
        endDate: "",
        onlyNew: true,
        startDate: "",
        userId: "",
    },
    error: {
        status: 0,
        title: "",
    }
}

export const getBillingSessions = createAsyncThunk(
    'billing/sessions',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const getNonCredentialedSessions = createAsyncThunk(
    'billing/sessions/non-credentialed',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getNonCredentialedSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const downloadMBHExcelReport = createAsyncThunk(
    'billing/sessions/download/mbh',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadMBHExcelReport(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const downloadNCExcelReport = createAsyncThunk(
    'billing/sessions/download/nc',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadNCExcelReport(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const downloadTotalReport = createAsyncThunk(
    'billing/sessions/download/total',
    async (data: GetTotalBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadTotalSesions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getLimitedPermitSessions = createAsyncThunk(
    'billing/sessions/limited-permit',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getLimitedPermitSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const downloadLimitedPermitSessions = createAsyncThunk(
    'billing/sessions/limited-permit/download',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadLimitedPermitSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const getBackdatedSessions = createAsyncThunk(
    'billing/backdated/sessions',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getBackdatedSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const downloadBackdatedSessions = createAsyncThunk(
    'billing/backdated/sessions/download',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadBackdatedSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

const BillingSlice = createSlice({
    name: 'billing',
    initialState: initialState,
    reducers: {
        setClientIdFilters(state, action: { payload: string; type: string }) {
            state.billingFilter.clientId = action.payload;
        },
        setUserIdFilters(state, action: { payload: string; type: string }) {
            state.billingFilter.userId = action.payload;
        },
        setStartDate(state, action: { payload: string; type: string }) {
            state.billingFilter.startDate = action.payload;
        },
        setEndDate(state, action: { payload: string; type: string }) {
            state.billingFilter.endDate = action.payload;
        },
        setOnlyNew(state, action: { payload: boolean; type: string }) {
            state.billingFilter.onlyNew = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBillingSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBillingSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.mbhSessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getBillingSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(getNonCredentialedSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNonCredentialedSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.ncSessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getNonCredentialedSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadMBHExcelReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadMBHExcelReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadMBHExcelReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadTotalReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadTotalReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadTotalReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadNCExcelReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadNCExcelReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadNCExcelReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(getLimitedPermitSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getLimitedPermitSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.lpSessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getLimitedPermitSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadLimitedPermitSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadLimitedPermitSessions.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadLimitedPermitSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(getBackdatedSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBackdatedSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.backdatedSessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getBackdatedSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const {
    setClientIdFilters,
    setEndDate,
    setOnlyNew,
    setStartDate,
    setUserIdFilters,
} = BillingSlice.actions;
export default BillingSlice.reducer;