import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { blue400 } from "../../../../shared/Helpers/colors";
import { CustomTable, Text } from "../../../../shared/uiComponents";
import { AdminTypes } from "../../../../components/Menu";
import { TableBodyAll, TableBodyBCBA, headersAll } from "./tableInfo";
import AddInsuranceAuthorization from "./AddInsuranceAuthorization";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  getActiveInsuranceAuthorizations,
  getInsuranceAuthorizations,
} from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const AdminPanel = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const userId = useSelector((state) => state.account.user.id);
  const loading = useSelector((state) => state.authorization.loading);
  const authorization = useSelector(
    (state) => state.authorization.allInsuranceAuthorizations
  );

  useEffect(() => {
    if (!clientId || !userId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";

    dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
  }, [clientId, dispatch, userId, params]);

  return (
    <>
      <AddInsuranceAuthorization />
      <CustomTable
        TableBody={TableBodyAll}
        headers={headersAll}
        data={authorization}
        loading={loading}
      />
    </>
  );
};

const BCBAPanel = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const userId = useSelector((state) => state.account.user.id);
  const loading = useSelector((state) => state.authorization.loading);
  const authorization = useSelector(
    (state) => state.authorization.authorizationDetails
  );

  useEffect(() => {
    if (!clientId || !userId) return;
    dispatch(getActiveInsuranceAuthorizations({ clientId, userId }));
  }, [clientId, dispatch, userId]);

  return (
    <CustomTable
      TableBody={TableBodyBCBA}
      headers={headersAll}
      data={{ query: authorization }}
      loading={loading}
      hasPagination={false}
    />
  );
};

const InsuranceAuthorization = () => {
  const role = useSelector((state) => state.account.role);

  const [panelId, setPanelId] = useState<string>("1");

  if (!role) return null;

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      {role.section.id === AdminTypes.admin ? (
        <>
          <div
            style={{
              display: "flex",
              gap: "16px",
              border: `1px solid ${blue400}`,
              borderRadius: "12px",
              padding: "8px",
              width: "max-content",
            }}
          >
            <Text
              title={"Client authorizations"}
              onClick={() => setPanelId("1")}
              size="smallBold"
              textColor={panelId === "1" ? blue400 : ""}
            />
            <Text
              title={"My authorizations"}
              onClick={() => setPanelId("2")}
              size="smallBold"
              textColor={panelId === "2" ? blue400 : ""}
            />
          </div>
          <div>
            {panelId === "1" && <AdminPanel />}
            {panelId === "2" && <BCBAPanel />}
          </div>
        </>
      ) : (
        <BCBAPanel />
      )}
    </div>
  );
};

export default InsuranceAuthorization;
