
export const CLIENT = "/client/v1.0";
export const IDENTITY = '/identity/v1.0';
export const EXPORT = `/export/v1.0`;
export const USER = '/user/v1.0';

export const BILLING = `${CLIENT}/billing`;
export const CLIENT_USERS = `${CLIENT}/users`;
export const CLIENTS = `${CLIENT}/clients`;
export const USERS = `${USER}/users`;
export const SESSIONS = `${CLIENT}/sessions`;
export const TARGETS = `${CLIENT}/targets`;
export const BEHAVIORS = `${CLIENT}/behaviors`;
export const TARGETS_ANNOTATIONS = `${TARGETS}/annotations`;
export const BEHAVIORS_ANNOTATIONS = `${BEHAVIORS}/annotations`;
export const CALENDAR = `${CLIENT}/calendar`;
export const CALENDAR_EVENTS = `${CALENDAR}/events`;

export const PROGRAMS = (skillAreaId: string) =>
    `${CLIENT}/skill-areas/${skillAreaId}/programs`;
export const SKILL_AREA = (clientId: string) => `${CLIENTS}/${clientId}/skill-areas`;
export const PROGRAM_TARGET = (programId: string) =>
    `${CLIENT}/programs/${programId}/targets`;
export const CLIENT_BEHAVIORS = (clientId: string) =>
    `${CLIENTS}/${clientId}/behaviors`;
export const SOAP_NOTES = (clientId: string) =>
    `${CLIENTS}/${clientId}/soap-notes`;
export const INSURANCE_AUTHORIZATION = `${CLIENT}/insurance-authorization`;
export const CLIENT_INSURANCE_AUTHORIZATION = (clientId: string) =>
    `${CLIENTS}/${clientId}/insurance-authorization`;
