import { useState } from "react";
import {
  InfoOutlined as Info,
  CheckBoxOutlineBlank as Box,
  CheckBoxOutlined as Check,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";

import { CustomAlert, Text } from "../../../../../shared/uiComponents";
import { InfoRow, Wrapper } from "../../../../../components/StyledComponents";
import { BehaviorReductionProperties } from "../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const Details = ({ data }: { data: BehaviorReductionProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="More Information">
        <Info onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Detailed Information"
        open={open}
        onClose={closeHandler}
        Content={() => <DetailsContent data={data} />}
      />
    </>
  );
};

const DetailsContent = ({ data }: { data: BehaviorReductionProperties }) => {
  const {
    name,
    definition,
    goalName,
    category,
    recordingType,
    createdAt,
    dataToday,
    isArchived,
  } = data;

  return (
    <>
      <Wrapper>
        <InfoRow>
          <Text title={"Behavior name:"} size="smallBold" />
          <Text title={name} />
        </InfoRow>
        <InfoRow>
          <Text title={"Behavior definition:"} size="smallBold" />
          <Text title={!!definition ? definition : "No definition"} />
        </InfoRow>
        <InfoRow>
          <Text title={"Goal name:"} size="smallBold" />
          <Text title={!!goalName ? goalName : "No goal name"} />
        </InfoRow>
        <InfoRow>
          <Text title={"Category:"} size="smallBold" />
          <Text title={category.name} />
        </InfoRow>
        <InfoRow>
          <Text title={"Recording type:"} size="smallBold" />
          <Text title={recordingType.text} />
        </InfoRow>
        <InfoRow>
          <Text title={"Date created:"} size="smallBold" />
          <Text title={dayjs(createdAt).format("MM/DD/YYYY")} />
        </InfoRow>
        <InfoRow>
          <Text title={"Data today:"} size="smallBold" />
          <Text title={dataToday} />
        </InfoRow>
        <InfoRow>
          <Text title={"Archived:"} size="smallBold" />
          <div>{isArchived ? <Check /> : <Box />}</div>
        </InfoRow>
      </Wrapper>
    </>
  );
};

export default Details;
