import { useState } from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import {
  CustomButton,
  CustomAlert,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../redux/store";
import {
  getTargets,
  masterOutTarget,
  openTarget,
} from "../../../../../../redux/State/clientSlice/programTargetSlice";
import { TargetProperties } from "../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const MasterOut = ({ item }: { item: TargetProperties }) => {
  const [openMasterOut, setOpenMasterOut] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch<DispatchProperties>();
  const { id, programId } = item;

  const masterOutTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(masterOutTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenMasterOut(false);
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const openTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(openTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpen(false);
        toast("Opened");
      })
      .catch(errorNormalizer);
  };
  return (
    <>
      {item.status.id !== 2 ? (
        <CustomButton
          title="Master Out"
          onClick={() => setOpenMasterOut(true)}
        />
      ) : (
        <CustomButton title="Open" onClick={() => setOpen(true)} />
      )}
      <CustomAlert
        title="Are you sure?"
        onClose={() => setOpenMasterOut(false)}
        open={openMasterOut}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              title="Master out target"
              onClick={masterOutTargetHandler}
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenMasterOut(false)}
            />
          </div>
        )}
      />
      <CustomAlert
        title="Are you sure?"
        onClose={() => setOpen(false)}
        open={open}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton title="Open target" onClick={openTargetHandler} />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenMasterOut(false)}
            />
          </div>
        )}
      />
    </>
  );
};

export default MasterOut;
