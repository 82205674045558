import { FC } from "react";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Text from "../Text";
import {
  black500,
  black900,
  blue400,
  red500,
  white100,
} from "../../Helpers/colors";

interface DatePickerProperties {
  label: string;
  value: Dayjs | null;
  onChange: (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  description?: string;
  onBlur?: () => void;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const CustomDate: FC<DatePickerProperties> = ({
  label,
  value,
  onChange,
  className = "",
  error = false,
  disabled = false,
  errorMessage = "",
  description = "",
  onBlur,
  disableFuture = false,
  disablePast = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={className} onBlur={onBlur}>
        <Text
          className={`inputLabel ${!!label ? "active" : ""}`}
          title={label}
          size={"tinyBold"}
        />
        <DatePicker
          value={value}
          format={"MM/DD/YYYY"}
          onChange={onChange}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          sx={{
            "& .MuiInputBase-root": {
              border: "1px solid",
              borderColor: black500,
              borderRadius: "10px",
              background: white100,
              color: black900,
              padding: "8px 10px",
              width: "100%", 
              height: "40px",
              borderBottom: "1px solid",
              borderBottomColor: error ? red500 : black500,
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: blue400,
              borderRadius: "10px",
            },
            "& .Mui-focused": {
              borderRadius: "10px",
              boxShadow: `0 0 0 0.2rem ${blue400}`,
            },
            "input":{
              padding: 0,
            }
          }}
        />
        <Text
          className={`error ${error ? "active" : ""}`}
          title={errorMessage}
          size={"tiny"}
          textColor={red500}
        />
        <Text
          className={`inputDescription ${
            !error && !!description ? "active" : ""
          }`}
          title={description}
          size={"tiny"}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomDate;
