import { useState, ChangeEvent, FormEvent } from "react";
import { EditOutlined as Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent, Tooltip } from "@mui/material";

import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  CustomInput,
  CustomSelect,
} from "../../../../../shared/uiComponents";
import { ButtonWrapper } from "../../../../../components/StyledComponents";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  ProgramDataProperties,
  UpdateProgramProperties,
} from "../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import {
  getPrograms,
  updateProgram,
} from "../../../../../redux/State/clientSlice/clientProgramSlice";

const EditProgram = ({ data }: { data: ProgramDataProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Edit">
        <Edit onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Edit Program Information"
        open={open}
        onClose={closeHandler}
        Content={({ onClose }) => <EditContent data={data} onClose={onClose} />}
      />
    </>
  );
};

const EditContent = ({
  data,
  onClose,
}: {
  data: ProgramDataProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const skillAreas = useSelector((state) => state.skillArea.skillAreas);

  const [name, setName] = useState<string>(data.name);
  const [goalName, setGoalName] = useState<string>(data.goalName);
  const [skillAreaId, setSkillAreaId] = useState<string>(data.skillAreaId);

  const onProgramNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGoalName(value);
  };

  const onSkillAreaChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSkillAreaId(value);
  };

  const editProgramHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const editeData: UpdateProgramProperties = {
      name,
      goalName,
      skillAreaId,
    };

    dispatch(updateProgram({ data: editeData, id: data.id }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getPrograms({ skillAreaId: data.skillAreaId }));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <form onSubmit={editProgramHandler}>
      <CustomSelect
        label="Skill Area:"
        data={skillAreas}
        value={skillAreaId}
        setValue={onSkillAreaChange}
        className={"marginBottom8"}
      />
      <CustomInput
        label={"Program name:"}
        value={name}
        setValue={onProgramNameChange}
        className={"marginBottom8"}
      />
      <CustomInput
        label={"Goal name:"}
        value={goalName}
        setValue={onGoalNameChange}
        className={"marginBottom24"}
      />
      <ButtonWrapper>
        <CustomButton
          title={"Save"}
          type="submit"
          disabled={!name || !goalName || !skillAreaId}
        />
        <CustomButton title="Cancel" onClick={onClose} secondaryButton />
      </ButtonWrapper>
    </form>
  );
};

export default EditProgram;
