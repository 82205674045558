import { FC } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import Edit from "@mui/icons-material/EditOutlined";

import { errorNormalizer } from "../../shared/Helpers/functions";
import { Text } from "../../shared/uiComponents";
import { Loader, Row, Wrapper } from "../../components/StyledComponents";
import Delete from "../../components/Delete";
import { RoleInfo } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { ExtendedRoleProperties } from "../../redux/API/identityAPIProperties";
import { deleteRole, getRoles } from "../../redux/State/identitySlice/roleSlice";

interface MobilDataProperties {
  data: ExtendedRoleProperties[] | null;
}

const MobileView: FC<MobilDataProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector((state) => state.role.loading);

  const deleteAdminHandler = (id: string | undefined) => {
    if (!id) return;
    dispatch(deleteRole(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getRoles());
        toast(`Deleted`);
      })
      .catch(errorNormalizer);
  };

  return (
    <div className={"mobileView"}>
      {loading && <Loader />}
      {!loading && !!data && !data.length && (
        <div className={"mobileView"}>
          <Text
            title={"No Results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!data &&
        data.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "flex-end" }}>
              {!item.isReadonly && (
                <>
                  <Link
                    to={`/permissions/${item.id}`}
                    className={"marginRight16"}
                  >
                    <Edit fontSize="small" />
                  </Link>
                  <Delete deleteHandler={() => deleteAdminHandler(item.id)} />
                </>
              )}
              <RoleInfo role={item} />
            </Row>
            <Row>
              <Text title="Role Name" size="smallBold" />
              <Text title={item.name} className={"marginLeft8"} />
            </Row>
            <Row>
              <Text title="Description" size="smallBold" />
              <Text
                title={!!item.description ? item.description : "-"}
                size="small"
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Text title="Usage" size="smallBold" />
              <Text
                title={item.numberOfUsage.toString()}
                size="small"
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Text title="Created" size="smallBold" />
              <Text
                title={dayjs(item.createdAt).format("MM-DD-YYYY")}
                size="small"
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Text title="Last Updated" size="smallBold" />
              <Text
                title={
                  !!item.modifiedAt
                    ? dayjs(item.modifiedAt).format("MM-DD-YYYY")
                    : "-"
                }
                size="small"
                className={"marginLeft8"}
              />
            </Row>
          </Wrapper>
        ))}
    </div>
  );
};

export default MobileView;
