import { ChangeEvent, useState } from "react";
import { v4 as randomUUID } from "uuid";
import { AddOutlined as Add } from "@mui/icons-material";

import {
  CustomInput,
  Text,
} from "../../../../../../../shared/uiComponents";

import { TargetStepsDataProperties } from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const AddStep = ({
  data,
  addTargetStep,
}: {
  addTargetStep: (data: TargetStepsDataProperties) => void;
  data: TargetStepsDataProperties;
}) => {

  const [name, setName] = useState<string>("");

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const addTargetStepHandler = () => {
    const step: TargetStepsDataProperties = {
      controlId: randomUUID(),
      orderNumber: data.orderNumber,
      name,
      note: "",
      enabled: true,
      useForMastery: true,
    };
    addTargetStep(step);
    setName("");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
      className="marginBottom16"
    >
      <Text title="Add step: " className="marginRight8" size="mediumBold" />
      <CustomInput
        value={name}
        setValue={onNameChange}
        className="marginRight16"
      />
      <Add fontSize="large" onClick={addTargetStepHandler} />
    </div>
  );
};

export default AddStep;
