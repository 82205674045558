import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  PhoneIphoneOutlined as Phone,
  PsychologyOutlined as Therapist,
  InfoOutlined as Info,
} from "@mui/icons-material";

import {
  Text,
  MobilePagination,
  CustomAlert,
  ClientStatusBadge,
} from "../../shared/uiComponents";
import { Loader, Row, Wrapper } from "../../components/StyledComponents";
import MobileSort from "../../components/MobileSort";
import { initialInfoClientState } from "./ClientForm/clientInitialStates";
import { ClientDetailedInfo, ClientsDataProperties } from "./tableInfo";
import { clientSortData } from "./mobileView";

import { DispatchProperties, useSelector } from "../../redux/store";
import { InfoClientProperties } from "../../redux/API/ClientAPIHelpers/clientProperties";
import { clearSkillAreas } from "../../redux/State/clientSlice/skillAreaSlice";

const MobileViewBCBA: FC<ClientsDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;

  const dispatch = useDispatch<DispatchProperties>();

  const loading = useSelector(
    (state) => state.userClient.loadingGetUserClients
  );

  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<InfoClientProperties>(
    initialInfoClientState
  );

  const showClientInfo = (info: InfoClientProperties) => {
    setClientInfo(info);
    setInfoOpen(true);
  };

  const navigateClientHandler = () => {
    dispatch(clearSkillAreas());
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={clientSortData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "space-between" }}>
              <div style={{ marginLeft: "16px" }}>
                <ClientStatusBadge statusCode={item.status.id} />
              </div>
              <div
                onClick={() => showClientInfo(item)}
                className={"marginRight16"}
                style={{ cursor: "pointer" }}
              >
                <Tooltip title="More Information">
                  <Info />
                </Tooltip>
              </div>
            </Row>
            <Link to={`/clients/client/${item.id}`}>
              <Row>
                <Person />
                <Text
                  title={item.fullName}
                  className={"marginLeft8"}
                  onClick={navigateClientHandler}
                />
              </Row>
            </Link>
            <Row>
              <Email /> <Text title={item.email} className={"marginLeft8"} />
            </Row>
            <Row>
              <Therapist />
              <Text
                title={`${item.numberOfTherapist}`}
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Phone />
              <Text
                title={!!item.parentPhoneNumber ? item.parentPhoneNumber : "-"}
                className={"marginLeft8"}
              />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Detailed Information"}
        Content={() => <ClientDetailedInfo {...clientInfo} />}
      />
    </div>
  );
};

export default MobileViewBCBA;
