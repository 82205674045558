import { SelectDataProperties } from "../../../../../../shared/uiComponents/Dropdown";
import { saveAs } from "file-saver";
import dayjs from "dayjs";

export enum Periods {
  "day",
  "week",
  "month",
  "year",
}

export enum LabelPeriods {
  "Date",
  "Week beginning",
  "Month",
  "Year",
}

export enum LabelPeriodsYFrequency {
  "Daily Frequency",
  "Weekly Frequency",
  "Monthly Frequency",
  "Yearly Frequency",
}

export interface ScaleValueProperties {
  type: string;
  time: { unit: string };
  title: { display: boolean; text: string };
}

export interface ScaleYValueProperties {
  title: {
    display: boolean;
    text: string;
  };
  suggestedMin: number;
  suggestedMax: number;
}

export interface DisplayDataProperties {
  x: string;
  y: number;
}

interface DisplayAnnotationProperties {
  id: string;
  note: string;
  date: string;
}

export interface BehaviorAnnotationProperties extends DisplayAnnotationProperties {
  behaviorId: string;
}

export interface TargetAnnotationProperties extends DisplayAnnotationProperties {
  targetId: string;
}

export const commonOptions: any = {
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
    annotation: {
      annotations: [],
    },
  },
  backgroundColor: "white",
};

export const saveCanvas = (fileName: string) => {
  const canvasSave: any = document.getElementById("stackId");
  canvasSave.toBlob(function (blob: string) {
    saveAs(blob, fileName);
  });
};

export const whiteBackgroundPlugin = {
  id: "whiteBackgroundPlugin",
  beforeDraw(chart: any, args: any, pluginOptions: any) {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const selectTimeList: Array<SelectDataProperties> = [
  { id: "0", name: "Daily" },
  { id: "1", name: "Weekly" },
  { id: "2", name: "Monthly" },
  { id: "3", name: "Yearly" },
];

const today = dayjs();

export const intervalData = [
  { id: "1D", value: today.format("MM/DD/YYYY") },
  {
    id: "5D",
    value: today.subtract(5, 'day').format("MM/DD/YYYY"),
  },
  {
    id: "14D",
    value: today.subtract(14, 'day').format("MM/DD/YYYY"),
  },
  {
    id: "MTD",
    value: today.startOf('month').format("MM/DD/YYYY"),
  },
  {
    id: "1M",
    value: today.subtract(1, 'month').format("MM/DD/YYYY"),
  },
  {
    id: "3M",
    value: today.subtract(3, 'month').format("MM/DD/YYYY"),
  },
  {
    id: "6M",
    value: today.subtract(6, 'month').format("MM/DD/YYYY"),
  },
  {
    id: "YTD",
    value: today.startOf('year').format("MM/DD/YYYY"),
  },
  {
    id: "1Y",
    value: today.subtract(1, 'year').format("MM/DD/YYYY"),
  },
  {
    id: "2Y",
    value: today.subtract(2, 'year').format("MM/DD/YYYY"),
  },
  {
    id: "5Y",
    value: today.subtract(5, 'year').format("MM/DD/YYYY"),
  },
];
