import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { ChangeEvent, useState } from "react";
import Text from "../Text";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_PLACES_KEY ?? "";

const AddressInput = ({
  setAddressDetails,
  value,
  handleInputBlur,
}: {
  value: string;
  handleInputBlur?: () => void;
  setAddressDetails: ({
    fullAddress,
    city,
    zipcode,
  }: {
    fullAddress?: string;
    zipcode?: string;
    city?: string;
  }) => void;
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries: ["places"],
  });

  const placeChangeHandler = () => {
    if (!autocomplete) return;
    const { address_components, formatted_address } = autocomplete.getPlace();

    const zipcode = address_components?.find(
      (component) => component.types[0] === "postal_code"
    )?.long_name;

    const city = address_components?.find(
      (component) => component.types[0] === "locality"
    )?.long_name;

    setAddressDetails({ fullAddress: formatted_address, city, zipcode });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setAddressDetails({ fullAddress: "" });
      return;
    }
    setAddressDetails({ fullAddress: value });
  };

  return isLoaded ? (
    <>
      <Text title={"Address"} className="inputLabel active" size="tinyBold" />
      <div className="addressLine">
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={placeChangeHandler}
        >
          <input
            id="address-line"
            placeholder=""
            onChange={handleInputChange}
            value={value}
            onBlur={handleInputBlur}
          />
        </Autocomplete>
      </div>
    </>
  ) : (
    <></>
  );
};

export default AddressInput;
