import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { errorNormalizer } from "../../shared/Helpers/functions";
import { CustomButton } from "../../shared/uiComponents";
import { AdminContainer } from "../../components/StyledComponents";

import { adminReducer, initialState } from "./adminReducer";
import AdminForm from "./adminForm";

import { DispatchProperties, useSelector } from "../../redux/store";
import { addUser } from "../../redux/State/userSlice";

const AddAdmin = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const saveDisabled = useSelector((state) => state.user.saveDisabled);

  const [adminInfo, setAdminInfo] = useReducer(adminReducer, initialState);

  const AddAdminHandler = () => {
    dispatch(addUser(adminInfo))
      .then(unwrapResult)
      .then(() => {
        navigate(-1);
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <AdminForm adminInfo={adminInfo} setAdminInfo={setAdminInfo} />
      <AdminContainer>
        <CustomButton
          title={"Save"}
          onClick={AddAdminHandler}
          disabled={saveDisabled}
          className="marginBottom16"
        />
      </AdminContainer>
    </>
  );
};

export default AddAdmin;
