import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CustomAlert, CustomTable, Text } from "../../shared/uiComponents";
import { TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getAuthorizationInfoBCBA } from "../../redux/State/clientSlice/userClientSlice";
import { InfoOutlined } from "@mui/icons-material";
import { green200, red300 } from "../../shared/Helpers/colors";

const AuthorizationInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [open, setOpen] = useState<boolean>(false);

  const user = useSelector((state) => state.account.user);
  const role = useSelector((state) => state.account.role);
  const authorization = useSelector((state) => state.userClient.authorization);
  const loading = useSelector((state) => state.userClient.loading);

  useEffect(() => {
    if (!user.id || !role) return;
    const userId = user.id;
    dispatch(getAuthorizationInfoBCBA(userId));
  }, [user, role, dispatch]);

  if (!authorization || !authorization.length) return null;

  return (
    <div
      style={{
        maxWidth: "750px",
        width: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text size="mediumBold" title={"BCBA Authorizations Dashboard"} />
        <InfoOutlined onClick={() => setOpen(true)} />
        <CustomAlert
          open={open}
          onClose={() => setOpen(false)}
          title="Details for authorization period"
          Content={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text
                title={"Expired"}
                style={{
                  backgroundColor: red300,
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
              <Text
                title={"Expires in 3 month"}
                style={{
                  backgroundColor: "#FFFF5C",
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
              <Text
                title={"Active"}
                style={{
                  backgroundColor: green200,
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
            </div>
          )}
        />
      </div>
      <CustomTable
        headers={[
          { id: "1", name: "Client" },
          { id: "2", name: "Authorization Period" },
          { id: "3", name: "Authorization Details" },
        ]}
        data={{ query: authorization }}
        TableBody={TableBody}
        loading={loading}
        hasPagination={false}
      />
    </div>
  );
};

export default AuthorizationInfo;
