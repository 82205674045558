import { ChangeEvent, useEffect, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomButton,
  Text,
  CustomInput,
  CustomSelect,
} from "../../../../../../../shared/uiComponents";
import {
  errorNormalizer,
  validateNumber,
} from "../../../../../../../shared/Helpers/functions";
import { white100 } from "../../../../../../../shared/Helpers/colors";
import { initialState } from "./initialState";
import { ACTIONS, TargetReducer } from "./reducer";

import {
  DispatchProperties,
  useSelector,
} from "../../../../../../../redux/store";
import {
  createTarget,
  getMasteryCriteria,
} from "../../../../../../../redux/State/clientSlice/programTargetSlice";
import {
  CreateProgramTargetProperties,
  MasteryTypes,
  TargetTypes,
} from "../../../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const CreateTarget = () => {
  const { programId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.programTarget.loading);
  const masteryOptions = useSelector(
    (state) => state.programTarget.masteryCriterias
  );

  const [targetData, setTargetData] = useReducer(TargetReducer, initialState);
  const {
    name,
    goalName,
    sd,
    targetInstructions,
    typeId,
    errorCorrection,
    reinforcement,
    masteryCriteria,
    consecutiveDays,
    responsePercentage,
  } = targetData;

  const [stepsValue, setStepsValue] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    dispatch(getMasteryCriteria());
  }, [dispatch]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setName, payload: value });
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setGoalName, payload: value });
  };

  const onSdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setSD, payload: value });
  };

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setTargetInstructions, payload: value });
  };

  const onErrorCorrectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setErrorCorrection, payload: value });
  };

  const onReinforcementChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setReinforcement, payload: value });
  };

  const onTypeIdChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setTypeId, payload: value });
  };

  const onMasteryCriteriaChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    const payload = parseInt(value);
    setTargetData({ type: ACTIONS.setMasteryCriteria, payload });
    if (MasteryTypes.AUTOMATIC === payload) {
      setTargetData({ type: ACTIONS.setResponsePercentage, payload: 80 });
    }
    if (MasteryTypes.MANUAL === payload) {
      setTargetData({ type: ACTIONS.setConsecutiveDays, payload: null });
      setTargetData({ type: ACTIONS.setResponsePercentage, payload: null });
    }
  };

  const onConsecutiveDaysChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateNumber(value)) return;
    setTargetData({
      type: ACTIONS.setConsecutiveDays,
      payload: value,
    });
  };

  const onResponsePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateNumber(value)) return;
    setTargetData({
      type: ACTIONS.setResponsePercentage,
      payload: value,
    });
  };

  const onStepsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStepsValue(value);
  };

  const saveTargetHandler = () => {
    if (!programId) return;

    let data: CreateProgramTargetProperties = { ...targetData };

    if (!!stepsValue) {
      const steps = stepsValue.split(",").map((s) => ({ name: s.trim() }));
      data = { ...data, steps };
    }

    dispatch(createTarget({ data, programId }))
      .then(unwrapResult)
      .then(() => {
        toast("Created");
        navigate(-1);
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!!name) {
      setError("");
      return;
    }

    setError("Required");
  }, [name]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: white100,
        width: "100%",
        maxWidth: "720px",
        padding: "24px",
        borderRadius: "12px",
        margin: "0 auto 16px auto",
      }}
    >
      <Text
        title="Target Information"
        size="mediumBold"
        className="marginBottom16"
      />
      <CustomInput
        label="Target Name"
        className="marginBottom16"
        value={name}
        setValue={onNameChange}
        error={!name}
        errorMessage={error}
      />
      <CustomInput
        label="SD"
        className="marginBottom16"
        value={sd}
        setValue={onSdChange}
      />
      <CustomSelect
        label="Mastery Criteria"
        className="marginBottom16"
        value={masteryCriteria.toString()}
        setValue={onMasteryCriteriaChange}
        data={masteryOptions}
      />
      {masteryCriteria === MasteryTypes.AUTOMATIC && (
        <>
          <CustomInput
            label="Consecutive Days"
            className="marginBottom16"
            value={consecutiveDays}
            setValue={onConsecutiveDaysChange}
          />
          <CustomInput
            label="Response Percentage"
            className="marginBottom16"
            value={responsePercentage}
            setValue={onResponsePercentageChange}
          />
        </>
      )}
      <CustomInput
        label="Goal Name (for reporting)"
        className="marginBottom16"
        value={goalName}
        setValue={onGoalNameChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="Target Instructions"
        className="marginBottom16"
        value={targetInstructions}
        setValue={onTargetInstructionsChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="Error Correction"
        className="marginBottom16"
        value={errorCorrection}
        setValue={onErrorCorrectionChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="Reinforcement"
        className="marginBottom16"
        value={reinforcement}
        setValue={onReinforcementChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomSelect
        label="Target type"
        className="marginBottom16"
        value={typeId.toString()}
        setValue={onTypeIdChange}
        data={[
          { id: TargetTypes.DTT, name: "DTT" },
          { id: TargetTypes.TA, name: "Task Analysis" },
        ]}
      />
      {typeId === TargetTypes.TA && (
        <CustomInput
          label="Steps"
          className="marginBottom16"
          value={stepsValue}
          setValue={onStepsChange}
          multiline={{
            multiline: true,
            rowCount: 2,
          }}
          description="Separate with comma"
        />
      )}
      <CustomButton
        title="Save"
        onClick={saveTargetHandler}
        loading={loading}
        disabled={loading || !name}
      />
    </div>
  );
};

export default CreateTarget;
