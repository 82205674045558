import { CLIENT } from "../constants";
import { FetchAPI } from "../Main/fetchAPI";
import { GetExcelReportProperties } from "./soapNoteProperties";

export const ExcelReport = {
    getPendingDSUSoapNotes: (data: GetExcelReportProperties) => {
        const {
            clientId,
            userId,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        if (!!userId) query.set("userId", userId);
        if (!!clientId) query.set("clientId", clientId);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${CLIENT}/soap-notes/pending-dsu/xlsx?${query.toString()}` })
    },
    getExpiredSessions: (data: GetExcelReportProperties) => {
        const {
            clientId,
            userId,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        if (!!userId) query.set("userId", userId);
        if (!!clientId) query.set("clientId", clientId);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${CLIENT}/sessions/expired/xlsx?${query.toString()}` })
    },
    getAssessmentReport: (data: GetExcelReportProperties) => {
        const {
            clientId,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        if (!!clientId) query.set("clientId", clientId);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${CLIENT}/assessment/basic-report/xlsx?${query.toString()}` })
    },
} 