import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { HeaderProperties } from "../../../../shared/uiComponents/Table/tableProperties";
import { TableNoData } from "../../../../shared/uiComponents";
import { AdminTypes } from "../../../../components/Menu";
import Actions from "./Actions";

import { useSelector } from "../../../../redux/store";
import { ProgramDataProperties } from "../../../../redux/API/ClientAPIHelpers/clientProgramProperties";

export const programHeaders: Array<HeaderProperties> = [
  { id: "0", name: "Program name" },
  { id: "1", name: "Open targets" },
  { id: "2", name: "Mastered targets" },
  { id: "3", name: "Total targets" },
  { id: "4", name: "Actions" },
];

export const programHeadersBT: Array<HeaderProperties> = [
  { id: "0", name: "Program name" },
  { id: "1", name: "Open targets" },
  { id: "2", name: "Mastered targets" },
  { id: "3", name: "Total targets" },
];

interface ProgramRowRendererProperties {
  data: Array<ProgramDataProperties>;
}

export const ProgramTableBody: FC<ProgramRowRendererProperties> = ({
  data,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const role = useSelector((state) => state.account.role);

  const handleProgramClick = (id: string, programName: string) => {
    window.localStorage.setItem("programName", programName);
    navigate(`${pathname}/${id}`);
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={programHeaders.length} />;
  }
  return (
    <Body>
      {data.map((row) => (
        <TableRow key={row.id}>
          <TableCell
            onClick={() => handleProgramClick(row.id, row.name)}
            style={{ cursor: "pointer" }}
          >
            {row.name}
          </TableCell>
          <TableCell>{row.openTargets}</TableCell>
          <TableCell>{row.masteredTargets}</TableCell>
          <TableCell>{row.totalTargets}</TableCell>
          {!!role && role.section.id !== AdminTypes.bt && (
            <TableCell style={{ display: "flex", gap: "16px" }}>
              <Actions data={row} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </Body>
  );
};
