import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import Wrapper from "../../components/WrapperWithoutMenu";
import { Loader } from "../../components/StyledComponents";
import { Text } from "../../shared/uiComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";

import { DispatchProperties } from "../../redux/store";
import { completeEmailChange } from "../../redux/State/identitySlice/accountSlice";
import { resetState } from "../../redux/resetState";

const ChangeEmail = () => {
  return <Wrapper Component={ConfirmEmailChange} />;
};

const ConfirmEmailChange = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [flag, setFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const token = encodeURIComponent(
    new URLSearchParams(search).get("token") as string
  );
  const userId = new URLSearchParams(search).get("userId");

  useEffect(() => {
    if (!userId || !token) return;
    setLoading(true);
    dispatch(
      completeEmailChange({
        userId,
        token,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        setFlag(response.isSuccessful);
        const timeout = setTimeout(() => {
          dispatch(resetState());
          navigate("/login");
        }, 5000);

        return () => clearTimeout(timeout);
      })
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  }, [dispatch, navigate, token, userId]);

  return !loading ? (
    <Text
      title={flag ? "Email updated successfully." : "Something went wrong"}
      size="smallBold"
    />
  ) : (
    <Loader />
  );
};

export default ChangeEmail;
