import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router";

import { CustomCheckBox, CustomTable } from "../../../../shared/uiComponents";
import { blue400 } from "../../../../shared/Helpers/colors";
import {
  BehaviorTableBody,
  behaviorHeaders,
  behaviorHeadersBT,
} from "./tableInfo";
import { AdminTypes } from "../../../../components/Menu";
import AddBehavior from "./Actions/addBehavior";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getBehaviors } from "../../../../redux/State/clientSlice/behaviorReductionSlice";
import { BehaviorReductionProperties } from "../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const BehaviorReduction: FC = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();
  const includeArchived =
    params.get("includeArchivedBehavior") === "true" ? true : false;

  const [clientBehaviors, setClientBehaviors] = useState<
    Array<BehaviorReductionProperties>
  >([]);

  const behaviors = useSelector((state) => state.behaviorReduction.behaviors);
  const role = useSelector((state) => state.account.role);
  const loading = useSelector((state) => state.clientProgram.loading);

  const showArchivedBehaviorReductionsHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    params.set("includeArchivedBehavior", `${checked}`);
    setParams(params);
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getBehaviors({ clientId, includeArchived }));
  }, [clientId, includeArchived, dispatch]);

  useEffect(() => {
    if (!behaviors) return;
    if (includeArchived) {
      setClientBehaviors(behaviors);
      return;
    }

    const data = behaviors.filter((item) => !item.isArchived);
    setClientBehaviors(data);
  }, [behaviors, includeArchived]);

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      <CustomTable
        headers={
          !!role && role.section.id !== AdminTypes.bt
            ? behaviorHeaders
            : behaviorHeadersBT
        }
        TableBody={BehaviorTableBody}
        data={{ query: clientBehaviors }}
        loading={loading}
        hasPagination={false}
      />
      <div className="margin16">
        <AddBehavior />
        <CustomCheckBox
          item={{
            id: "0",
            checked: includeArchived,
            label: `Show archived`,
          }}
          onChange={showArchivedBehaviorReductionsHandler}
        />
      </div>
    </div>
  );
};

export default BehaviorReduction;
