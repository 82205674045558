import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import {
  CustomButton,
  CustomAlert,
} from "../../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../../shared/Helpers/functions";
import Delete from "../../../../../../../components/Delete";
import PdfAnnotations from "../../../../SkillAcquisition/Targets/Actions/TargetGraph/GraphAnnotations/pdfAnnotations";

import {
  DispatchProperties,
  useSelector,
} from "../../../../../../../redux/store";
import {
  deleteBehaviorAnnotation,
  getBehaviorReductionAllAnnotations,
} from "../../../../../../../redux/State/clientSlice/graphAnnotationSlice";

const ViewAnnotationsPopup = ({ behaviorReductionId, name }) => {
  const [viewAnnotations, setViewAnnotations] = useState<boolean>(false);
  const dispatch = useDispatch<DispatchProperties>();
  const annotationsList = useSelector(
    (state) => state.graphAnnotation.behaviorAnnotations
  );
  const clientInfo = useSelector((state) => state.client.client);
  const handlerDeleteAnnotation = (id: string) => {
    if (!id) return;
    dispatch(deleteBehaviorAnnotation(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviorReductionAllAnnotations(behaviorReductionId));
        toast("Deleted");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomButton
        title="View Annotations"
        onClick={() => setViewAnnotations(true)}
      />
      <CustomAlert
        title="View Annotations"
        onClose={() => setViewAnnotations(false)}
        open={viewAnnotations}
        Content={() => (
          <div className="container-view">
            {annotationsList &&
              annotationsList.map((item) => (
                <div className="info-annotation" key={item.id}>
                  <p>{item.note}</p>
                  <p>{dayjs(new Date(item.date)).format("MM-DD-YYYY")}</p>
                  {/* <CustomButton
                  title={"Delete"}
                  onClick={() => handlerDeleteAnnotation(item.id)}
                /> */}
                  <Delete
                    deleteHandler={() => handlerDeleteAnnotation(item.id)}
                  />
                </div>
              ))}
            <div className="btns-popup">
              <CustomButton
                title={"Close"}
                secondaryButton
                onClick={() => setViewAnnotations(false)}
              />
              <PdfAnnotations
                annotationsList={annotationsList}
                clientName={clientInfo.fullName}
                name={name}
                title1="Client Name"
                title2="Behavior Name"
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default ViewAnnotationsPopup;
